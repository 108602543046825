import React from "react";
import styled from "styled-components";

const HorizontalRule = styled.div`
  width: 100%;
  opacity: 0.15;
  height: 42px;
  border-bottom: 1px solid ${({ theme }) => theme.color.text.blue};
`;

export default HorizontalRule
