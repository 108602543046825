import React from "react";
import styled from "styled-components";

import {
  Text,
  Spacer,
  GradientText,
  ErrorPageWrapper,
  Link,
} from "src/components";
import { mediaQueries } from "src/utils/responsive";

const NotFound: React.FC = () => {
  return (
    <ErrorPageWrapper>
      <MessageContainer>
        <Title>Whoops!</Title>
        <Spacer height={10} />
        <Subtitle>It looks like this page doesn&apos;t exist</Subtitle>
        <Spacer height={32} />
        <Link href="/">Go to home page →</Link>
      </MessageContainer>
    </ErrorPageWrapper>
  );
};

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  max-width: 800px;
  padding: 67px 150px;
  margin: 0 50px;
  margin-bottom: 20%;
  z-index: 1;
  ${mediaQueries.largeMobile} {
    padding: 67px 10px;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Title = styled(GradientText).attrs({ heading: true, element: "h1" })`
  display: inline-block;
  background: ${({ theme }) => theme.color.custom.blueGreenGradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
`;

const Subtitle = styled(Text).attrs({ heading: true, element: "h2" })`
  font-size: 24px;
  text-align: center;
`;

export default NotFound;
