import React, { createContext, useContext, useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";

import { sharedTheme } from "src/theme";

export type TThemeMode = "light" | "dark";

export type TThemeState = {
  curMode: TThemeMode;
  toggleMode: () => void;
};

const DEFAULT_STATE: TThemeState = {
  curMode: "light",
  toggleMode: () => {},
};

export const ThemeContext: React.Context<TThemeState> =
  createContext(DEFAULT_STATE);

export const ThemeContextProvider: React.FC = ({ children, ...rest }) => {
  const [isMounted, setIsMounted] = useState(false);
  const curMode: TThemeMode = "light";

  const stateValue = {
    curMode,
    toggleMode: () => {},
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  /**
   * Prevents SSR flash for mismatched dark mode
   * NOTE: https://brianlovin.com/overthought/adding-dark-mode-with-next-js
   */
  return isMounted ? (
    <ThemeContext.Provider value={stateValue} {...rest}>
      <ThemeProvider theme={sharedTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  ) : null;
};

export const useThemeContext = () => useContext(ThemeContext);
