import { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

export type TFlexProps = ComponentPropsWithoutRef<"div"> & {
  /**
   * See @https://css-tricks.com/snippets/css/a-guide-to-flexbox/ for more information
   */
  row?: boolean;
  column?: boolean;
  justify?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "start"
    | "end"
    | "left"
    | "end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  align?:
    | "stretch"
    | "flex-start"
    | "start"
    | "self-start"
    | "flex-end"
    | "end"
    | "self-end"
    | "center"
    | "baseline";
};

const setFlexDirection = (row?: boolean, column?: boolean) => {
  if (row && column)
    throw new TypeError("row and column cannot be specified together");

  return column ? "column" : "row";
};

const Flex = styled.div<TFlexProps>`
  display: flex;
  flex-flow: ${({ row, column }) => `${setFlexDirection(row, column)} nowrap`};
  justify-content: ${({ justify }) => justify || "flex-start"};
  align-items: ${({ align }) => align || "stretch"};
  box-sizing: border-box;
`;

Flex.displayName = "Flex";

export default Flex;
