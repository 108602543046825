import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";

import {
  Spacer,
  Text,
  Flex,
  FullReviewShortAnswer,
  FullReviewLongAnswer,
  Button,
  Modal,
} from "src/components";
import {
  Background,
  WhiteContainer,
  ChildrenWrapper,
} from "src/components/LongAnswerPage";
import { RouteName } from "src/constants/route";
import { useSiteContext } from "src/contexts";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { BackArrowIcon } from "src/static/icons";

const REVIEW_BOAT_QUOTE = "Ship it!";

const Subtitle = styled(Text).attrs({
  size: "bodyL",
})`
  font-weight: 500;
  color: ${({ theme }) => theme.color.primary.blue1};
`;

export default function FullReview() {
  const { isApplicationSubmitted, submitResponses } = useResponse();

  const { isMobile, navigate, setBoatQuote } = useSiteContext();
  const [isModalOpen, setModalOpen] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  setBoatQuote(REVIEW_BOAT_QUOTE);

  return (
    <Background isMobile={isMobile}>
      <WhiteContainer>
        <ChildrenWrapper>
          {!isApplicationSubmitted && (
            <>
              <ReactRouterLink to={RouteName.SURVEY}>
                <Flex row align="center">
                  <BackArrowIcon />
                  <Text link> Survey questions</Text>
                </Flex>
              </ReactRouterLink>
              <Spacer height={24} />
            </>
          )}

          <Text heading size="h2D">
            {isApplicationSubmitted ? "View" : "Review"} my application
          </Text>
          <Spacer height={36} />
          <Subtitle>
            {isApplicationSubmitted
              ? `Your application has been submitted! You can no longer edit your application.`
              : `Please review your application before submitting. You can also
            submit at a later time by returning to this page.`}
          </Subtitle>
          <FullReviewShortAnswer />
          <FullReviewLongAnswer />
          <Spacer height={72} />
          <Flex row justify="flex-end">
            <Button
              onClick={
                isApplicationSubmitted
                  ? () => navigate(RouteName.APPLICATION_SUBMITTED)
                  : () => setModalOpen(true)
              }
            >
              {isApplicationSubmitted
                ? "Done reviewing"
                : "Submit my application"}
            </Button>
          </Flex>
          <Spacer height={100} />
          <Modal
            isOpen={isModalOpen}
            title="Are you sure you want to submit?"
            onClose={() => setModalOpen(false)}
            actions={
              <Button
                onClick={() =>
                  submitResponses().then((submittedSuccessfully) =>
                    submittedSuccessfully
                      ? navigate(RouteName.APPLICATION_SUBMITTED)
                      : null
                  )
                }
              >
                Submit
              </Button>
            }
          >
            <Text>
              Click the submit button if you’re ready to send in your Hack the
              North 2021 application!
            </Text>
          </Modal>
        </ChildrenWrapper>
      </WhiteContainer>
    </Background>
  );
}
