import React, { useState } from "react";
import tw, { styled } from "twin.macro";

import {
  Button,
  CustomBoat,
  Text,
  Flex,
  ShortAnswerPage,
  InkTransition,
  Spacer,
} from "src/components";
import {
  colors,
  expressions,
  accessories,
} from "src/components/CustomBoat/BoatOptions";
import { QuestionName } from "src/contexts/ResponseContext/config";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import StorybookPaginator from "src/contexts/StorybookContext/Paginator";
import { TStorybookPageProps } from "src/contexts/StorybookContext/Storybook/constants";
import { NameImg, PaperIconImg, ExpressionIconImg } from "src/static";
import { ShuffleIcon, BucketIcon, FlagIcon, HeadIcon } from "src/static/icons";
import { mediaQueries } from "src/utils/responsive";

import AssetPage from "../AssetPage";

type TabType = {
  selected: boolean;
  right: boolean;
};

const HeadingText = styled(Text).attrs({ heading: true, size: "h1" })`
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.fontSize.h3M}px;

  ${mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.fontSize.h3M}px;
  }
`;

export const OptionButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  transition: transform 100ms;
  transform: scale(0.95);
  &:focus,
  &:hover {
    transform: scale(1);
    outline: none;
  }
`;

export const AccessoryButton = styled(OptionButton)`
  ${tw`inline-flex justify-center items-center`}
  width: 80px;
  min-height: 65px;
`;

export const TabButton = styled(Button)<TabType>`
  width: 33.3%;
  padding: 20px;
  border: none;
  border-radius: 0;
  border-right: ${({ right, theme }) =>
    !right && "1px solid " + theme.color.grey.outline};
  border-bottom: ${({ selected, theme }) =>
    !selected && "1px solid " + theme.color.grey.outline};
  background-color: ${({ selected, theme }) =>
    selected ? "white" : "#fafafa"};

  &:focus {
    opacity: 1;
  }
`;

export const TabButtonText = styled(Text)`
  margin-top: 4px;
  font-size: ${({ theme }) => theme.fontSize.label}px;
  color: ${({ theme }) => theme.color.primary.blue2};
`;

export const boatLines = [
  "Looking ship-shape!",
  "What a boat-y!",
  "Looking tide-y!",
  "Whale, hello there!",
];

const boatNames = [
  "boaty mcboatface",
  "i want to go on this boat",
];

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const getRandomQuote = () => {
  return boatLines[getRandomInt(boatLines.length)];
};

const Build: React.FC<TStorybookPageProps> = () => {
  const { onTurnNext } = useStorybookContext();
  const [tab, setTab] = useState("color");
  const {
    responses: {
      short_name,
      boat_custom_color,
      boat_custom_expression,
      boat_custom_accessory,
    },
    updateResponseValues,
  } = useResponse();

  const { isMobile } = useSiteContext();

  const { value: customColor, setValue: setCustomColor } = boat_custom_color;
  const { value: customExpression, setValue: setCustomExpression } =
    boat_custom_expression;
  const { value: customAccessory, setValue: setCustomAccessory } =
    boat_custom_accessory;

  const handleRandomize = () => {
    const colorKeys = Object.keys(colors);
    const expressionKeys = Object.keys(expressions);
    const accessoryKeys = Object.keys(accessories);

    updateResponseValues({
      [QuestionName.BOAT_COLOR]:
        colorKeys[Math.floor(Math.random() * colorKeys.length)],
      [QuestionName.BOAT_EXPRESSION]:
        expressionKeys[Math.floor(Math.random() * expressionKeys.length)],
      [QuestionName.BOAT_ACCESSORY]:
        accessoryKeys[Math.floor(Math.random() * accessoryKeys.length)],
    });
  };

  return (
    <>
      <AssetPage>
        {/* Hack to position boat in same position on mobile; since other assets have the same height */}
        {isMobile && (
          <InkTransition>
            <NameImg style={{ visibility: "hidden" }} />
          </InkTransition>
        )}

        {/* On mobile, the boat stays in the same position on all pages and is handled by AssetPage */}
        {!isMobile && (
          <DesktopBoatWrapper>
            <CustomBoat
              color={customColor}
              expression={customExpression}
              accessory={customAccessory}
              speechBubbleMarkup={
                boatNames.includes(short_name.value.toLowerCase())
                  ? "I want to go on this boat"
                  : getRandomInt(3) === 1
                  ? getRandomQuote()
                  : null
              }
            />
            <Button
              secondary
              tw="flex items-center gap-x-8 justify-self-end"
              onClick={handleRandomize}
            >
              <ShuffleIcon />
              <Text>Randomize</Text>
            </Button>
          </DesktopBoatWrapper>
        )}
      </AssetPage>

      <ShortAnswerPage>
        <div tw="flex flex-col gap-y-8 justify-center">
          {isMobile && (
            <div tw="flex w-full justify-between h-64 -mt-64 mb-8">
              <TabButton
                tw="rounded-tl-16"
                selected={tab === "color"}
                right={false}
                onClick={() => setTab("color")}
              >
                <div tw="flex flex-col justify-center">
                  <BucketIcon tw="m-auto" />
                  <TabButtonText>Colour</TabButtonText>
                </div>
              </TabButton>
              <TabButton
                selected={tab === "expression"}
                right={false}
                onClick={() => setTab("expression")}
              >
                <div tw="flex flex-col justify-center">
                  <HeadIcon tw="m-auto" />
                  <TabButtonText>Expression</TabButtonText>
                </div>
              </TabButton>
              <TabButton
                selected={tab === "accessory"}
                right={true}
                tw="rounded-tr-16"
                onClick={() => setTab("accessory")}
              >
                <div tw="flex flex-col justify-center">
                  <FlagIcon tw="m-auto" />
                  <TabButtonText>Accessory</TabButtonText>
                </div>
              </TabButton>
            </div>
          )}

          {/* Headings don't show up on mobile */}
          {!isMobile && <HeadingText>Pick your paper:</HeadingText>}
          {(!isMobile || tab === "color") && (
            <div
              tw="flex items-center flex-wrap"
              css={[isMobile && tw`justify-between`]}
            >
              {Object.keys(colors).map((color) => (
                <OptionButton
                  key={color}
                  aria-label={color}
                  title={color}
                  onClick={() => setCustomColor(color)}
                >
                  <PaperIconImg
                    paperColor={color}
                    selected={customColor === color}
                  />
                </OptionButton>
              ))}
            </div>
          )}

          {!isMobile && <HeadingText>Draw an expression:</HeadingText>}
          {(!isMobile || tab === "expression") && (
            <div
              tw="flex items-center gap-8 flex-wrap"
              css={[isMobile && tw`justify-center`]}
            >
              {Object.keys(expressions).map((expression) => (
                <OptionButton
                  key={expression}
                  aria-label={expression}
                  title={expression}
                  onClick={() => setCustomExpression(expression)}
                  css={[isMobile && tw`w-120`]}
                >
                  <ExpressionIconImg
                    expression={expression}
                    selected={customExpression === expression}
                  />
                </OptionButton>
              ))}
            </div>
          )}

          {!isMobile && <HeadingText>Put on an accessory:</HeadingText>}
          {(!isMobile || tab === "accessory") && (
            <div
              tw="flex items-center gap-24 flex-wrap"
              css={[isMobile && tw`justify-center`]}
            >
              {Object.keys(accessories).map((accessoryName) => {
                const { normal, selected } = accessories[accessoryName];
                const isSelected = customAccessory === accessoryName;
                let markup;
                if (accessoryName === "boat_hat") {
                  markup = isSelected
                    ? selected[customColor]
                    : normal[customColor];
                } else {
                  markup = isSelected ? selected : normal;
                }
                return (
                  <AccessoryButton
                    key={accessoryName}
                    aria-label={accessoryName}
                    title={accessoryName}
                    onClick={() => setCustomAccessory(accessoryName)}
                    css={[isMobile && tw`w-112`]}
                  >
                    {markup}
                  </AccessoryButton>
                );
              })}
            </div>
          )}

          <Flex justify="flex-end">
            <Button css={[isMobile && tw`w-full mt-16`]} onClick={onTurnNext}>
              Let&apos;s go!
            </Button>
          </Flex>
          {isMobile && <Spacer height={48} />}
        </div>
        {!isMobile && <StorybookPaginator />}
      </ShortAnswerPage>
    </>
  );
};

const DesktopBoatWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  align-items: center;
  justify-content: center;
`;

export default Build;
