import React from "react";
import styled from "styled-components";

import { moveclouds } from "src/constants/animations";
import { Cloud1, Cloud2, Cloud3 } from "src/static";

type CloudProps = {
  src: React.ReactNode;
  animationTime: number; // in seconds
  top: number; // left offset for abs positioning
  left: number; // top offset for abs positioning
  opacity?: number;
};

const clouds: CloudProps[] = [
  {
    src: <Cloud1 />,
    animationTime: 36,
    top: 70,
    left: 50,
  },
  {
    src: <Cloud1 />,
    animationTime: 60,
    top: 90,
    left: 0,
  },
  {
    src: <Cloud2 />,
    animationTime: 40,
    top: 140,
    left: -1000,
  },
  {
    src: <Cloud3 />,
    animationTime: 30,
    top: 180,
    left: 500,
  },
];

const Clouds: React.FC = () => {
  const opacity = Math.random() * (0.5 - 0.3) + 0.3;
  return (
    <div tw="pointer-events-none">
      {clouds.map((cloud, i) => {
        const node = cloud.src;
        return (
          <AnimatedCloud key={`cloud_${i}`} opacity={opacity} {...cloud}>
            {node}
          </AnimatedCloud>
        );
      })}
    </div>
  );
};

const AnimatedCloud = styled.div<CloudProps>`
  position: absolute;
  opacity: ${({ opacity }) => opacity};
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  animation: ${moveclouds} ${({ animationTime }) => animationTime}s linear
    infinite;
`;

export default React.memo(Clouds);
