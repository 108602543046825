import React, { ElementType, ComponentPropsWithoutRef } from "react";
import * as FeatherIcons from "react-feather";
import styled from "styled-components";

export type BaseProps<T extends ElementType> = ComponentPropsWithoutRef<T>;

export type IconProps = {
  /**
   * The name of the icon. Can be found on feathericons.com, and
   * should always be title cased. If an invalid name is provided,
   * a colored solid square will show instead of an icon.
   */
  name: string;
  /**
   * The color of the icon. Can be any value accepted in CSS, or a
   * custom value defined in theme. Defaults to `black`.
   */
  color?: string;
  /**
   * The size of the icon in `px`. Defaults to `24`.
   */
  size?: number | string;
} & BaseProps<"svg">;

const DEFAULT_ICON_SIZE = 24;

const NoIconFound = styled.span<IconProps>`
  display: inline-block;
  width: ${({ size }) => size || DEFAULT_ICON_SIZE}px;
  height: ${({ size }) => size || DEFAULT_ICON_SIZE}px;
  background-color: ${({ color }) => color};
`;

export const FeatherIcon: React.FC<IconProps> = ({
  className,
  name,
  color = "black",
  size = DEFAULT_ICON_SIZE,
  ...rest
}) => {
  const IconComponent = FeatherIcons[name];

  return IconComponent ? (
    <IconComponent size={size} color={color} className={className} {...rest} />
  ) : (
    <NoIconFound
      name={name}
      size={size}
      color={color}
      className={`unknown-icon-${name} ${className || ""}`}
    />
  );
};
