import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { useTheme } from "styled-components";
import tw from "twin.macro";

import Flex from "src/components/base/Flex";
import Icon from "src/components/base/Icon";
import GradientText from "src/components/GradientText";
import { RouteName } from "src/constants/route";
import { shortAnswerRouteToResponse } from "src/contexts/ResponseContext/config";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import { mediaQueries } from "src/utils/responsive";

const Paginator: React.FC = () => {
  const { curPage, numPages, pageToChildIndex, onTurnPrev, onTurnNext } =
    useStorybookContext();
  const theme = useTheme();
  const {
    responses,
    responses: { short_grad_level },
  } = useResponse();

  /**
   * Children are laid out as:
   *  - front cover
   *  - blank pages
   *  - Q1: build a boat
   *  - ...
   *  - Q7: grad 9
   *  - blank page 10
   *  - back cover 11
   * return the question index, or -1 if not valid
   */
  const childIndexToQuestionNumber = (idx: number) => {
    if (idx < 2 || idx > pageToChildIndex(numPages) - 2) {
      return -1;
    }
    return idx - 2 + 1; // add 1 since questions are 1-indexed
  };
  const location = useLocation();
  const isGradRoute = location.pathname === RouteName.SHORT_ANSWER_GRAD;
  const isBoatRoute = location.pathname === RouteName.BUILD_A_BOAT;
  const curStage = Object.keys(RouteName).find(
    (key) => RouteName[key] === location.pathname
  );
  const curResponse = curStage
    ? shortAnswerRouteToResponse[location.pathname]
    : "";
  const { value } = curResponse && !isGradRoute ? responses[curResponse] : "";
  const [hasContent, setHasContent] = useState(false);
  useEffect(() => {
    setHasContent(value !== null && value !== "" && value !== undefined);
  }, [value, location.pathname]);
  const gradAns = isGradRoute
    ? {
        level_of_study: responses[curResponse[0]].value,
        graduating_year: responses[curResponse[1]].value,
      }
    : null;

  const validGradContent =
    (gradAns?.level_of_study !== "" &&
      gradAns?.level_of_study !== undefined &&
      gradAns?.graduating_year !== 0 &&
      gradAns?.graduating_year !== undefined) ||
    gradAns?.level_of_study === "other";

  const hasGradContent = isGradRoute ? validGradContent : false;
  const isHighSchoolStudent = short_grad_level.value == "high school";
  const isOtherStudent = short_grad_level.value == "other";

  const isNextButtonDisabled = () => {
    if (isBoatRoute) {
      return false;
    }
    if (isGradRoute) {
      return !hasGradContent;
    }
    return !hasContent;
  };

  const pageToQuestionNum = (page: number) =>
    childIndexToQuestionNumber(pageToChildIndex(page));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const questionNum = useMemo(() => pageToQuestionNum(curPage), [curPage]);
  const isPageAfterGrad = curPage >= 14;

  const calculateQuestionNum = () => {
    if (isHighSchoolStudent && isPageAfterGrad) {
      return questionNum - 1;
    } else if (isOtherStudent && isPageAfterGrad) {
      return questionNum - 2;
    }
    return questionNum;
  };
  const calculateTotalQuestions = () => {
    if (isHighSchoolStudent) {
      return 6;
    }
    if (isOtherStudent) {
      return 5;
    }
    return 7;
  };
  const totalQuestions = calculateTotalQuestions();
  const revisedQuestionNum = calculateQuestionNum();

  return questionNum > 0 ? (
    <PaginationWrapper>
      <GradientText tw="mr-20!">
        QUESTION {revisedQuestionNum} OF {totalQuestions}
      </GradientText>
      <PaginatorButton className="left" onClick={onTurnPrev}>
        <Icon
          name="chevronLeft"
          width="24px"
          height="24px"
          stroke={theme.color.primary.blue2}
          opacity={isBoatRoute ? 0.3 : 1}
        />
      </PaginatorButton>
      <PaginatorButton
        className="right"
        disabled={isNextButtonDisabled()}
        onClick={onTurnNext}
      >
        <Icon
          name="chevronRight"
          width="24px"
          height="24px"
          stroke={theme.color.primary.blue2}
          opacity={isNextButtonDisabled() ? 0.3 : 1}
        />
      </PaginatorButton>
    </PaginationWrapper>
  ) : null;
};

const PaginationWrapper = styled(Flex)`
  align-self: flex-end;
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  bottom: -4em;
  user-select: none;

  ${mediaQueries.tablet} {
    align-self: inherit;
    position: relative;
    right: inherit;
    bottom: inherit;
    margin-left: auto;
  }
`;

const PaginatorButton = styled.button`
  cursor: pointer;
  background: ${({ theme }) => theme.color.secondary.paginatorBackground};
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.color.primary.blue1};
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -2px;
  }

  &:hover {
    background: #efefef;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.color.secondary.paginatorBackground};
  }
`;

export default Paginator;
