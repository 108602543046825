import React, { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { styled } from "twin.macro";

import { Link, Text, TexturedAsset } from "src/components";
import { PROGRAM_TO_ASSET } from "src/constants/copy";
import { useSiteContext } from "src/contexts";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import {
  GraduatingStudentImg,
  StudentImg,
  LogoImg,
  GradientImg,
  TextureUrl,
} from "src/static";

type Props = ComponentPropsWithoutRef<"div">;

const SUBMITTED_BOAT_QUOTE = "We did it!";

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontSize.h1D}px;
  width: fit-content;
  position: relative;
  top: 33%;
  transform: translate(-50%, 12%);
`;

const StyledSubtitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.body}px;
  width: fit-content;
  position: relative;
  top: 37%;
  transform: translate(-49.5%, 4%);
  text-align: center;
  width: 460px;
`;

const ClickAnywhereToContinueWrapper = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.body}px;
  font-weight: 500;
  width: fit-content;
  position: relative;
  top: 50%;
  transform: translate(-49%, 7%);
  animation: fadeIn linear 3s;
  z-index: 2;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 92px;
  left: -53px;
  pointer-events: none;
`;

const BackgroundGradient = styled.img`
  position: absolute;
  width: 1366px;
  height: auto;
  bottom: 0px;
  right: 0px;
  pointer-events: none;
`;

const ProgramAssetWrapper = styled.div`
  position: absolute;
  bottom: -255px;
  right: 0px;
  width: 745px;
  pointer-events: none;
`;

const StudentAssetWrapper = styled.div`
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  bottom: -30px;
  right: -190px;
  width: 598px;
  opacity: 0.4;
  pointer-events: none;
`;

const ThanksRight: React.FC<Props> = ({ children, ...rest }) => {
  const { onTurnNext } = useStorybookContext();

  const {
    responses: { short_grad_year, short_program },
  } = useResponse();
  const { setBoatPosition, setBoatQuote } = useSiteContext();
  const [clickToContinue, setClickToContinue] = useState<boolean>(false);

  const CURRENT_GRADUATING_YEAR = 2021;
  const NEXT_GRADUATING_YEAR = 2022;
  const Program = PROGRAM_TO_ASSET(short_program.value, false);

  const handleClick = () => {
    if (clickToContinue) {
      onTurnNext();
      setBoatPosition(9);
      setBoatQuote(SUBMITTED_BOAT_QUOTE);
      setClickToContinue(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setClickToContinue(true);
    }, 2000);
    window.addEventListener("click", handleClick);

    return () => window.removeEventListener("click", handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickToContinue]);

  return (
    <Container {...rest}>
      <BackgroundGradient src={GradientImg} />
      <LogoWrapper>
        <LogoImg />
      </LogoWrapper>
      <StyledTitle>Application Sent! You&apos;re all set.</StyledTitle>
      <StyledSubtitle>
        If you don&apos;t receive a confirmation email within the next 10
        minutes, please reach out to us at hello@hackthenorth.com.
      </StyledSubtitle>
      <ClickAnywhereToContinueWrapper>
        Click anywhere to continue
      </ClickAnywhereToContinueWrapper>

      <StudentAssetWrapper>
        <TexturedAsset texture={TextureUrl}>
          {short_grad_year.value === CURRENT_GRADUATING_YEAR ||
          short_grad_year.value === NEXT_GRADUATING_YEAR ? (
            <GraduatingStudentImg />
          ) : (
            <StudentImg />
          )}
        </TexturedAsset>
      </StudentAssetWrapper>
      <ProgramAssetWrapper>
        <TexturedAsset texture={TextureUrl}>
          <Program />
        </TexturedAsset>
      </ProgramAssetWrapper>
    </Container>
  );
};

export default ThanksRight;
