import React, { useMemo } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";

import { normalTextStyles } from "../Text";

export type TLinkProps = React.ComponentPropsWithoutRef<"a">;

const isRelativeHref = (href: string) => {
  try {
    new URL(href);
    return false;
  } catch (err) {
    return true;
  }
};

const Link: React.FC<TLinkProps> = ({ children, href = "", ...rest }) => {
  const isInternal: boolean = useMemo(() => isRelativeHref(href), [href]);

  if (isInternal) {
    return (
      <ReactRouterLink to={href} {...rest}>
        <StyledLink>{children}</StyledLink>
      </ReactRouterLink>
    );
  } else {
    return (
      <StyledLink href={href} {...rest}>
        {children}
      </StyledLink>
    );
  }
};

const StyledLink = styled.a`
  ${normalTextStyles}
  color: ${({ theme }) => theme.color.primary.blue1};
  text-decoration: none;
  outline: none;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: ${({ theme }) => theme.color.primary.blue1};
    width: 0px;
    height: 1px;
    transition: width 250ms;
  }
  &:focus:after,
  &:hover:after,
  .focus-visible:after {
    width: 100%;
  }
`;

export default Link;
