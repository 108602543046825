import React from "react";

import { RouteName, STORYBOOK_ROUTES } from "src/constants/route";
import { useSiteContext } from "src/contexts/SiteContext";
import { StorybookContextProvider } from "src/contexts/StorybookContext";
import { COVER_SIZE } from "src/contexts/StorybookContext/Storybook/constants";
import {
  CoverPage,
  NamePage,
  GradPage,
  SchoolPage,
  ProgramPage,
  LocationPage,
  ExperiencePage,
  BuildPage,
  BlankPage,
} from "src/stages/ShortAnswerStage/pages";

const duplicateArray = <T extends string = string>(arr: T[]) =>
  arr.map((route) => [route, route]).flat();

const ShortAnswerStage: React.FC = () => {
  const { isMobile } = useSiteContext();

  const coverPageStyle = isMobile
    ? {}
    : {
        width: COVER_SIZE.width,
        height: COVER_SIZE.height,
      };

  return (
    <div className="flex-1 flex h-full w-full items-center justify-center">
      {/* the storybook pages are 1-indexed but routes are 0-indexed, so insert blank at start */}
      <StorybookContextProvider
        routes={[
          RouteName.LANDING,
          ...duplicateArray<RouteName>(STORYBOOK_ROUTES),
        ]}
        skipFirstPage
        disableLastPage
      >
        <CoverPage className="hard own-size" style={coverPageStyle} />
        <>
          <BlankPage
            className="hard fixed own-size"
            style={{ ...coverPageStyle }}
          />
          <BlankPage style={{ background: "#FFFCFC" }} />
        </>
        <BuildPage className="fixed own-size" />
        <NamePage className="fixed own-size" offset={1} />
        <GradPage className="fixed own-size" offset={2} />
        <SchoolPage className="fixed own-size" offset={3} />
        <ProgramPage className="fixed own-size" offset={3} />
        <LocationPage className="fixed own-size" offset={4} />
        <ExperiencePage className="fixed own-size" offset={5} />
        <>
          <BlankPage style={{ background: "#FFFCFC" }} />
          <BlankPage
            className="hard fixed own-size"
            style={{ ...coverPageStyle }}
          />
        </>
        <BlankPage
          className="hard fixed own-size"
          style={{ ...coverPageStyle }}
        />
      </StorybookContextProvider>
    </div>
  );
};

export default ShortAnswerStage;
