import { log } from "@hackthenorth/analytics";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { styled } from "twin.macro";

import { ExclamationIcon, successToast } from "src/components";
import {
  Spacer,
  Button,
  HorizontalRule,
  LongAnswerQuestion,
  LongAnswerPage,
  TextAreaInput,
  Flex,
  TagInput,
  TextInput,
  Text,
} from "src/components";
import {
  HACKER_TYPES,
  LINK_TYPES,
  LONG_ANSWER_TITLES,
} from "src/constants/copy";
import { RouteName } from "src/constants/route";
import { useSiteContext } from "src/contexts";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { ClipboardIcon } from "src/static/icons";

const VALID_LONG_ANSWERS_BOAT_QUOTE = "It's a-boat time!";

const getInputInfo = (index: number) => {
  if (index == 0) {
    return [LINK_TYPES[index][0], LINK_TYPES[index][1]];
  } else if (index == 1) {
    return [LINK_TYPES[index][0], LINK_TYPES[index][1]];
  } else if (index == 2) {
    return [LINK_TYPES[index][0], LINK_TYPES[index][1]];
  } else {
    return ["Other", "Enter a link here"];
  }
};

const cleanLinks = (links: string[], setLinks: (l: string[]) => void) => {
  setLinks([
    ...links.slice(0, 3),
    ...links.slice(3).filter((link) => link !== ""),
  ]);
};

const LongAnswerSection: React.FC = () => {
  const { isMobile } = useSiteContext();
  const { responses, saveResponses } = useResponse();
  const {
    long_collaboration,
    long_desire,
    long_hacker_type,
    long_links,
    long_obstacle,
  } = responses;

  const {
    value: links,
    setValue: setLinks,
    errors: linkErrors,
    config: linkConfig,
    saved: isLinksSaved,
  } = long_links;

  const {
    value: desire,
    setValue: setDesire,
    errors: desireErrors,
    config: desireConfig,
    saved: isDesireSaved,
  } = long_desire;

  const {
    value: hackerTypes,
    setValue: setHackerTypes,
    errors: hackerTypesErrors,
    config: hackerTypesConfig,
    saved: isHackerTypesSaved,
  } = long_hacker_type;

  const {
    value: obstacle,
    setValue: setObstacle,
    errors: obstacleErrors,
    config: obstacleConfig,
    saved: isObstacleSaved,
  } = long_obstacle;

  const {
    value: collaboration,
    setValue: setCollaboration,
    errors: collaborationErrors,
    config: collaborationConfig,
    saved: isCollaborationSaved,
  } = long_collaboration;

  const formErrors = [
    ...desireErrors,
    ...hackerTypesErrors,
    ...obstacleErrors,
    ...collaborationErrors,
    ...linkErrors,
  ];

  const [didEdit, setDidEdit] = useState({
    collaboration: false,
    desire: false,
    obstacle: false,
    hackerTypes: false,
    links: false,
  });

  const [inputSelected, setInputSelected] = useState("");
  const { navigate, setBoatQuote } = useSiteContext();

  const setLongAnswerBoatQuote = () => {
    if (!formErrors.join("")) {
      setBoatQuote(VALID_LONG_ANSWERS_BOAT_QUOTE);
    } else {
      setBoatQuote(null);
    }
  };

  useEffect(() => {
    cleanLinks(links, setLinks);
    setLongAnswerBoatQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLongAnswerBoatQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desireErrors, hackerTypesErrors, collaborationErrors, linkErrors]);

  const getClipboardText = () => {
    return (
      `1. ${LONG_ANSWER_TITLES[0]} (500 characters)\n\n` +
      `2. ${LONG_ANSWER_TITLES[1]} (1200 characters)\n\n` +
      `3. ${LONG_ANSWER_TITLES[2]} (1200 characters)\n`
    );
  };

  const desireRef = useRef(null);
  const collaborationRef = useRef(null);
  const obstacleRef = useRef(null);
  const hackerTypeRef = useRef(null);
  const linkRef = useRef(null);

  const executeScroll = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  };

  return (
    <LongAnswerPage>
      <Flex column>
        <HeaderSection>
          <Text heading size="h2D">
            Write Your Story
          </Text>
          {!isMobile && (
            <Button
              link
              onClick={() => {
                navigator.clipboard.writeText(getClipboardText());
                log({
                  identifier: "copy-questions",
                  duration: 0,
                  startTime: 0,
                  stream: "usage",
                });
                successToast("Questions copied to clipboard!");
              }}
            >
              <Flex row align="center">
                <ClipboardIcon />
                <Text link>Copy questions to clipboard</Text>
              </Flex>
            </Button>
          )}
        </HeaderSection>
        <Text>
          Time to fill in the details! Complete the application questions below
          to narrate your goals and motivations for attending Hack the North
          2021.
        </Text>
        {isMobile && (
          <>
            <Spacer height={24} />
            <Button
              link
              onClick={() => {
                navigator.clipboard.writeText(getClipboardText());
                log({
                  identifier: "copy-questions",
                  duration: 0,
                  startTime: 0,
                  stream: "usage",
                });
                successToast("Questions copied to clipboard!");
              }}
            >
              <Flex row align="center">
                <ClipboardIcon />
                <Text link>Copy questions to clipboard</Text>
              </Flex>
            </Button>
          </>
        )}
      </Flex>
      <HorizontalRule />
      <span ref={desireRef} />
      <LongAnswerQuestion
        step={0}
        isRequired={desireConfig.required}
        errors={desireErrors}
        isSaved={isDesireSaved}
        didEdit={didEdit.desire}
      >
        <TextAreaInput
          placeholder="Type your answer here..."
          charLimit={500}
          value={desire ?? ""}
          errors={desireErrors}
          isSelected={inputSelected == "desire"}
          didEdit={didEdit.desire}
          onChange={(e) => {
            if (!didEdit.desire) {
              setDidEdit((prev) => ({
                ...prev,
                desire: true,
              }));
            }
            setDesire(e.target.value);
          }}
          onBlur={() => setInputSelected("")}
          onFocus={() => setInputSelected("desire")}
        />
      </LongAnswerQuestion>
      <span ref={collaborationRef} />
      <LongAnswerQuestion
        step={1}
        isRequired={collaborationConfig.required}
        errors={collaborationErrors}
        isSaved={isCollaborationSaved}
        didEdit={didEdit.collaboration}
      >
        <TextAreaInput
          placeholder="Type your answer here..."
          charLimit={1200}
          value={collaboration ?? ""}
          didEdit={didEdit.collaboration}
          errors={collaborationErrors}
          isSelected={inputSelected == "collaboration"}
          onChange={(e) => {
            if (!didEdit.collaboration) {
              setDidEdit((prev) => ({
                ...prev,
                collaboration: true,
              }));
            }
            setCollaboration(e.target.value);
          }}
          onBlur={() => setInputSelected("")}
          onFocus={() => setInputSelected("collaboration")}
        />
      </LongAnswerQuestion>
      <span ref={obstacleRef} />
      <LongAnswerQuestion
        step={2}
        isRequired={obstacleConfig.required}
        errors={obstacleErrors}
        isSaved={isObstacleSaved}
        didEdit={didEdit.obstacle}
      >
        <TextAreaInput
          placeholder="Type your answer here..."
          charLimit={1200}
          value={obstacle ?? ""}
          errors={obstacleErrors}
          didEdit={didEdit.obstacle}
          onChange={(e) => {
            if (!didEdit.obstacle) {
              setDidEdit((prev) => ({
                ...prev,
                obstacle: true,
              }));
            }
            setObstacle(e.target.value);
          }}
          isSelected={inputSelected == "obstacle"}
          onBlur={() => setInputSelected("")}
          onFocus={() => setInputSelected("obstacle")}
        />
      </LongAnswerQuestion>
      <span ref={hackerTypeRef} />
      <LongAnswerQuestion
        isRequired={hackerTypesConfig.required}
        step={3}
        errors={hackerTypesErrors}
        isSaved={isHackerTypesSaved}
        didEdit={didEdit.hackerTypes}
        requiresAtLeastOne
      >
        <TagInput
          multi
          options={HACKER_TYPES}
          selectedOptions={hackerTypes}
          setSelectedOptions={(newSelection) => {
            setHackerTypes(newSelection);
          }}
        />
      </LongAnswerQuestion>
      <span ref={linkRef} />
      <LongAnswerQuestion
        step={4}
        isRequired={linkConfig.required}
        showSurveyButton={true}
        errors={linkErrors}
        didEdit={didEdit.links}
        isSaved={!linkErrors.length && isLinksSaved}
      >
        <LinkContainer>
          {links.map((link, i) => {
            const [name, placeholder] = getInputInfo(i);
            const error = !!linkErrors[i];
            return (
              <Fragment key={i}>
                <Flex column align="flex-start">
                  {error && link ? (
                    <Flex align="center">
                      <PlatformLinkText>{`${name}:`}</PlatformLinkText>
                      <Flex>
                        <ExclamationIcon />
                        <StatusText error={error}>{linkErrors[i]}</StatusText>
                      </Flex>
                    </Flex>
                  ) : (
                    <PlatformLinkText>{`${name}:`}</PlatformLinkText>
                  )}
                  <TextInput
                    placeholder={placeholder}
                    value={link}
                    error={error}
                    onBlur={() => setInputSelected("")}
                    onChange={(e) => {
                      if (!didEdit.links[i]) {
                        setDidEdit((prev) => ({
                          ...prev,
                          links: true,
                        }));
                      }
                      setLinks((prev) => [
                        ...prev.slice(0, i),
                        e.target.value,
                        ...prev.slice(i + 1),
                      ]);
                    }}
                  />
                </Flex>
              </Fragment>
            );
          })}
        </LinkContainer>
        <Button link onClick={() => setLinks((prev) => [...prev, ""])}>
          + Add another link
        </Button>
        <Spacer height={24} />
        <Flex row justify="flex-end">
          <Button
            onClick={() => {
              if (!formErrors.join("")) {
                cleanLinks(links, setLinks);
                saveResponses().then((savedSuccessfully) => {
                  if (savedSuccessfully) navigate(RouteName.SURVEY);
                });
              } else {
                setDidEdit({
                  collaboration: true,
                  desire: true,
                  obstacle: true,
                  hackerTypes: true,
                  links: true,
                });
                // scroll to first section with error
                if (desireErrors.join("")) {
                  executeScroll(desireRef);
                } else if (collaborationErrors.join("")) {
                  executeScroll(collaborationRef);
                } else if (obstacleErrors.join("")) {
                  executeScroll(obstacleRef);
                } else if (hackerTypesErrors.join("")) {
                  executeScroll(hackerTypeRef);
                } else if (linkErrors.join("")) {
                  executeScroll(linkRef);
                }
              }
            }}
          >
            Continue to survey questions
          </Button>
        </Flex>
      </LongAnswerQuestion>
    </LongAnswerPage>
  );
};

export default LongAnswerSection;

const HeaderSection = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const HackerTypeText = styled(Text)`
  color: ${({ theme }) => theme.color.primary.blue1};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: 500;
  font-size: 18;
  margin-left: 15px;
`;

const PlatformLinkText = styled(HackerTypeText)`
  color: ${({ theme }) => theme.color.text.blue};
  line-height: 33px;
  margin-left: 0px;
  margin-right: 15px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 20px;
`;

const StatusText = styled(Text)<{ error: boolean }>`
  color: ${({ theme, error }) =>
    error ? theme.color.secondary.red : theme.color.secondary.green};
`;
