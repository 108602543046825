import React from "react";
import styled from "styled-components";

import { Flex } from "src/components";
import { mediaQueries } from "src/utils/responsive";

const ErrorPageWrapper: React.FC = ({ children }) => (
  <Container>
    <PageFlexWrapper column align="center">
      {children}
    </PageFlexWrapper>
  </Container>
);

const Container = styled(Flex).attrs({ align: "center", justify: "center" })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  ${mediaQueries.largeMobile} {
    padding: 50px;
    height: -webkit-fill-available;
  }
`;

const PageFlexWrapper = styled(Flex)`
  ${mediaQueries.medium} {
    align-items: baseline;
  }

  ${mediaQueries.largeMobile} {
    width: 100%;
  }
`;

export default ErrorPageWrapper;
