import React, { forwardRef, ComponentPropsWithoutRef, RefObject } from "react";
import { useNavigate } from "react-router";
import { styled } from "twin.macro";

import { Button } from "src/components";
import { RouteName } from "src/constants/route";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useWindowSize } from "src/utils/useWindowSize";

import Paginator from "../Paginator";

import { STORYBOOK_SIZE } from "./constants";

type TStorybookProps = ComponentPropsWithoutRef<"div"> & {
  hidePaginator?: boolean;
};

const SCALE_BREAKPOINT = 1550;

const Storybook = forwardRef<HTMLDivElement, TStorybookProps>(
  ({ children, hidePaginator, ...rest }, ref) => {
    const { windowWidth } = useWindowSize();
    const navigate = useNavigate();
    const { isApplicationSubmitted } = useResponse();
    let scaleFactor = 1.0;
    if (windowWidth && windowWidth <= SCALE_BREAKPOINT) {
      scaleFactor = windowWidth / SCALE_BREAKPOINT;
    }

    return (
      <Centered>
        <StorybookWrapper scaleFactor={scaleFactor}>
          <div ref={ref as RefObject<HTMLDivElement>} id="flipbook" {...rest}>
            {children}
          </div>
          {location.pathname === RouteName.LANDING && !isApplicationSubmitted && (
            <div
              tw="m-auto text-center"
              style={{
                position: "absolute",
                width: "100%",
                margin: "0 auto",
                bottom: "-64px",
              }}
            >
              <Button
                link
                tw="leading-loose"
                onClick={() => navigate("/alternative-form")}
              >
                Having trouble loading? Fill out this form instead.
              </Button>
            </div>
          )}
          {!hidePaginator && <Paginator />}
        </StorybookWrapper>
      </Centered>
    );
  }
);

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const StorybookWrapper = styled.div<{ scaleFactor: number }>`
  position: relative;
  width: ${STORYBOOK_SIZE.width}px;
  height: ${STORYBOOK_SIZE.height}px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 250px;
  transform: scale(${({ scaleFactor }) => scaleFactor});
`;

Storybook.displayName = "Storybook";

export default Storybook;
