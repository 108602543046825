import React, { useState, useCallback, useRef, useEffect } from "react";
import { useLocation } from "react-router";
import Select, { Props, OptionTypeBase, OptionsType } from "react-select";
import Creatable from "react-select/creatable";
import styled, { css } from "styled-components";

import { RouteName } from "src/constants/route";
import { optionsToAnswers } from "src/utils/react-select";
import { mediaQueries } from "src/utils/responsive";

import { normalTextStyles } from "../base/Text";

import MultiValueRemove from "./MultiValueRemoveComponent";
import { useDynamicDropdownStyles } from "./useDynamicDropdownStyles";

export type TDropdownTagInputProps = Omit<Props, "theme"> & {
  creatable?: boolean;
  multi?: boolean;
  shortAnswer?: boolean;
  onEnter?: () => void;
  focusOnFlip?: boolean;
  route?: RouteName;
  max?: number;
};

export const selectStyles = css<TDropdownTagInputProps>`
  ${normalTextStyles}
  outline: none;
  font-weight: 800;
  line-height: normal;
  color: ${({ theme }) => theme.color.primary.blue2};
  max-width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme, shortAnswer }) =>
    shortAnswer ? theme.fontSize.h3D : theme.fontSize.body}px;

  padding-bottom: 15px;

  .select__control {
    box-shadow: none;
    outline: none;
    min-height: 64px;
    border: 1px solid #006691;
    border-radius: 16px;
    background: #fafcff;
    padding: 0px 4px;
    &:hover {
      cursor: pointer;
      border-color: ${({ theme }) => theme.color.primary.blue3};
    }
    &::after {
      height: ${({ shortAnswer }) => (shortAnswer ? "6px" : "4px")};
      bottom: ${({ shortAnswer }) => (shortAnswer ? "-6px" : "-4px")};
    }
    ${mediaQueries.largeMobile} {
      min-height: 45px;
      font-size: ${({ theme, shortAnswer }) =>
        shortAnswer ? theme.fontSize.h4M : theme.fontSize.body}px;
    }
  }

  .select__control--menu-is-open {
    border-radius: ${({ menuClosed }) => !menuClosed && "16px 16px 0px 0px"};
  }

  .select__option {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: 500;
    padding: 12px;
    font-size: ${({ theme }) => theme.fontSize.body}px;
    color: ${({ theme }) => theme.color.text.blue};
    border-top: 0;
    cursor: pointer;
    ${mediaQueries.largeMobile} {
      font-size: ${({ theme }) => theme.fontSize.body}px;
    }
  }

  .select__option--is-focused {
    background: ${({ theme }) => theme.color.secondary.lightBlue};
  }

  .select__option--is-selected {
    background: ${({ theme }) => theme.color.primary.blue2};
  }

  .select__value-container {
    outline: none;
    font-weight: ${({ theme, shortAnswer }) => (shortAnswer ? 800 : 400)};
    flex-wrap: wrap;
    ${mediaQueries.largeMobile} {
      max-width: 350px;
    }
    ${mediaQueries.smallMobile} {
      max-width: 300px;
    }
  }

  .select__menu {
    border-top: 0px;
    margin-top: -16px;
    padding: 0px;
    box-shadow: none;
    position: absolute;
    border-radius: 16px;
  }

  .select__menu-list {
    padding: 0;
    border: 3px solid #7ec2e4;
    border-radius: 0px 0px 16px 16px;
    max-height: 200px;
  }

  .select__indicator-separator {
    visibility: hidden;
  }

  .select__indicators {
    outline: none;
  }

  .select__single-value {
    color: ${({ theme, shortAnswer }) =>
      shortAnswer ? theme.color.primary.blue2 : theme.color.text.grey};
    font-family: ${({ theme, shortAnswer }) =>
      shortAnswer ? theme.fontFamily.heading : theme.fontFamily.body};
    line-height: ${({ shortAnswer }) => (shortAnswer ? "45px" : "normal")};
    font-size: ${({ theme, shortAnswer }) =>
      shortAnswer ? theme.fontSize.h3D : theme.fontSize.body}px;
    ${mediaQueries.largeMobile} {
      font-size: ${({ theme, shortAnswer }) =>
        shortAnswer ? theme.fontSize.h4M : theme.fontSize.body}px;
    }
  }

  .select__input {
    color: ${({ theme, shortAnswer }) =>
      shortAnswer ? theme.color.primary.blue2 : theme.color.text.grey};
    & input {
      font-weight: ${({ theme, shortAnswer }) => (shortAnswer ? 800 : 400)};
      font-family: ${({ theme, shortAnswer }) =>
        shortAnswer ? theme.fontFamily.heading : theme.fontFamily.body};
      line-height: ${({ shortAnswer }) => (shortAnswer ? "45px" : "normal")};
    }
  }

  .select__placeholder {
    opacity: 0.3;
    color: ${({ theme }) => theme.color.text.blue};
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.body};
  }

  .select__multi-value {
    padding: 6px 36px;
    border-radius: 43px;
    background-color: ${({ theme }) => theme.color.primary.blue2};
    color: white;
    align-items: center;
    margin-right: 16px;
    flex-wrap: wrap;
  }

  .select__multi-value__label {
    color: white;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    font-weight: 500;
    line-height: 33px;
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.body}px;
  }

  .select__clear-indicator {
    visibility: hidden;
  }

  .select__multi-value__remove {
    background-color: ${({ theme }) => theme.color.primary.blue2};
    margin-left: 16px;
    position: relative;
    bottom: -1px;
    &:focus,
    :hover {
      background-color: ${({ theme }) => theme.color.primary.blue2};
      cursor: pointer;
      > svg path {
        stroke: ${({ theme }) => theme.color.primary.blue3};
      }
    }
  }
`;

const StyledSelect = styled(Select)<TDropdownTagInputProps>`
  ${selectStyles}
`;

const StyledCreatable = styled(Creatable)<TDropdownTagInputProps>`
  ${selectStyles}
`;

const DropdownTagInput: React.FC<TDropdownTagInputProps> = ({
  creatable = false,
  multi = false,
  shortAnswer = false,
  onEnter,
  value,
  onChange,
  options,
  onInputChange,
  focusOnFlip = false,
  route,
  isSearchable = false,
  max,
  ...rest
}) => {
  const maxOptionsSelected = value && max ? value.length === max : false;
  const styles = useDynamicDropdownStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>(
    value ? value.label : ""
  );
  const [displayedOptions, setDisplayedOptions] = useState<OptionTypeBase[]>(
    options || []
  );
  const [showValue, setShowValue] = useState<boolean>(true); //show value within input component
  const location = useLocation();

  useEffect(() => {
    if (inputRef.current && focusOnFlip && route === location.pathname) {
      inputRef.current.focus();
    }
  }, [location.pathname]);

  const handleKeyPress = useCallback(
    (e: any) => {
      const menuClosed = displayedOptions.length === 0;
      if (e.key === "Enter" && menuClosed && onEnter) {
        onEnter();
      }
    },
    [displayedOptions, onEnter]
  );

  const handleOptionChange = (option: OptionTypeBase) => {
    // react-select hides the input on menu close if closeMenuOnSelect={true}, so keep that prop as false but display an empty menu
    // on change
    // ref: https://github.com/JedWatson/react-select/blob/b8298f4c43a54c19fce48536fbb1cf11a8b01c68/src/Select.js#L604
    setInputValue(option.label);
    onChange(option);
    setDisplayedOptions([]);
  };

  const handleInputChange = (text: string) => {
    // on input change, "open" the menu again
    setInputValue(text);
    setDisplayedOptions(options);
  };

  const isValidNewOption = (
    inputValue: string,
    _: OptionTypeBase,
    options: OptionsType<OptionTypeBase>
  ) => {
    return (
      !maxOptionsSelected &&
      options.length > 0 &&
      !optionsToAnswers(options).includes(inputValue) &&
      inputValue !== ""
    );
  };

  const Select = creatable ? StyledCreatable : StyledSelect;

  return (
    <Select
      controlShouldRenderValue={multi || !isSearchable || showValue}
      ref={inputRef}
      className="select"
      classNamePrefix="select"
      closeMenuOnSelect={false}
      createOptionPosition="first"
      styles={styles}
      components={{ MultiValueRemove }}
      shortAnswer={shortAnswer}
      onInputChange={handleInputChange}
      isMulti={multi}
      inputValue={isSearchable ? inputValue || "" : undefined}
      onKeyDown={handleKeyPress}
      onChange={multi ? onChange : handleOptionChange}
      onFocus={() => {
        setShowValue(false);
        setInputValue(value ? value.label : "");
      }}
      onBlur={() => {
        setShowValue(true);
      }}
      options={maxOptionsSelected ? [] : displayedOptions}
      value={value}
      isSearchable={isSearchable}
      isValidNewOption={(
        inputValue: string,
        selected: OptionsType<OptionTypeBase>,
        selectOptions: OptionsType<OptionTypeBase>
      ) =>
        !selected.some((opt) => opt.value === inputValue) &&
        selectOptions.every(
          (opt) => !opt.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      }
      tabSelectsValue={false}
      noOptionsMessage={() => null}
      formatCreateLabel={(inputValue: string) =>
        inputValue ? `Add option "${inputValue}"` : "Add another option"
      }
      menuClosed={displayedOptions.length === 0}
      {...rest}
    />
  );
};

export default DropdownTagInput;
