import React from "react";
import { OptionTypeBase } from "react-select";
import styled from "styled-components";

import {
  ShortAnswerQuestion,
  ShortAnswerPage,
  DropdownTagInput,
  InkTransition,
  Spacer,
  TexturedAsset,
} from "src/components";
import { PROGRAM_OPTIONS, PROGRAM_TO_ASSET } from "src/constants/copy";
import { RouteName } from "src/constants/route";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import StorybookPaginator from "src/contexts/StorybookContext/Paginator";
import { TStorybookPageProps } from "src/contexts/StorybookContext/Storybook/constants";
import { ProgramEngineeringImg, TextureUrl } from "src/static";
import { answersToOptions, answerToOption } from "src/utils/react-select";

import AssetPage from "../AssetPage";
import { ShortAnswerMobileSVGStyles } from "../GradPage";
import { BodyText } from "../NamePage";

const ProgramAssetWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  ${({ isMobile }) =>
    isMobile &&
    `
    transform: translateX(20%) translateY(13%);
    max-width: 400px;
    margin-left: auto;
  `}
  ${ShortAnswerMobileSVGStyles}
`;

const Program: React.FC<TStorybookPageProps> = (props) => {
  const { isMobile } = useSiteContext();
  const {
    responses: { short_program },
    saveResponses,
  } = useResponse();

  const { value, setValue } = short_program;

  const { onTurnNext } = useStorybookContext();
  const Program = PROGRAM_TO_ASSET(value, true);
  const hasContent = value !== null && value !== "" && value !== undefined;

  return (
    <>
      <AssetPage {...props}>
        {hasContent ? (
          <InkTransition>
            <TexturedAsset texture={TextureUrl}>
              <ProgramAssetWrapper isMobile={isMobile}>
                <Program />
              </ProgramAssetWrapper>
            </TexturedAsset>
          </InkTransition>
        ) : null}
      </AssetPage>
      <ShortAnswerPage {...props}>
        <ShortAnswerQuestion showButton={hasContent} buttonText="Cool!">
          <BodyText>...where I will be studying</BodyText>
          {isMobile ? <Spacer height={24} /> : <Spacer height={46} />}

          <DropdownTagInput
            shortAnswer
            creatable
            isSearchable
            placeholder="My program name"
            options={answersToOptions(PROGRAM_OPTIONS)}
            value={value ? answerToOption(value) : undefined}
            onChange={(option: OptionTypeBase) => setValue(option.value)}
            onKeyDown={(e: any) => {
              if (
                e.key === "Enter" &&
                hasContent &&
                location.pathname == RouteName.SHORT_ANSWER_PROGRAM
              ) {
                onTurnNext();
              }
            }}
            tabIndex={0}
            focusOnFlip
            route={RouteName.SHORT_ANSWER_PROGRAM}
          />
        </ShortAnswerQuestion>
        {isMobile && <StorybookPaginator />}
      </ShortAnswerPage>
    </>
  );
};

export default Program;
