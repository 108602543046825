export enum BaseRoute {
  LANDING = "/",
  BUILD_A_BOAT = "/boat",
  SHORT_ANSWER = "/short",
  CREATE_ACCOUNT = "/account",
  LONG_ANSWER = "/long",
  SURVEY = "/survey",
  REVIEW = "/review",
  APPLICATION_SUBMITTED = "/submitted",
  PLAYGROUND = "/playground",
  NOT_FOUND = "/404",
}

export enum ShortAnswerRoute {
  FULL_NAME = "/name",
  GRADUATING_YEAR = "/grad",
  SCHOOL = "/school",
  PROGRAM = "/program",
  LOCATION = "/location",
  EXPERIENCE = "/experience",
}

export const RouteName = {
  LANDING: BaseRoute.LANDING as const,
  BUILD_A_BOAT: BaseRoute.BUILD_A_BOAT as const,
  SHORT_ANSWER: BaseRoute.SHORT_ANSWER as const,
  SHORT_ANSWER_NAME:
    `${BaseRoute.SHORT_ANSWER}${ShortAnswerRoute.FULL_NAME}` as const,
  SHORT_ANSWER_GRAD:
    `${BaseRoute.SHORT_ANSWER}${ShortAnswerRoute.GRADUATING_YEAR}` as const,
  SHORT_ANSWER_SCHOOL:
    `${BaseRoute.SHORT_ANSWER}${ShortAnswerRoute.SCHOOL}` as const,
  SHORT_ANSWER_PROGRAM:
    `${BaseRoute.SHORT_ANSWER}${ShortAnswerRoute.PROGRAM}` as const,
  SHORT_ANSWER_LOCATION:
    `${BaseRoute.SHORT_ANSWER}${ShortAnswerRoute.LOCATION}` as const,
  SHORT_ANSWER_EXPERIENCE:
    `${BaseRoute.SHORT_ANSWER}${ShortAnswerRoute.EXPERIENCE}` as const,
  CREATE_ACCOUNT: BaseRoute.CREATE_ACCOUNT as const,
  LONG_ANSWER: BaseRoute.LONG_ANSWER as const,
  SURVEY: BaseRoute.SURVEY as const,
  REVIEW: BaseRoute.REVIEW as const,
  APPLICATION_SUBMITTED: BaseRoute.APPLICATION_SUBMITTED as const,
  PLAYGROUND: BaseRoute.PLAYGROUND as const,
  NOT_FOUND: BaseRoute.NOT_FOUND as const,
  BLANK: "" as const,
};

/* eslint-disable @typescript-eslint/naming-convention */
type ValueOf<T> = T[keyof T];
export type RouteName = ValueOf<typeof RouteName>;
/* eslint-enable @typescript-eslint/naming-convention */

export const STORYBOOK_ROUTES: RouteName[] = [
  RouteName.BLANK,
  RouteName.BUILD_A_BOAT,
  RouteName.SHORT_ANSWER_NAME,
  RouteName.SHORT_ANSWER_GRAD,
  RouteName.SHORT_ANSWER_SCHOOL,
  RouteName.SHORT_ANSWER_PROGRAM,
  RouteName.SHORT_ANSWER_LOCATION,
  RouteName.SHORT_ANSWER_EXPERIENCE,
];

// ordered from first to last in flow
export const PROTECTED_ROUTES: RouteName[] = [
  RouteName.BUILD_A_BOAT,
  RouteName.SHORT_ANSWER_NAME,
  RouteName.SHORT_ANSWER_GRAD,
  RouteName.SHORT_ANSWER_SCHOOL,
  RouteName.SHORT_ANSWER_PROGRAM,
  RouteName.SHORT_ANSWER_LOCATION,
  RouteName.SHORT_ANSWER_EXPERIENCE,
  RouteName.CREATE_ACCOUNT,
  RouteName.LONG_ANSWER,
  RouteName.SURVEY,
  RouteName.REVIEW,
  RouteName.APPLICATION_SUBMITTED,
];

export const getFurthestVisitedRoute = (visitedSteps: RouteName[]) =>
  PROTECTED_ROUTES.reverse().find((orderedRoute) =>
    visitedSteps.includes(orderedRoute)
  );
