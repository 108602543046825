import React, { useState } from "react";
import styled from "styled-components";
import "twin.macro";

import { useSiteContext } from "src/contexts/SiteContext";
import { SupremeBoatImg, TextureUrl } from "src/static";

import TexturedAsset from "../TexturedAsset";

import {
  colors,
  expressions,
  expressions_light,
  accessories,
} from "./BoatOptions";

const ExpressionContainer = styled.div<{ left: number; top: number }>`
  position: absolute;
  z-index: 20;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
`;

const AccessoryContainer = styled.div<{ left: number; top: number }>`
  position: absolute;
  z-index: 20;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
`;

const expressionPlacements = {
  ["smile"]: {
    left: 60,
    top: 106,
  },
  ["joy"]: {
    left: 59,
    top: 108,
  },
  ["eyepatch"]: {
    left: 44,
    top: 90,
  },
  ["glasses"]: {
    left: 29,
    top: 78,
  },
};

const accessoryPlacements = {
  ["flag"]: {
    left: 79,
    top: 14,
  },
  ["plant"]: {
    left: 48,
    top: 0,
  },
  ["pride"]: {
    left: 75,
    top: 6,
  },
  ["boat_hat"]: {
    left: 47,
    top: 23,
  },
  ["beret"]: {
    left: 46,
    top: 27,
  },
  ["egg"]: {
    left: 49,
    top: 30,
  },
  ["pirate_hat"]: {
    left: 39,
    top: 24,
  },
  ["cowboy_hat"]: {
    left: 43,
    top: 14,
  },
};

const SpeechBubble = styled.div<{ show: boolean; color: string }>`
  background: ${({ color }) => color};
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  left: 0;
  font-family: ${({ theme }) => theme.fontFamily.body};
  color: ${({ theme }) => theme.color.primary.blue1};

  transition: transform 300ms cubic-bezier(0.47, -0.44, 0.49, 1.46),
    opacity 300ms;
  transform: scale(${({ show }) => (show ? 1 : 0)}) translate(-85%, -70%);
  transform-origin: bottom right;
  opacity: ${({ show }) => (show ? 1 : 0)};

  // generated with https://projects.verou.me/bubbly/
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 81%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: ${({ color }) => color};
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -8px;
  }
`;

const SpeechBubble2 = styled.div<{ show: boolean }>`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: -40px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  color: ${({ theme }) => theme.color.primary.blue1};

  transition: transform 300ms cubic-bezier(0.47, -0.44, 0.49, 1.46),
    opacity 300ms;
  transform: scale(${({ show }) => (show ? 1 : 0)}) translate(-85%, -70%);
  transform-origin: bottom right;
  opacity: ${({ show }) => (show ? 1 : 0)};

  // generated with https://projects.verou.me/bubbly/
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 81%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: #ffffff;
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -8px;
  }
`;

const CustomBoat: React.FC<{
  color: string;
  expression: string;
  accessory: string;
  speechBubbleMarkup?: React.ReactNode;
  speechBubbleColor?: string;
  AltSpeechBubble?: boolean;
}> = ({
  color,
  expression,
  accessory,
  speechBubbleMarkup,
  speechBubbleColor = "#c8d9eb",
  AltSpeechBubble = false,
}) => {
  const expressionInfo = expressionPlacements[expression];
  const accessoryInfo = accessoryPlacements[accessory];
  const { isMobile } = useSiteContext();

  const [clickCount, setClickCount] = useState(0);
  const [isBooping, doTheBoop] = useState(false);

  return (
    <div
      tw="relative z-0"
      aria-hidden
      style={
        isMobile
          ? {
              transform: "translateX(15%)",
            }
          : {}
      }
      onClick={() => {
        setClickCount((prev) => {
          return prev + 1;
        });
      }}
      onKeyDown={() =>
        setClickCount((prev) => {
          return prev + 1;
        })
      }
    >
      {clickCount > 100 ? (
        <SupremeBoatImg />
      ) : (
        <TexturedAsset texture={TextureUrl} style={{ paddingTop: "34px" }}>
          <div>{colors[color]}</div>
          <ExpressionContainer {...expressionInfo}>
            {(color == "plaid" && expressions_light[expression]) ||
              expressions[expression]}
          </ExpressionContainer>
          <AccessoryContainer {...accessoryInfo}>
            {accessory === "boat_hat"
              ? accessories[accessory].normal[color]
              : accessories[accessory].normal}
          </AccessoryContainer>
        </TexturedAsset>
      )}
      {AltSpeechBubble ? (
        <SpeechBubble2 show={!!speechBubbleMarkup}>
          {speechBubbleMarkup}
        </SpeechBubble2>
      ) : (
        <SpeechBubble show={!!speechBubbleMarkup} color={speechBubbleColor}>
          {speechBubbleMarkup}
        </SpeechBubble>
      )}
    </div>
  );
};

export default CustomBoat;
