import React, { ReactElement } from "react";
import styled from "styled-components";

import { Spacer, Flex, Icon, Button } from "src/components";

import Text from "../Text";

import NorthModal from "./NorthModal";

type TModalProps = React.ComponentPropsWithoutRef<"div"> & {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  actions?: ReactElement;
  isMobile?: boolean;
};

const XIconButton = styled(Button)`
  position: absolute;
  top: 32px;
  right: 48px;
  border: none;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.color.text.blue};
`;

const ModalContainer = styled(NorthModal)`
  background: white;
  padding: 48px;
  border-radius: 20px;
  position: relative;
  min-width: 400px;
  max-width: 632px;
  &:focus {
    outline: none;
  }
`;

const NO_OP = () => {};

const Modal: React.FC<TModalProps> = ({
  onClose,
  title,
  actions,
  children,
  isMobile,
  ...rest
}) => (
  <ModalContainer
    onClose={onClose || NO_OP}
    shouldCloseOnEsc={!!onClose}
    shouldCloseOnOverlayClick={!!onClose}
    {...rest}
  >
    {onClose && (
      <XIconButton onClick={onClose}>
        <Icon name="x" width="24px" height="24px" strokeWidth="1.2px" />
      </XIconButton>
    )}
    {title && (
      <>
        <Text heading size={isMobile ? "h3M" : "h3D"}>{title}</Text>
        <Spacer height={16} />
      </>
    )}
    {children}
    {actions && (
      <>
        <Spacer height={32} />
        <Flex justify="flex-end">{actions}</Flex>
      </>
    )}
  </ModalContainer>
);

export default Modal;
