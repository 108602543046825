import React from "react";
import { css, keyframes } from "styled-components";
import tw, { styled } from "twin.macro";

import { CustomBoat } from "src/components";
import { SCHOOL_TO_MASCOT_NO_REFLECTION } from "src/constants/copy";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import {
  COVER_SIZE,
  StorybookContextProvider,
} from "src/contexts/StorybookContext";
import {
  ThanksLeft,
  ThanksRight,
  SubmittedPage,
} from "src/stages/ApplicationSubmittedStage/pages";
import AssetPage from "src/stages/ShortAnswerStage/pages/AssetPage";
import { DownloadableBackground, DownloadableWaves } from "src/static";
import { mediaQueries } from "src/utils/responsive";

import PlaceholderPage from "./pages/PlaceholderPage";

const DownloadContainer = styled.div`
  background-image: url(${DownloadableBackground});
  position: absolute;
  height: 260px;
  width: 260px;
  left: 0px;
  top: 0px;
  z-index: -10;
`;

const DownloadBoatContainer = styled.div`
  position: absolute;
  left: 48px;
  top: 56px;
`;

const DownloadWaves = styled.img`
  position: absolute;
  left: 0px;
  bottom: 0px;
`;

const StorybookWrapper = styled.div`
  margin-top: -115px;
`;

const MascotWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  ${({ isMobile }) =>
    isMobile &&
    `
    transform: translateX(25%) translateY(-5%) scale(0.9);
    max-width: 400px;
    margin-left: auto;
  `}
`;

const DestinationEaseIn = keyframes`
  0% {
    right: -950px;
  }
  100% {
    right: -50px;
  }
`;

const DestinationAnimation = css`
  animation-name: ${DestinationEaseIn};
  animation-duration: 5s;
  animation-timing-function: ease-out;
  animation-direction: normal;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const DestinationWrapper = styled.div<{ boatPosition: number }>`
  transform: scaleX(-1);
  position: absolute;
  right: -950px;
  bottom: 0px;
  height: auto;
  width: 1000px;
  overflow: hidden;
  ${({ boatPosition }) => (boatPosition === 9 ? DestinationAnimation : "")};

  ${mediaQueries.large} {
    width: 850px;
  }
  ${mediaQueries.medium} {
    width: 700px;
  }
`;

const ApplicationSubmittedStage: React.FC = () => {
  const { isMobile, boatPosition } = useSiteContext();
  const {
    responses: { short_school },
  } = useResponse();
  const { value } = short_school;
  const Mascot =
    SCHOOL_TO_MASCOT_NO_REFLECTION[value] ??
    SCHOOL_TO_MASCOT_NO_REFLECTION["Other"];
  const hasContent = value !== null && value !== "" && value !== undefined;

  const {
    responses: {
      boat_custom_color,
      boat_custom_expression,
      boat_custom_accessory,
    },
  } = useResponse();

  const coverPageStyle = {
    width: COVER_SIZE.width,
    height: COVER_SIZE.height,
  };

  return isMobile ? (
    <>
      <AssetPage>
        {hasContent ? (
          <MascotWrapper isMobile={isMobile}>
            <Mascot />
          </MascotWrapper>
        ) : null}
      </AssetPage>
      <SubmittedPage />
      {/* Downloadable boat asset TODO: Fix for mobile (the boat avatar is a bit small) */}
      <DownloadContainer id="boat-container">
        <DownloadBoatContainer>
          <CustomBoat
            color={boat_custom_color?.value || "pink"}
            expression={boat_custom_expression?.value || "smile"}
            accessory={boat_custom_accessory?.value || "flag"}
          />
        </DownloadBoatContainer>
        <DownloadWaves src={DownloadableWaves} />
      </DownloadContainer>
    </>
  ) : (
    <StorybookWrapper>
      <StorybookContextProvider appSubmittedBook>
        <PlaceholderPage style={coverPageStyle} />
        <ThanksLeft />
        <ThanksRight className="hard" />
        <SubmittedPage className="hard own-size" style={coverPageStyle} />
        <div />
      </StorybookContextProvider>

      <DestinationWrapper boatPosition={boatPosition}>
        <Mascot />
      </DestinationWrapper>
      {/* Downloadable boat asset */}
      <DownloadContainer id="boat-container">
        <DownloadBoatContainer>
          <CustomBoat
            color={boat_custom_color?.value || "pink"}
            expression={boat_custom_expression?.value || "smile"}
            accessory={boat_custom_accessory?.value || "flag"}
          />
        </DownloadBoatContainer>
        <DownloadWaves src={DownloadableWaves} />
      </DownloadContainer>
    </StorybookWrapper>
  );
};

const MobileWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  h1 {
    margin: 0;
    color: ${({ theme }) => theme.color.primary.blue2};
  }

  h2 {
    margin: 0;
    color: ${({ theme }) => theme.color.primary.blue1};
  }

  p {
    font-family: ${({ theme }) => theme.fontFamily.body};
    color: ${({ theme }) => theme.color.primary.blue1};
    line-height: 1.8;
    margin: 24px 0;
  }
  a {
    color: ${({ theme }) => theme.color.primary.blue2};
    font-weight: bold;
  }
`;

export default ApplicationSubmittedStage;
