import React, { createContext, useContext, useState } from "react";

export type TSurveyScrollState = {
  surveyScrolled: number;
  onScroll: (e: any) => void;
};

const DEFAULT_STATE: TSurveyScrollState = {
  surveyScrolled: 0,
  onScroll: () => {},
};

export const SurveyScrollContext: React.Context<TSurveyScrollState> =
  createContext(DEFAULT_STATE);

export const SurveyScrollContextProvider: React.FC = ({
  children,
  ...rest
}) => {
  const [surveyScrolled, setSurveyScrolled] = useState(0);

  const onScroll = (e: any) => {
    /**
     * Calculates how far the user has scrolled on the Survey page
     * @returns percentage as a number from 0 to 100
     */
    const currPercentScrolled =
      (e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight)) *
      100;
    setSurveyScrolled(currPercentScrolled);
  };

  const stateValue = {
    surveyScrolled,
    onScroll,
  };

  return (
    <SurveyScrollContext.Provider value={stateValue} {...rest}>
      {children}
    </SurveyScrollContext.Provider>
  );
};

export const useSurveyScrollContext = () => useContext(SurveyScrollContext);
