import { useAuth } from "@hackthenorth/north";
import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { Prompt } from "react-router-dom";
import { OptionsType, OptionTypeBase } from "react-select";
import tw, { styled } from "twin.macro";

import { ExclamationIcon } from "src/components";
import {
  Spacer,
  Button,
  HorizontalRule,
  LongAnswerQuestion,
  LongAnswerPage,
  TextAreaInput,
  Flex,
  TagInput,
  TextInput,
  Text,
  successToast,
} from "src/components";
import Modal from "src/components/base/Modal";
import {
  ETHNICITY_OPTIONS,
  ETHNICITY_TOOLTIP,
  EXPERIENCE_OPTIONS,
  GENDER_OPTIONS,
  GRADUATING_YEAR_OPTIONS,
  HACKATHON_COUNT_OPTIONS,
  HACKER_TYPES,
  LEVEL_OF_STUDY_OPTIONS,
  LINK_TYPES,
  LONG_ANSWER_TITLES,
  OPTIONS_MAX_COPY,
  PROGRAM_OPTIONS,
  RACE_OPTIONS,
  RACE_TOOLTIP,
  SCHOOL_OPTIONS,
  TECHNOLOGY_MAX,
  TECHNOLOGY_OPTIONS,
  UNDERREP_GROUPS_OPTIONS,
  WORKSHOP_MAX,
  WORKSHOP_OPTIONS,
  YES_NO_OPTIONS,
} from "src/constants/copy";
import { RouteName } from "src/constants/route";
import { useSiteContext, useUserContext } from "src/contexts";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import {
  AccessoryButton,
  getRandomQuote,
  OptionButton,
  TabButton,
  TabButtonText,
} from "src/stages/ShortAnswerStage/pages/BuildPage";
import { ExpressionIconImg, PaperIconImg } from "src/static";
import {
  BucketIcon,
  ClipboardIcon,
  FlagIcon,
  HeadIcon,
} from "src/static/icons";
import {
  answersToOptions,
  answerToOption,
  optionsToAnswers,
} from "src/utils/react-select";
import { mediaQueries } from "src/utils/responsive";

import CheckBox from "../Checkbox";
import CustomBoat from "../CustomBoat";
import { accessories, colors, expressions } from "../CustomBoat/BoatOptions";
import DropdownTagInput from "../DropdownTagInput";
import GeoCoder from "../GeoCoder";
import SurveyQuestion from "../SurveyQuestion";

import { Authenticate } from "./Authenticate";

/**
 * im warning u this code sucks
 */

const getInputInfo = (index: number) => {
  if (index == 0) {
    return [LINK_TYPES[index][0], LINK_TYPES[index][1]];
  } else if (index == 1) {
    return [LINK_TYPES[index][0], LINK_TYPES[index][1]];
  } else if (index == 2) {
    return [LINK_TYPES[index][0], LINK_TYPES[index][1]];
  } else {
    return ["Other", "Enter a link here"];
  }
};

const cleanLinks = (links: string[], setLinks: (l: string[]) => void) => {
  setLinks([
    ...links.slice(0, 3),
    ...links.slice(3).filter((link) => link !== ""),
  ]);
};

const AlternativeForm: React.FC = () => {
  const { responses, saveResponses, submitResponses } = useResponse();
  const {
    boat_custom_accessory,
    boat_custom_expression,
    boat_custom_color,
    short_name,
    short_school,
    short_grad_year,
    short_grad_level,
    short_program,
    short_location,
    short_hackathon_count,
    long_collaboration: {
      value: collaboration,
      setValue: setCollaboration,
      errors: collaborationErrors,
      config: collaborationConfig,
      saved: isCollaborationSaved,
    },
    long_desire: {
      value: desire,
      setValue: setDesire,
      errors: desireErrors,
      config: desireConfig,
      saved: isDesireSaved,
    },
    long_hacker_type: {
      value: hackerTypes,
      setValue: setHackerTypes,
      errors: hackerTypesErrors,
      config: hackerTypesConfig,
      saved: isHackerTypesSaved,
    },
    long_links: {
      value: links,
      setValue: setLinks,
      errors: linkErrors,
      config: linkConfig,
      saved: isLinksSaved,
    },
    long_obstacle: {
      value: obstacle,
      setValue: setObstacle,
      errors: obstacleErrors,
      config: obstacleConfig,
      saved: isObstacleSaved,
    },
    survey_gender,
    survey_race,
    survey_ethnicity,
    survey_education,
    survey_personal_experience,
    survey_family_experience,
    survey_groups,
    survey_underrep_groups,
    survey_tech_preferences,
    survey_workshop_preferences,
  } = responses;

  const { value: customColor, setValue: setCustomColorValue } =
    boat_custom_color;
  const { value: customExpression, setValue: setCustomExpressionValue } =
    boat_custom_expression;
  const { value: customAccessory, setValue: setCustomAccessoryValue } =
    boat_custom_accessory;

  const setCustomColor: typeof setCustomColorValue = (v) => {
    setCustomColorValue(v);
    setShowBoat(true);
  };
  const setCustomExpression: typeof setCustomExpressionValue = (v) => {
    setCustomExpressionValue(v);
    setShowBoat(true);
  };
  const setCustomAccessory: typeof setCustomAccessoryValue = (v) => {
    setCustomAccessoryValue(v);
    setShowBoat(true);
  };
  const [showBoat, setShowBoat] = useState(true);

  const isHighSchoolStudent = short_grad_level.value == "high school";
  const isOtherStudent = short_grad_level.value == "other";

  const formErrors = [
    ...desireErrors,
    ...hackerTypesErrors,
    ...obstacleErrors,
    ...collaborationErrors,
    ...linkErrors,
  ];

  const [didEdit, setDidEdit] = useState({
    collaboration: false,
    desire: false,
    obstacle: false,
    hackerTypes: false,
    links: false,
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [inputSelected, setInputSelected] = useState("");

  useEffect(() => {
    cleanLinks(links, setLinks);
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [didFocus, setDidFocus] = useState({
    gender: false,
    underrep: false,
  });

  const getClipboardText = () => {
    const desireText = `1.${LONG_ANSWER_TITLES[0]}\n`;
    const collaborationText = `2.${LONG_ANSWER_TITLES[1]}\n`;
    const obstacleText = `3.${LONG_ANSWER_TITLES[2]}\n`;
    return `${desireText}\n${collaborationText}\n${obstacleText}`;
  };

  const shortRef = useRef(null);
  const desireRef = useRef(null);
  const collaborationRef = useRef(null);
  const obstacleRef = useRef(null);
  const hackerTypeRef = useRef(null);
  const linkRef = useRef(null);

  const executeScroll = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  };

  const { isMobile, navigate } = useSiteContext();
  const [addingTechnology, setAddingTechnology] = useState(false);
  const [addingWorkshop, setAddingWorkshop] = useState(false);
  const [userTechnology, setUserTechnology] = useState<string[]>(
    survey_tech_preferences.value.filter(
      (option: string) => !TECHNOLOGY_OPTIONS.includes(option)
    )
  );
  const [userWorkshops, setUserWorkshops] = useState<string[]>(
    survey_workshop_preferences.value.filter(
      (option: string) => !WORKSHOP_OPTIONS.includes(option)
    )
  );

  const validShortAnswers = () => {
    const validOtherStudentRequired =
      short_name.value &&
      short_grad_level.value &&
      short_location.value &&
      short_hackathon_count.value;
    if (isOtherStudent) {
      return validOtherStudentRequired;
    }
    if (isHighSchoolStudent) {
      return (
        validOtherStudentRequired && short_grad_year.value && short_school.value
      );
    }
    return (
      validOtherStudentRequired &&
      short_grad_year.value &&
      short_school.value &&
      short_program.value
    );
  };

  useEffect(() => {
    const listener = () => setShowBoat(false);
    window.addEventListener("scroll", listener, { passive: true });

    return () => window.removeEventListener("scroll", listener);
  }, []);

  return (
    <LongAnswerPage>
      <Prompt
        when={!isModalOpen}
        message="You have unsaved answers. Are you sure you want to leave?"
      />
      <CustomBoatContainer
        show={showBoat}
        onClick={isMobile ? () => setShowBoat(false) : undefined}
      >
        <CustomBoat
          color={customColor}
          expression={customExpression}
          accessory={customAccessory}
          speechBubbleMarkup={"What a boat-y!"}
        />
      </CustomBoatContainer>
      <Flex column>
        <HeaderSection>
          <Text heading size="h1D">
            Hack the North 2021 Application
          </Text>
          <Button
            link
            onClick={() => {
              navigator.clipboard.writeText(getClipboardText());
              successToast("Questions copied!");
            }}
          >
            <Flex row align="center">
              <ClipboardIcon />
              <Text link>Copy questions</Text>
            </Flex>
          </Button>
        </HeaderSection>
        <Text>
          Complete the application questions below to apply for Hack the North
          2021!
        </Text>
      </Flex>
      <HorizontalRule />

      <Spacer height={48} />

      <Text heading size="h2D">
        Build your boat
      </Text>

      <div tw="flex flex-col gap-y-8 justify-center">
        {/* Headings don't show up on mobile */}
        <Text heading size={isMobile ? "h3M" : "h3D"} element="h2">
          Pick your paper
        </Text>
        <div
          tw="flex items-center flex-wrap"
          css={[isMobile && tw`justify-between`]}
        >
          {Object.keys(colors).map((color) => (
            <OptionButton
              key={color}
              aria-label={color}
              title={color}
              onClick={() => setCustomColor(color)}
            >
              <PaperIconImg
                paperColor={color}
                selected={customColor === color}
              />
            </OptionButton>
          ))}
        </div>

        <Text heading size={isMobile ? "h3M" : "h3D"} element="h2">
          Draw an expression
        </Text>
        <div
          tw="flex items-center gap-8 flex-wrap"
          css={[isMobile && tw`justify-center`]}
        >
          {Object.keys(expressions).map((expression) => (
            <OptionButton
              key={expression}
              aria-label={expression}
              title={expression}
              onClick={() => setCustomExpression(expression)}
              css={[isMobile && tw`w-120`]}
            >
              <ExpressionIconImg
                expression={expression}
                selected={customExpression === expression}
              />
            </OptionButton>
          ))}
        </div>

        <Text heading size={isMobile ? "h3M" : "h3D"} element="h2">
          Put on an accessory
        </Text>
        <div
          tw="flex items-center gap-24 flex-wrap"
          css={[isMobile && tw`justify-center`]}
        >
          {Object.keys(accessories).map((accessoryName) => {
            const { normal, selected } = accessories[accessoryName];
            const isSelected = customAccessory === accessoryName;
            let markup;
            if (accessoryName === "boat_hat") {
              markup = isSelected ? selected[customColor] : normal[customColor];
            } else {
              markup = isSelected ? selected : normal;
            }
            return (
              <AccessoryButton
                key={accessoryName}
                aria-label={accessoryName}
                title={accessoryName}
                onClick={() => setCustomAccessory(accessoryName)}
                css={[isMobile && tw`w-112`]}
              >
                {markup}
              </AccessoryButton>
            );
          })}
        </div>
      </div>

      <Spacer height={48} />

      <span ref={shortRef} />
      <LongAnswerQuestion
        step={0}
        isAlternativeForm
        isRequired={short_name.config.required}
        errors={short_name.errors}
        isSaved={short_name.saved}
      >
        <TextInput
          placeholder="My full name"
          value={short_name.value ?? ""}
          error={short_name.errors.length > 0}
          onChange={(e) => {
            short_name.setValue(e.target.value);
          }}
        />
      </LongAnswerQuestion>

      <span />
      <LongAnswerQuestion
        step={1}
        isAlternativeForm
        isRequired={short_grad_level.config.required}
        errors={short_grad_level.errors}
        isSaved={short_grad_level.saved}
      >
        <DropdownTagInput
          placeholder="My level of study"
          value={
            short_grad_level.value
              ? answerToOption(short_grad_level.value)
              : undefined
          }
          onChange={(option: OptionTypeBase) =>
            short_grad_level.setValue(option.value)
          }
          options={answersToOptions(LEVEL_OF_STUDY_OPTIONS)}
        />
      </LongAnswerQuestion>

      <span />
      {!isOtherStudent && (
        <LongAnswerQuestion
          step={2}
          isAlternativeForm
          isRequired={short_grad_year.config.required}
          errors={short_grad_year.errors}
          isSaved={short_grad_year.saved}
        >
          <DropdownTagInput
            placeholder="My graduating year"
            value={
              short_grad_year.value
                ? answerToOption(short_grad_year.value)
                : undefined
            }
            onChange={(option: OptionTypeBase) =>
              short_grad_year.setValue(option.value)
            }
            options={answersToOptions(GRADUATING_YEAR_OPTIONS)}
          />
        </LongAnswerQuestion>
      )}

      <span />
      {!isOtherStudent && (
        <LongAnswerQuestion
          step={3}
          isAlternativeForm
          isRequired={short_school.config.required}
          errors={short_school.errors}
          isSaved={short_school.saved}
        >
          {isHighSchoolStudent ? (
            <>
              <TextInput
                placeholder="My school name"
                value={short_school.value}
                onChange={(e) => short_school.setValue(e.target.value)}
              />
              <div
                style={{
                  height:
                    "1px" /* im too to fix margin collapsing dont kill me */,
                }}
              />
            </>
          ) : (
            <>
              <DropdownTagInput
                creatable
                isSearchable
                placeholder="My school name"
                value={
                  short_school.value
                    ? answerToOption(short_school.value)
                    : undefined
                }
                onChange={(option: OptionTypeBase) =>
                  short_school.setValue(option.value)
                }
                options={answersToOptions(SCHOOL_OPTIONS)}
                tabIndex={0}
              />
              <div style={{ height: "1px" }} />
            </>
          )}
        </LongAnswerQuestion>
      )}
      <span />
      {!isHighSchoolStudent && !isOtherStudent && (
        <LongAnswerQuestion
          step={4}
          isAlternativeForm
          isRequired={short_program.config.required}
          errors={short_program.errors}
          isSaved={short_program.saved}
        >
          <DropdownTagInput
            creatable
            isSearchable
            placeholder="My program name"
            options={answersToOptions(PROGRAM_OPTIONS)}
            value={
              short_program.value
                ? answerToOption(short_program.value)
                : undefined
            }
            onChange={(option: OptionTypeBase) =>
              short_program.setValue(option.value)
            }
            tabIndex={0}
          />
        </LongAnswerQuestion>
      )}

      <span />
      <LongAnswerQuestion
        step={5}
        isAlternativeForm
        isRequired={short_location.config.required}
        errors={short_location.errors}
        isSaved={short_location.saved}
      >
        <GeoCoder
          placeholder="My city"
          value={
            short_location.value
              ? answerToOption(short_location.value)
              : undefined
          }
          onChange={(e: any) => short_location.setValue(e.value)}
          tabIndex={0}
        />
      </LongAnswerQuestion>

      <span />
      <LongAnswerQuestion
        step={6}
        isAlternativeForm
        isRequired={short_hackathon_count.config.required}
        errors={short_hackathon_count.errors}
        isSaved={short_hackathon_count.saved}
      >
        <DropdownTagInput
          placeholder="# of hackathons"
          value={
            short_hackathon_count.value
              ? answerToOption(short_hackathon_count.value)
              : undefined
          }
          onChange={(option: OptionTypeBase) =>
            short_hackathon_count.setValue(option.value)
          }
          options={answersToOptions(HACKATHON_COUNT_OPTIONS)}
          tabIndex={0}
        />
      </LongAnswerQuestion>

      <span ref={desireRef} />
      <LongAnswerQuestion
        step={7}
        isAlternativeForm
        isRequired={desireConfig.required}
        errors={desireErrors}
        isSaved={isDesireSaved}
        didEdit={didEdit.desire}
      >
        <TextAreaInput
          placeholder="Type your answer here..."
          charLimit={500}
          value={desire ?? ""}
          errors={desireErrors}
          isSelected={inputSelected == "desire"}
          didEdit={didEdit.desire}
          onChange={(e) => {
            if (!didEdit.desire) {
              setDidEdit((prev) => ({
                ...prev,
                desire: true,
              }));
            }
            setDesire(e.target.value);
          }}
          onBlur={() => setInputSelected("")}
          onFocus={() => setInputSelected("desire")}
        />
      </LongAnswerQuestion>
      <span ref={collaborationRef} />
      <LongAnswerQuestion
        step={8}
        isAlternativeForm
        isRequired={collaborationConfig.required}
        errors={collaborationErrors}
        isSaved={isCollaborationSaved}
        didEdit={didEdit.collaboration}
      >
        <TextAreaInput
          placeholder="Type your answer here..."
          charLimit={1200}
          value={collaboration ?? ""}
          didEdit={didEdit.collaboration}
          errors={collaborationErrors}
          isSelected={inputSelected === "collaboration"}
          onChange={(e) => {
            if (!didEdit.collaboration) {
              setDidEdit((prev) => ({
                ...prev,
                collaboration: true,
              }));
            }
            setCollaboration(e.target.value);
          }}
          onBlur={() => setInputSelected("")}
          onFocus={() => setInputSelected("collaboration")}
        />
      </LongAnswerQuestion>
      <span ref={obstacleRef} />
      <LongAnswerQuestion
        step={9}
        isAlternativeForm
        isRequired={obstacleConfig.required}
        errors={obstacleErrors}
        isSaved={isObstacleSaved}
        didEdit={didEdit.obstacle}
      >
        <TextAreaInput
          placeholder="Type your answer here..."
          charLimit={1200}
          value={obstacle ?? ""}
          errors={obstacleErrors}
          didEdit={didEdit.obstacle}
          onChange={(e) => {
            if (!didEdit.obstacle) {
              setDidEdit((prev) => ({
                ...prev,
                obstacle: true,
              }));
            }
            setObstacle(e.target.value);
          }}
          isSelected={inputSelected == "obstacle"}
          onBlur={() => setInputSelected("")}
          onFocus={() => setInputSelected("obstacle")}
        />
      </LongAnswerQuestion>
      <span ref={hackerTypeRef} />
      <LongAnswerQuestion
        isRequired={hackerTypesConfig.required}
        step={10}
        isAlternativeForm
        errors={hackerTypesErrors}
        isSaved={isHackerTypesSaved}
        didEdit={didEdit.hackerTypes}
        requiresAtLeastOne
      >
        <TagInput
          multi
          options={HACKER_TYPES}
          selectedOptions={hackerTypes}
          setSelectedOptions={(newSelection) => {
            setHackerTypes(newSelection);
          }}
        />
      </LongAnswerQuestion>
      <span ref={linkRef} />
      <LongAnswerQuestion
        step={11}
        isAlternativeForm
        isRequired={linkConfig.required}
        showSurveyButton={true}
        errors={linkErrors}
        didEdit={didEdit.links}
        isSaved={!linkErrors.length && isLinksSaved}
      >
        <LinkContainer>
          {links.map((link, i) => {
            const [name, placeholder] = getInputInfo(i);
            const error = !!linkErrors[i];
            return (
              <Fragment key={i}>
                <Flex column align="flex-start">
                  {error && link ? (
                    <Flex align="center">
                      <PlatformLinkText>{`${name}:`}</PlatformLinkText>
                      <Flex>
                        <ExclamationIcon />
                        <StatusText error={error}>{linkErrors[i]}</StatusText>
                      </Flex>
                    </Flex>
                  ) : (
                    <PlatformLinkText>{`${name}:`}</PlatformLinkText>
                  )}
                  <TextInput
                    placeholder={placeholder}
                    value={link}
                    error={error}
                    onBlur={() => setInputSelected("")}
                    onChange={(e) => {
                      if (!didEdit.links[i]) {
                        setDidEdit((prev) => ({
                          ...prev,
                          links: true,
                        }));
                      }
                      setLinks((prev) => [
                        ...prev.slice(0, i),
                        e.target.value,
                        ...prev.slice(i + 1),
                      ]);
                    }}
                  />
                </Flex>
              </Fragment>
            );
          })}
        </LinkContainer>
        <Button link onClick={() => setLinks((prev) => [...prev, ""])}>
          + Add another link
        </Button>
      </LongAnswerQuestion>
      {/* ESURVEY */}

      <Flex column>
        <HeaderSection>
          <Text heading size="h2D">
            Survey questions (optional)
          </Text>
        </HeaderSection>
        <Text>
          We want to understand who our hackers are so that we do our best to
          help them succeed at Hack the North. This includes eliminating biases
          from our application process, setting goals and measuring our progress
          towards them, as well as making educated decisions so that Hack the
          North is a great experience for everyone.
        </Text>
        <Text>
          The following <span tw="font-bold">optional</span> survey questions
          are used only for aggregate reporting and{" "}
          <span tw="font-bold">will not have any effect</span> on your
          application. For more information on how we use your data, check out
          our privacy policy at{" "}
          <a
            href="https://hackthenorth.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            hackthenorth.com/privacy
          </a>
          .
        </Text>
      </Flex>
      <HorizontalRule />

      <SurveyQuestion
        step={0}
        showSaved={false}
        subtitle="Select all that apply."
      >
        <CheckboxContainer>
          {GENDER_OPTIONS.map((type, i) => {
            const isChecked = survey_gender.value.includes(type);
            const onChange = () => {
              if (!didFocus.gender) {
                setDidFocus({ ...didFocus, gender: true });
              }
              survey_gender.setValue((prev) => {
                if (prev.includes(type)) {
                  return prev.filter((value) => value !== GENDER_OPTIONS[i]);
                } else {
                  return [...prev, GENDER_OPTIONS[i]];
                }
              });
            };

            return (
              <CheckboxRow key={i} row align="center">
                {isChecked ? (
                  <CheckBox
                    onChange={onChange}
                    checked={isChecked}
                    checkColor="#FFFFFF"
                  />
                ) : (
                  <CheckBox onChange={onChange} checked={isChecked} />
                )}
                <CheckboxText>{GENDER_OPTIONS[i]}</CheckboxText>
              </CheckboxRow>
            );
          })}
        </CheckboxContainer>
      </SurveyQuestion>

      <SurveyQuestion
        step={1}
        showSaved={false}
        subtitle="Select all that apply and/or add your own option."
        tooltip={RACE_TOOLTIP}
      >
        <DropdownTagInput
          multi
          isSearchable
          creatable
          placeholder="Please type or select from the dropdown"
          options={RACE_OPTIONS}
          value={answersToOptions(survey_race.value)}
          onChange={(options: OptionsType<OptionTypeBase>) =>
            survey_race.setValue(optionsToAnswers(options))
          }
        />
      </SurveyQuestion>

      <SurveyQuestion
        step={2}
        showSaved={false}
        subtitle="Select all that apply and/or add your own option."
        tooltip={ETHNICITY_TOOLTIP}
      >
        <DropdownTagInput
          multi
          isSearchable
          creatable
          placeholder="Please type or select from the dropdown"
          options={ETHNICITY_OPTIONS}
          value={answersToOptions(survey_ethnicity.value)}
          onChange={(options: OptionsType<OptionTypeBase>) =>
            survey_ethnicity.setValue(optionsToAnswers(options))
          }
        />
      </SurveyQuestion>

      <SurveyQuestion step={3} showSaved={false}>
        <TagInput
          options={YES_NO_OPTIONS}
          selectedOptions={
            survey_education.value !== null
              ? survey_education.value
                ? ["Yes"]
                : ["No"]
              : []
          }
          setSelectedOptions={(newSelection) => {
            newSelection[0] == "Yes"
              ? survey_education.setValue(true)
              : newSelection[0] == "No"
              ? survey_education.setValue(false)
              : survey_education.setValue(null);
          }}
        />
      </SurveyQuestion>

      <SurveyQuestion step={4} showSaved={false}>
        <TagInput
          options={EXPERIENCE_OPTIONS}
          selectedOptions={[survey_personal_experience.value]}
          setSelectedOptions={(newSelection) => {
            survey_personal_experience.setValue(newSelection[0] || "");
          }}
        />
      </SurveyQuestion>

      <SurveyQuestion step={5} showSaved={false}>
        <TagInput
          options={YES_NO_OPTIONS}
          selectedOptions={
            survey_family_experience.value !== null
              ? survey_family_experience.value
                ? ["Yes"]
                : ["No"]
              : []
          }
          setSelectedOptions={(newSelection) => {
            newSelection[0] == "Yes"
              ? survey_family_experience.setValue(true)
              : newSelection[0] == "No"
              ? survey_family_experience.setValue(false)
              : survey_family_experience.setValue(null);
          }}
        />
      </SurveyQuestion>

      <SurveyQuestion
        step={6}
        showSaved={false}
        subtitle="Select all that apply."
      >
        <CheckboxContainer>
          {UNDERREP_GROUPS_OPTIONS.map((type, i) => {
            const isChecked = survey_groups.value.includes(type);
            const onChange = () => {
              if (!didFocus.underrep) {
                setDidFocus({ ...didFocus, underrep: true });
              }
              survey_groups.setValue((prev) => {
                if (prev.includes(type)) {
                  return prev.filter(
                    (value) => value !== UNDERREP_GROUPS_OPTIONS[i]
                  );
                } else {
                  return [...prev, UNDERREP_GROUPS_OPTIONS[i]];
                }
              });
            };

            return (
              <CheckboxRow key={i} row align="center">
                {isChecked ? (
                  <CheckBox
                    onChange={onChange}
                    checked={isChecked}
                    checkColor="#FFFFFF"
                  />
                ) : (
                  <CheckBox onChange={onChange} checked={isChecked} />
                )}
                <CheckboxText>{UNDERREP_GROUPS_OPTIONS[i]}</CheckboxText>
              </CheckboxRow>
            );
          })}
        </CheckboxContainer>
      </SurveyQuestion>

      <SurveyQuestion step={7} showSaved={false}>
        <TextInput
          value={survey_underrep_groups.value}
          placeholder="Please type here"
          onChange={(e) => survey_underrep_groups.setValue(e.target.value)}
        />
      </SurveyQuestion>

      <SurveyQuestion
        step={8}
        showSaved={false}
        subtitle="Select and/or add up to three options."
      >
        <TagInput
          multi
          max={TECHNOLOGY_MAX}
          adding={addingTechnology}
          setAdding={setAddingTechnology}
          options={TECHNOLOGY_OPTIONS}
          userOptions={userTechnology}
          setUserOptions={setUserTechnology}
          selectedOptions={survey_tech_preferences.value}
          setSelectedOptions={survey_tech_preferences.setValue}
        />
        {!addingTechnology &&
        survey_tech_preferences.value.length < TECHNOLOGY_MAX ? (
          <Button link onClick={() => setAddingTechnology(true)} tw="inline">
            + Add a new option
          </Button>
        ) : (
          <Text tw="font-bold">{OPTIONS_MAX_COPY}</Text>
        )}
      </SurveyQuestion>

      <SurveyQuestion
        step={9}
        showSaved={false}
        subtitle="Select and/or add up to three options."
      >
        <TagInput
          multi
          max={3}
          adding={addingWorkshop}
          setAdding={setAddingWorkshop}
          options={WORKSHOP_OPTIONS}
          userOptions={userWorkshops}
          setUserOptions={setUserWorkshops}
          selectedOptions={survey_workshop_preferences.value}
          setSelectedOptions={survey_workshop_preferences.setValue}
        />
        {!addingWorkshop &&
        survey_workshop_preferences.value.length < WORKSHOP_MAX ? (
          <Button link onClick={() => setAddingWorkshop(true)} tw="inline">
            + Add a new option
          </Button>
        ) : (
          <Text tw="font-bold">{OPTIONS_MAX_COPY}</Text>
        )}
      </SurveyQuestion>

      <Spacer height={78} />

      <Flex row justify="flex-end">
        <Button
          onClick={() => {
            if (!formErrors.join("") && validShortAnswers()) {
              console.log("here");
              cleanLinks(links, setLinks);
              saveResponses().then(() => setModalOpen(true));
            } else {
              setDidEdit({
                collaboration: true,
                desire: true,
                obstacle: true,
                hackerTypes: true,
                links: true,
              });
              // scroll to first section with error
              if (!validShortAnswers()) {
                executeScroll(shortRef);
              }
              if (desireErrors.join("")) {
                executeScroll(desireRef);
              } else if (collaborationErrors.join("")) {
                executeScroll(collaborationRef);
              } else if (obstacleErrors.join("")) {
                executeScroll(obstacleRef);
              } else if (hackerTypesErrors.join("")) {
                executeScroll(hackerTypeRef);
              } else if (linkErrors.join("")) {
                executeScroll(linkRef);
              }
            }
          }}
        >
          Submit
        </Button>
      </Flex>

      <Spacer height={78} />

      <Modal
        isOpen={isModalOpen}
        title="Are you sure you want to submit?"
        onClose={() => setModalOpen(false)}
        actions={
          <Button
            onClick={() =>
              submitResponses().then((submittedSuccessfully) =>
                submittedSuccessfully
                  ? navigate(RouteName.APPLICATION_SUBMITTED)
                  : null
              )
            }
          >
            Submit
          </Button>
        }
      >
        <Text>
          Click the submit button if you’re ready to send in your Hack the North
          2021 application!
        </Text>
      </Modal>
    </LongAnswerPage>
  );
};

export default function AlternativeFormPage() {
  const { isAuthenticated } = useUserContext();
  return isAuthenticated ? <AlternativeForm /> : <Authenticate />;
}

const HeaderSection = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  margin-top: 15px;

  ${mediaQueries.tablet} {
    flex-direction: column;
    align-items: flex-start;

    & button {
      margin-top: 8px;
      margin-left: 0;
    }
  }
`;

const HackerTypeText = styled(Text)`
  color: ${({ theme }) => theme.color.primary.blue1};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: 500;
  font-size: 18;
  margin-left: 15px;
`;

const PlatformLinkText = styled(HackerTypeText)`
  color: ${({ theme }) => theme.color.text.blue};
  line-height: 33px;
  margin-left: 0px;
  margin-right: 15px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 20px;
`;

const StatusText = styled(Text)<{ error: boolean }>`
  color: ${({ theme, error }) =>
    error ? theme.color.secondary.red : theme.color.secondary.green};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CheckboxRow = styled(Flex)`
  margin-top: 20px;
`;

const CheckboxText = styled(Text)`
  color: ${({ theme }) => theme.color.primary.blue1};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize.body};
  margin-left: 15px;
`;

const CustomBoatContainer = styled.div<{ show: boolean }>`
  position: fixed;
  top: 350px;
  z-index: 4;
  transition: transform 400ms ease-in;
  transform: translateX(${({ show }) => (show ? 0 : "80vw")});
  left: calc(50vw + 120px);
  ${mediaQueries.tablet} {
    left: calc(50vw + 20px);
  }
`;
