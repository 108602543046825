import React from "react";

const ExpressionIcon: React.FC<{ expression: string; selected: boolean }> = ({
  expression,
  selected,
}) => {
  const strokeColor = "#7EC2E4";
  const strokeWidth = selected ? 5 : 0;

  let face = (
    <>
      <path
        d="M35.4686 22.5602C36.2056 22.3627 36.8931 22.4573 37.5311 22.8439C38.1907 23.2248 38.6164 23.7729 38.8081 24.4882C39.0055 25.2252 38.9109 25.9127 38.5243 26.5507C38.1651 27.2045 37.617 27.6301 36.88 27.8276C36.143 28.0251 35.4447 27.9334 34.7851 27.5526C34.1529 27.1876 33.7381 26.6366 33.5406 25.8996C33.3489 25.1843 33.4327 24.4997 33.7919 23.8459C34.1727 23.1862 34.7316 22.7577 35.4686 22.5602ZM24.0884 25.6095C24.8254 25.412 25.5129 25.5066 26.1508 25.8933C26.8105 26.2741 27.2361 26.8222 27.4278 27.5375C27.6253 28.2745 27.5307 28.962 27.144 29.6C26.7849 30.2538 26.2368 30.6795 25.4998 30.877C24.7628 31.0744 24.0644 30.9828 23.4048 30.6019C22.7726 30.2369 22.3578 29.6859 22.1604 28.9489C21.9687 28.2336 22.0524 27.549 22.4116 26.8952C22.7924 26.2356 23.3514 25.807 24.0884 25.6095Z"
        fill="#0E3460"
      />
      <path
        d="M21.2237 35.3332C21.3406 35.1625 21.4641 35.0597 21.5942 35.0249C21.7459 34.9842 21.9346 35.0382 22.1603 35.1868C22.9907 35.6846 23.8332 36.0977 24.6879 36.4263C25.5427 36.7549 26.4392 36.9793 27.3775 37.0996C28.3216 37.2416 29.3155 37.2657 30.3591 37.1719C31.4302 37.094 32.5727 36.8924 33.7866 36.5672C35.0004 36.2419 36.0798 35.8482 37.0247 35.3859C37.997 34.9395 38.8565 34.4188 39.6032 33.8237C40.3556 33.2504 41.0198 32.6078 41.5957 31.8959C42.1717 31.1839 42.6948 30.4049 43.165 29.5586C43.2645 29.3229 43.4009 29.1818 43.5743 29.1353C43.6827 29.1063 43.841 29.1336 44.0493 29.2172L46.462 30.4177C46.6544 30.5288 46.7767 30.6819 46.829 30.877C46.8696 31.0287 46.8611 31.1704 46.8035 31.302C45.8792 33.3153 44.4077 35.1501 42.3887 36.8063C40.3973 38.4783 37.8842 39.7209 34.8495 40.534C31.8147 41.3472 29.0062 41.5305 26.4239 41.084C23.869 40.6534 21.6881 39.7974 19.881 38.5158C19.7652 38.4307 19.687 38.3123 19.6464 38.1605C19.5941 37.9654 19.6235 37.7717 19.7346 37.5793L21.2237 35.3332Z"
        fill="#0E3460"
      />
    </>
  );

  switch (expression) {
    case "smile": {
      face = (
        <>
          <path
            d="M35.4686 22.5602C36.2056 22.3627 36.8931 22.4573 37.5311 22.8439C38.1907 23.2248 38.6164 23.7729 38.8081 24.4882C39.0055 25.2252 38.9109 25.9127 38.5243 26.5507C38.1651 27.2045 37.617 27.6301 36.88 27.8276C36.143 28.0251 35.4447 27.9334 34.7851 27.5526C34.1529 27.1876 33.7381 26.6366 33.5406 25.8996C33.3489 25.1843 33.4327 24.4997 33.7919 23.8459C34.1727 23.1862 34.7316 22.7577 35.4686 22.5602ZM24.0884 25.6095C24.8254 25.412 25.5129 25.5066 26.1508 25.8933C26.8105 26.2741 27.2361 26.8222 27.4278 27.5375C27.6253 28.2745 27.5307 28.962 27.144 29.6C26.7849 30.2538 26.2368 30.6795 25.4998 30.877C24.7628 31.0744 24.0644 30.9828 23.4048 30.6019C22.7726 30.2369 22.3578 29.6859 22.1604 28.9489C21.9687 28.2336 22.0524 27.549 22.4116 26.8952C22.7924 26.2356 23.3514 25.807 24.0884 25.6095Z"
            fill="#0E3460"
          />
          <path
            d="M21.2237 35.3332C21.3406 35.1625 21.4641 35.0597 21.5942 35.0249C21.7459 34.9842 21.9346 35.0382 22.1603 35.1868C22.9907 35.6846 23.8332 36.0977 24.6879 36.4263C25.5427 36.7549 26.4392 36.9793 27.3775 37.0996C28.3216 37.2416 29.3155 37.2657 30.3591 37.1719C31.4302 37.094 32.5727 36.8924 33.7866 36.5672C35.0004 36.2419 36.0798 35.8482 37.0247 35.3859C37.997 34.9395 38.8565 34.4188 39.6032 33.8237C40.3556 33.2504 41.0198 32.6078 41.5957 31.8959C42.1717 31.1839 42.6948 30.4049 43.165 29.5586C43.2645 29.3229 43.4009 29.1818 43.5743 29.1353C43.6827 29.1063 43.841 29.1336 44.0493 29.2172L46.462 30.4177C46.6544 30.5288 46.7767 30.6819 46.829 30.877C46.8696 31.0287 46.8611 31.1704 46.8035 31.302C45.8792 33.3153 44.4077 35.1501 42.3887 36.8063C40.3973 38.4783 37.8842 39.7209 34.8495 40.534C31.8147 41.3472 29.0062 41.5305 26.4239 41.084C23.869 40.6534 21.6881 39.7974 19.881 38.5158C19.7652 38.4307 19.687 38.3123 19.6464 38.1605C19.5941 37.9654 19.6235 37.7717 19.7346 37.5793L21.2237 35.3332Z"
            fill="#0E3460"
          />
        </>
      );
      break;
    }
    case "joy": {
      face = (
        <>
          <path
            d="M20.6357 32.0287C20.7986 31.9013 20.9474 31.8405 21.0819 31.8466C21.2388 31.8536 21.4024 31.962 21.5727 32.1718C22.2142 32.8969 22.8927 33.5451 23.6085 34.1163C24.3242 34.6875 25.1112 35.172 25.9694 35.5699C26.8267 35.9902 27.7669 36.3131 28.7902 36.5387C29.8348 36.7877 30.9849 36.9403 32.2403 36.9965C33.4958 37.0528 34.6437 37.0031 35.684 36.8476C36.7457 36.7154 37.7223 36.4784 38.6137 36.1364C39.5041 35.8169 40.3312 35.4047 41.0952 34.8998C41.8591 34.3949 42.5929 33.81 43.2966 33.1452C43.4626 32.9504 43.6353 32.8571 43.8146 32.8651C43.9267 32.8701 44.0694 32.9439 44.2427 33.0865L46.1806 34.9592C46.3305 35.1231 46.4009 35.306 46.3919 35.5078C46.3848 35.6647 46.334 35.7972 46.2393 35.9053C44.7506 37.5458 42.7939 38.8509 40.3693 39.8206C37.9661 40.8136 35.1951 41.2398 32.0565 41.0992C28.9179 40.9586 26.1849 40.2858 23.8578 39.0807C21.552 37.8992 19.7311 36.4248 18.395 34.6577C18.3104 34.5416 18.2716 34.4051 18.2786 34.2481C18.2876 34.0464 18.3741 33.8705 18.5381 33.7206L20.6357 32.0287Z"
            fill="#0E3460"
          />
          <path
            d="M40.4275 27.0143C40.3301 27.3427 40.1947 27.5303 40.0213 27.577C39.8697 27.6179 39.7417 27.6175 39.6375 27.5759C38.6389 27.1478 37.7283 26.9168 36.9057 26.8829C36.1047 26.8432 35.2059 26.9576 34.2092 27.2263C33.9058 27.3081 33.6845 27.2632 33.5453 27.0915C33.4669 26.9732 33.4102 26.849 33.3751 26.719L32.8056 24.6064C32.6947 24.1947 32.8558 23.9304 33.2892 23.8136C34.6109 23.4573 35.8482 23.3911 37.0009 23.6149C38.1812 23.8546 39.3177 24.2339 40.4105 24.7528C40.5481 24.8319 40.6473 24.8981 40.7081 24.9515C40.7906 24.9989 40.8465 25.0768 40.8757 25.1852C40.9049 25.2935 40.9045 25.4215 40.8746 25.569L40.4275 27.0143Z"
            fill="#0E3460"
          />
          <path
            d="M20.6383 25.9895C20.7202 26.3221 20.8466 26.5158 21.0175 26.5707C21.1671 26.6186 21.2949 26.6243 21.401 26.5876C22.4186 26.207 23.3391 26.0191 24.1624 26.0239C24.9643 26.0219 25.8568 26.1785 26.8398 26.4938C27.1389 26.5897 27.3621 26.5553 27.5093 26.3904C27.5931 26.2759 27.6556 26.1545 27.6968 26.0263L28.365 23.9428C28.4952 23.5368 28.3467 23.2652 27.9193 23.1282C26.6158 22.7101 25.383 22.5857 24.221 22.7551C23.0308 22.9389 21.8777 23.2643 20.7617 23.7312C20.6206 23.8038 20.5184 23.8653 20.4551 23.9157C20.3704 23.9592 20.3109 24.0344 20.2766 24.1413C20.2424 24.2481 20.2367 24.3759 20.2597 24.5247L20.6383 25.9895Z"
            fill="#0E3460"
          />
        </>
      );
      break;
    }

    case "eyepatch": {
      face = (
        <>
          <path
            d="M37.6963 29.9895C37.0584 29.6028 36.3709 29.5082 35.6339 29.7057C34.8969 29.9032 34.3379 30.3317 33.9571 30.9914C33.5979 31.6452 33.5142 32.3298 33.7058 33.0451C33.9033 33.7821 34.3181 34.3331 34.9503 34.6981C35.6099 35.0789 36.3083 35.1706 37.0453 34.9731C37.7823 34.7757 38.3304 34.35 38.6895 33.6962C39.0762 33.0582 39.1708 32.3707 38.9733 31.6337C38.7816 30.9184 38.356 30.3703 37.6963 29.9895Z"
            fill="#0E3460"
          />
          <path
            d="M21.6357 41.3027C21.7986 41.1752 21.9474 41.1145 22.0819 41.1205C22.2388 41.1276 22.4024 41.236 22.5727 41.4458C23.2142 42.1709 23.8927 42.8191 24.6085 43.3903C25.3242 43.9615 26.1112 44.446 26.9694 44.8439C27.8267 45.2642 28.7669 45.5871 29.7902 45.8127C30.8348 46.0617 31.9849 46.2143 33.2403 46.2705C34.4958 46.3268 35.6437 46.2771 36.684 46.1216C37.7457 45.9894 38.7223 45.7524 39.6137 45.4104C40.5041 45.0909 41.3312 44.6787 42.0952 44.1738C42.8591 43.6689 43.5929 43.084 44.2966 42.4192C44.4626 42.2244 44.6353 42.1311 44.8146 42.1391C44.9267 42.1441 45.0694 42.2179 45.2427 42.3605L47.1806 44.2332C47.3305 44.3971 47.4009 44.58 47.3919 44.7818C47.3848 44.9387 47.334 45.0712 47.2393 45.1793C45.7506 46.8198 43.7939 48.1249 41.3693 49.0945C38.9661 50.0876 36.1951 50.5138 33.0565 50.3732C29.9179 50.2326 27.1849 49.5597 24.8578 48.3547C22.552 47.1732 20.7311 45.6988 19.395 43.9317C19.3104 43.8156 19.2716 43.6791 19.2786 43.5221C19.2876 43.3204 19.3741 43.1445 19.5381 42.9946L21.6357 41.3027Z"
            fill="#0E3460"
          />
          <path
            d="M11.015 27.0641C10.6399 32.6891 17.3745 38.2834 22.265 35.6891C24.8846 34.2995 25.49 32.0141 26.39 29.3141C27.515 25.9391 25.64 23.3141 21.515 23.3141C17.39 23.3141 11.7437 16.1361 11.015 27.0641Z"
            fill="#0E3460"
          />
          <path
            d="M60.5 33C69.3364 33 59.0998 49.4572 21.9133 33"
            stroke="#0E3460"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.49988 19.5C-0.336515 19.5 11.4999 30.5 18.5654 29.3143"
            stroke="#0E3460"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
      break;
    }
    case "glasses": {
      face = (
        <>
          <path
            d="M22.164 43.268C22.2957 43.1084 22.4278 43.017 22.5604 42.9939C22.7152 42.9669 22.8983 43.0375 23.1099 43.2056C23.8926 43.7753 24.695 44.2619 25.5171 44.6653C26.3391 45.0687 27.2121 45.3721 28.136 45.5756C29.0637 45.8011 30.0514 45.9136 31.0992 45.9132C32.173 45.931 33.3289 45.8321 34.567 45.6162C35.805 45.4004 36.9152 45.1044 37.8974 44.7281C38.9057 44.3701 39.8081 43.928 40.6048 43.4019C41.4054 42.8979 42.1242 42.317 42.7613 41.6592C43.3983 41.0014 43.9887 40.272 44.5325 39.4711C44.6526 39.2451 44.801 39.1167 44.9779 39.0859C45.0884 39.0666 45.2437 39.1079 45.4437 39.2097L47.7398 40.6204C47.9215 40.7482 48.0297 40.9116 48.0644 41.1106C48.0914 41.2653 48.0703 41.4057 48.0012 41.5316C46.9013 43.4546 45.2721 45.151 43.1136 46.6207C40.9812 48.1087 38.3674 49.1224 35.2722 49.662C32.1771 50.2015 29.3634 50.1339 26.8311 49.4592C24.3248 48.8027 22.2288 47.7557 20.5431 46.3183C20.4354 46.2232 20.368 46.0982 20.3411 45.9435C20.3064 45.7445 20.3529 45.5542 20.4807 45.3724L22.164 43.268Z"
            fill="#0E3460"
          />
          <path
            d="M5.93584 24.3713C5.44082 22.6984 6.5194 21.2285 8.22562 21.2508L26.1171 21.4851C27.6722 21.5054 29.13 22.7671 29.3733 24.3031L30.915 34.0373C31.1583 35.5733 30.0949 36.802 28.5399 36.7816L11.9922 36.565C10.5842 36.5466 9.2299 35.5036 8.8214 34.1231L5.93584 24.3713Z"
            fill="#0E3460"
          />
          <path
            d="M57.6721 24.4646C57.6381 22.7977 56.0885 21.2934 54.3823 21.2711L36.4908 21.0369C34.9357 21.0165 33.8724 22.2452 34.1156 23.7812L35.6574 33.5154C35.9007 35.0514 37.3585 36.3131 38.9135 36.3334L55.4612 36.55C56.8692 36.5685 57.898 35.5567 57.87 34.1811L57.6721 24.4646Z"
            fill="#0E3460"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.7448 31.6279L26.4618 30.572C26.2996 30.763 26.2949 31.3906 26.4512 31.9738C26.6075 32.5569 26.8657 32.8748 27.0278 32.6838M27.0278 32.6838L27.0297 32.6817L27.0359 32.6744L27.0614 32.645C27.084 32.619 27.1179 32.5805 27.1619 32.5314C27.2501 32.4331 27.3792 32.2923 27.5419 32.1231C27.8675 31.7845 28.3268 31.3333 28.8614 30.8834C29.938 29.9772 31.2923 29.1003 32.4657 29.1156C33.6391 29.131 35.2773 30.0471 36.646 30.9853C37.3257 31.4511 37.9301 31.9162 38.3645 32.2647C38.5816 32.4389 38.756 32.5838 38.8757 32.6847C38.9355 32.7352 38.9817 32.7747 39.0127 32.8014L39.0476 32.8316L39.0562 32.8391L39.0582 32.8409L39.0587 32.8413C39.0587 32.8413 39.0587 32.8413 39.0587 32.8413C39.2821 33.0373 39.4405 32.7249 39.4123 32.1434C39.3842 31.562 39.1802 30.9316 38.9568 30.7356L39.0077 31.7884C38.9568 30.7356 38.9568 30.7356 38.9568 30.7356L38.953 30.7323L38.9431 30.7237L38.9055 30.6912C38.8727 30.6629 38.8246 30.6217 38.7628 30.5696C38.6391 30.4652 38.4603 30.3167 38.2383 30.1386C37.7947 29.7827 37.1775 29.3078 36.4828 28.8316C35.1029 27.8858 33.3822 26.91 32.1137 26.8934C30.8452 26.8768 29.427 27.8115 28.3417 28.7251C27.7953 29.185 27.3263 29.6456 26.9937 29.9914C26.8274 30.1644 26.695 30.3089 26.6039 30.4104C26.5584 30.4612 26.5231 30.5012 26.4991 30.5287L26.4717 30.5604L26.4645 30.5688L26.4618 30.572C26.4617 30.5721 26.4618 30.572 26.7448 31.6279"
            fill="#0E3460"
          />
        </>
      );
      break;
    }
    default: {
      face = (
        <>
          <path
            d="M14.9706 0.195086C15.7076 -0.00239419 16.3951 0.0921913 17.0331 0.478842C17.6927 0.859685 18.1184 1.40777 18.3101 2.1231C18.5075 2.86011 18.413 3.54759 18.0263 4.18556C17.6671 4.83939 17.1191 5.26504 16.382 5.46252C15.645 5.66 14.9467 5.56832 14.2871 5.18748C13.6549 4.82251 13.2401 4.27152 13.0426 3.53451C12.851 2.81918 12.9347 2.1346 13.2939 1.48077C13.6747 0.821127 14.2336 0.392567 14.9706 0.195086ZM3.59038 3.24442C4.32738 3.04694 5.01487 3.14152 5.65283 3.52818C6.31247 3.90902 6.73813 4.4571 6.9298 5.17244C7.12728 5.90944 7.03269 6.59693 6.64604 7.23489C6.28687 7.88872 5.73879 8.31438 5.00178 8.51186C4.26477 8.70934 3.56645 8.61766 2.90681 8.23681C2.27466 7.87184 1.85984 7.32085 1.66236 6.58384C1.47069 5.86851 1.55443 5.18393 1.9136 4.5301C2.29444 3.87046 2.85337 3.4419 3.59038 3.24442Z"
            fill="#0A446D"
          />
          <path
            d="M1.99406 11.8536C2.11095 11.6829 2.23442 11.5801 2.36448 11.5453C2.51621 11.5046 2.70493 11.5586 2.93061 11.7072C3.76097 12.2049 4.60351 12.6181 5.45824 12.9467C6.31297 13.2752 7.2095 13.4997 8.14782 13.62C9.09195 13.762 10.0858 13.7861 11.1294 13.6923C12.2005 13.6144 13.343 13.4128 14.5569 13.0876C15.7708 12.7623 16.8501 12.3685 17.795 11.9063C18.7673 11.4599 19.6268 10.9391 20.3735 10.3441C21.1259 9.77077 21.7901 9.12814 22.3661 8.41623C22.942 7.70432 23.4651 6.92525 23.9353 6.07902C24.0348 5.84327 24.1712 5.70217 24.3446 5.6557C24.453 5.62666 24.6114 5.65393 24.8196 5.73753L27.2323 6.93808C27.4247 7.04916 27.547 7.20225 27.5993 7.39734C27.6399 7.54907 27.6314 7.69075 27.5738 7.82237C26.6496 9.83572 25.178 11.6705 23.1591 13.3266C21.1676 14.9987 18.6545 16.2412 15.6198 17.0544C12.585 17.8676 9.77651 18.0509 7.19418 17.6044C4.63933 17.1738 2.45837 16.3177 0.65131 15.0362C0.535562 14.9511 0.45736 14.8326 0.416702 14.6809C0.364428 14.4858 0.39383 14.2921 0.504909 14.0997L1.99406 11.8536Z"
            fill="#0A446D"
          />
        </>
      );
      break;
    }
  }

  return (
    <svg
      width="72"
      height="78"
      viewBox="0 0 72 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32.5"
        cy="32.5"
        r="30"
        fill="#FFFCFC"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      {face}
      {selected && (
        <path
          d="M60 46C54.48 46 50 50.48 50 56C50 61.52 54.48 66 60 66C65.52 66 70 61.52 70 56C70 50.48 65.52 46 60 46ZM57.29 60.29L53.7 56.7C53.31 56.31 53.31 55.68 53.7 55.29C54.09 54.9 54.72 54.9 55.11 55.29L58 58.17L64.88 51.29C65.27 50.9 65.9 50.9 66.29 51.29C66.68 51.68 66.68 52.31 66.29 52.7L58.7 60.29C58.32 60.68 57.68 60.68 57.29 60.29Z"
          fill="#7EC2E4"
        />
      )}
    </svg>
  );
};

export default ExpressionIcon;
