import React, { CSSProperties } from "react";
import ReactModal, { Styles as ReactModalStyles } from "react-modal";

export type TModalProps = React.ComponentPropsWithoutRef<"div"> & {
  /**
   * callback for any close requests
   */
  onClose: () => void;

  isOpen: boolean;

  overlayStyle?: CSSProperties;

  /**
   * Number indicating the milliseconds to wait before closing the modal
   */
  closeTimeoutMS?: number;

  /**
   * String indicating how the content container should be announced to screenreaders
   */
  contentLabel?: string;

  shouldCloseOnOverlayClick?: boolean;

  shouldCloseOnEsc?: boolean;
};

const DEFAULT_MODAL_OVERLAY_STYLE = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(0, 0, 0, 0.7)",
  zIndex: 100,
};

export const Modal: React.FC<TModalProps> = ({
  children,
  isOpen = false,
  onClose = () => {},
  overlayStyle,
  style,
  ...rest
}) => {
  const modalStyle: ReactModalStyles = {
    overlay: {
      ...DEFAULT_MODAL_OVERLAY_STYLE,
      ...overlayStyle,
    },
    content: style,
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
      {...rest}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
