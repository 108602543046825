import React from "react";
import styled, { css } from "styled-components";

import Icon, { TIconName } from "src/components/base/Icon";

export type TButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  link?: boolean;
  secondary?: boolean;
  icon?: TIconName;
  iconSize?: string;
  leftIcon?: boolean;
};

const sharedButtonStyles = css`
  padding: 16px 64px;
  border-radius: 16px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.body}px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 19px;
  /* position: relative;

  :focus::before {
    position: absolute;
    top: -7px;
    left: -7px;
    bottom: -7px;
    right: -7px;
    content: "";
    border: 2px solid #43afde;
    border-radius: 51px;
    opacity: 0.7;
  } */
`;

const PrimaryButton = styled.button`
  ${sharedButtonStyles}
  background-color: ${({ theme }) => theme.color.primary.blue1};
  border: 1px solid ${({ theme }) => theme.color.primary.blue1};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme }) => theme.color.white};

  &:hover,
  &:focus {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.7)};
    cursor: pointer;
    outline: none;
  }
`;

const SecondaryButton = styled.button`
  ${sharedButtonStyles}
  border: 1px solid ${({ theme }) => theme.color.primary.blue1};
  // 0.1 alpha
  background-color: ${({ theme }) => theme.color.secondary.lightBlue};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme }) => theme.color.primary.blue1};

  &:hover,
  &:focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.color.secondary.lightBlueHover};
  }
`;

const LinkButton = styled.button<TButtonProps>`
  background: none;
  border: none;
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.color.primary.blue2};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSize.body}px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  margin-left: 5px;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    opacity: 80%;
    text-decoration: underline;
  }
`;

const StyledIcon = styled(Icon)<{ leftIcon: boolean }>`
  ${({ leftIcon }) => (leftIcon ? "margin-right: 14px" : "margin-left: 14px")};
`;

const Button: React.FC<TButtonProps> = ({
  children,
  secondary,
  link = false,
  icon,
  iconSize,
  leftIcon = false,
  ...rest
}) => {
  const ButtonComponent = link
    ? LinkButton
    : secondary
    ? SecondaryButton
    : PrimaryButton;

  const Icon = icon ? (
    <StyledIcon leftIcon={leftIcon} name={icon} size={iconSize} />
  ) : null;

  return (
    <ButtonComponent {...rest}>
      {leftIcon && Icon}
      {children}
      {!leftIcon && Icon}
    </ButtonComponent>
  );
};

export default Button;
