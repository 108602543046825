import React from "react";
import { components } from "react-select";
import styled from "styled-components";

import { Icon } from "src/components";

const StyledIcon = styled(Icon)`
  > path {
    stroke: ${({ theme }) => theme.color.white};
  }
`;

const MultiValueRemoveComponent = (props: any) => (
  <components.MultiValueRemove {...props}>
    <StyledIcon name="x" size="16px" />
  </components.MultiValueRemove>
);

export default MultiValueRemoveComponent;
