import { TDefaultTheme } from "./types";

export const sharedTheme: TDefaultTheme = {
  color: {
    white: "#FFFFFF",
    black: "#000000",

    primary: {
      blue1: "#0A446D",
      blue2: "#4779A4",
      blue3: "#7EC2E4",
    },

    secondary: {
      red: "#AD0423",
      green: "#4F937E",
      lightBlue: "#E9EEF2",
      lightBlueHover: "#DCE3E8",
      highlight: "#F7EFE0",
      paginatorBackground: "#fff5f5",
    },

    gradient: {
      bluePink: "linear-gradient(180deg, #D1DCEB 14.06%, #F9ECEC 100%)",
      blueGreen:
        "linear-gradient(332.54deg, #4F937E 14.73%, rgba(79, 147, 126, 0) 91.08%), #43AFDE",
    },

    text: {
      blue: "#0E3460",
      grey: "#828282",
    },

    toast: {
      red: "#F7E6E9",
      green: "#E6EFEE",
    },

    grey: {
      background: "#FAFAFA",
      hover: "#DDDDDD",
      outline: "#C4C4C4",
    },

    custom: {
      pastelGradient: "linear-gradient(180deg, #D3DEEC 14.43%, #FAEDED 100%)",
      dandelionYellow: "TODO: WHERE IS THIS USED???",
      longPastelGradient:
        "linear-gradient(180.04deg, #D3DEEC 8.5%, #FAEDED 58.69%)",
      greenBlueGradient:
        "linear-gradient(144.92deg, #4F937E 32.37%, #0E3460 75.69%)",
      offWhite: "#FFFCFC",
      mobileGradient: "linear-gradient(180deg, #D3DEEC -10.4%, #FAEDED 44.53%)",
      blueGreenGradient:
        "linear-gradient(332.54deg, rgb(79, 147, 126) 14.73%, rgba(79, 147, 126, 0) 91.08%), #43AFDE;",
    },
  },
  fontFamily: {
    heading:
      "Castledown, -apple-system, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    body: "Inter, -apple-system, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  },

  fontSize: {
    titleD: 68,
    h1D: 44,
    h2D: 36,
    h3D: 28,

    titleM: 48,
    h1M: 36,
    h2M: 32,
    h3M: 24,
    h4M: 18,

    body: 16,
    label: 12,
  },
};
