import React, { useState } from "react";
import { OptionTypeBase } from "react-select";
import tw, { styled } from "twin.macro";

import { ExclamationIcon, Flex, Spacer, Text, GeoCoder } from "src/components";
import {
  GRADUATING_YEAR_OPTIONS,
  LEVEL_OF_STUDY_OPTIONS,
  SCHOOL_OPTIONS,
  PROGRAM_OPTIONS,
  HACKATHON_COUNT_OPTIONS,
} from "src/constants/copy";
import { QuestionName } from "src/contexts/ResponseContext/config";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { answerToOption, answersToOptions } from "src/utils/react-select";
import { mediaQueries } from "src/utils/responsive";

import DropdownTagInput from "../DropdownTagInput";
import TextInput from "../TextInput";

import FullReviewSection from "./FullReviewSection";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px));
  row-gap: 24px;
  column-gap: 24px;
  ${mediaQueries.largeMobile} {
    display: block;
    > :not(:first-child) {
      margin-top: 24px;
    }
  }
`;

const Title = styled(Text).attrs({
  heading: true,
  size: "h3",
  element: "h3",
})`
  color: ${({ theme }) => theme.color.primary.blue1};
`;

const Answer = styled(Text).attrs({
  heading: true,
  size: "h2",
})`
  color: ${({ theme }) => theme.color.primary.blue2};
`;

const TextInputContainer = styled.div`
  > div {
    width: auto;
  }
`;

const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.color.secondary.red};
`;

const FullReviewShortAnswer = () => {
  const {
    responses: {
      short_name,
      short_school,
      short_grad_year,
      short_grad_level,
      short_program,
      short_location,
      short_hackathon_count,
    },
    updateResponseValues,
    saveResponses,
  } = useResponse();

  const [tempFullName, setTempFullName] = useState(short_name.value);
  const [tempSchool, setTempSchool] = useState(short_school.value);
  const [tempGradYear, setTempGradYear] = useState(short_grad_year.value);
  const [tempGradLevel, setTempGradLevel] = useState(short_grad_level.value);
  const [tempProgram, setTempProgram] = useState(short_program.value);
  const [tempLocation, setTempLocation] = useState(short_location.value);
  const [tempHackathonCount, setTempHackathonCount] = useState(
    short_hackathon_count.value
  );

  const [nameError, setNameError] = useState(false);
  const [schoolError, setSchoolError] = useState(false);

  const handleSave = () => {
    updateResponseValues({
      [QuestionName.FULL_NAME]: tempFullName,
      [QuestionName.SCHOOL_NAME]: tempSchool,
      [QuestionName.GRAD_YEAR]: tempGradYear,
      [QuestionName.GRAD_LEVEL]: tempGradLevel,
      [QuestionName.PROGRAM]: tempProgram,
      [QuestionName.LOCATION]: tempLocation,
      [QuestionName.HACKATHON_COUNT]: tempHackathonCount,
    });
    saveResponses();
  };

  const handleCancel = () => {
    setTempFullName(short_name.value);
    setTempSchool(short_school.value);
    setTempGradYear(short_grad_year.value);
    setTempGradLevel(short_grad_level.value);
    setTempProgram(short_grad_level.value);
    setTempLocation(short_location.value);
    setTempHackathonCount(short_hackathon_count.value);
  };

  return (
    <FullReviewSection
      title="ABOUT YOU"
      onSave={() => handleSave()}
      onCancel={() => handleCancel()}
      saveDisabled={nameError}
    >
      {(isEditing: boolean) => (
        <Container>
          <Flex column>
            <Flex>
              <Title>Name:</Title>
              {nameError && (
                <Flex tw="ml-8">
                  <ExclamationIcon />
                  <ErrorText>This field cannot be empty.</ErrorText>
                </Flex>
              )}
            </Flex>
            <Spacer height={6} />
            {isEditing ? (
              <TextInputContainer>
                <TextInput
                  placeholder="My full name"
                  value={tempFullName ?? ""}
                  onChange={(e) => {
                    setNameError(e.target.value === "");
                    setTempFullName(e.target.value);
                  }}
                  review
                />
              </TextInputContainer>
            ) : (
              <Answer>{short_name.value}</Answer>
            )}
          </Flex>
          {tempGradLevel !== "other" && (
            <Flex column>
              <Title>Graduating Year:</Title>
              <Spacer height={6} />
              {isEditing ? (
                <DropdownTagInput
                  placeholder="year"
                  value={tempGradYear ? answerToOption(tempGradYear) : undefined}
                  onChange={(option: OptionTypeBase) =>
                    setTempGradYear(option.value)
                  }
                  options={answersToOptions(GRADUATING_YEAR_OPTIONS)}
                />
              ) : (
                <Answer>{short_grad_year.value}</Answer>
              )}
            </Flex>
          )}
          {tempGradLevel !== "other" && (
            <Flex column>
              <Flex>
                <Title>School:</Title>
                {schoolError && tempGradLevel === "high school" && (
                  <Flex tw="ml-8">
                    <ExclamationIcon />
                    <ErrorText>This field cannot be empty.</ErrorText>
                  </Flex>
                )}
              </Flex>
              <Spacer height={6} />
              {isEditing ? (
                <TextInputContainer>
                  {tempGradLevel === "high school" ? (
                    <TextInput
                      placeholder="My school name"
                      value={tempSchool ?? ""}
                      onChange={(e) => {
                        setSchoolError(e.target.value === "");
                        setTempSchool(e.target.value);
                      }}
                      review
                    />
                  ) : (
                    <DropdownTagInput
                      creatable
                      isSearchable
                      placeholder="My school name"
                      value={answerToOption(tempSchool ?? "")}
                      onChange={(option: OptionTypeBase) =>
                        setTempSchool(option.value)
                      }
                      options={answersToOptions(SCHOOL_OPTIONS)}
                    />
                  )}
                </TextInputContainer>
              ) : (
                <Answer>{short_school.value}</Answer>
              )}
            </Flex>
          )}
          <Flex column>
            <Title>Location:</Title>
            <Spacer height={6} />
            {isEditing ? (
              <TextInputContainer>
                <GeoCoder
                  placeholder="My city"
                  value={
                    tempLocation ? answerToOption(tempLocation) : undefined
                  }
                  onChange={(e: any) => setTempLocation(e.value)}
                />
              </TextInputContainer>
            ) : (
              <Answer>{short_location.value}</Answer>
            )}
          </Flex>
          {tempGradLevel !== "high school" && tempGradLevel !== "other" && (
            <Flex column>
              <Title>Program:</Title>
              <Spacer height={6} />
              {isEditing ? (
                <DropdownTagInput
                  creatable
                  isSearchable
                  placeholder="My program name"
                  value={answerToOption(tempProgram ?? "")}
                  onChange={(option: OptionTypeBase) =>
                    setTempProgram(option.value)
                  }
                  options={answersToOptions(PROGRAM_OPTIONS)}
                />
              ) : (
                <Answer>{short_program.value}</Answer>
              )}
            </Flex>
          )}
          <Flex column>
            <Title>Prior Hackathons:</Title>
            <Spacer height={6} />
            {isEditing ? (
              <DropdownTagInput
                placeholder="0"
                value={answerToOption(tempHackathonCount ?? "")}
                onChange={(option: OptionTypeBase) =>
                  setTempHackathonCount(option.value)
                }
                options={answersToOptions(HACKATHON_COUNT_OPTIONS)}
              />
            ) : (
              <Answer>{short_hackathon_count.value}</Answer>
            )}
          </Flex>
          <Flex column>
            <Title>Level of Study:</Title>
            <Spacer height={6} />
            {isEditing ? (
              <DropdownTagInput
                placeholder="level of study"
                value={answerToOption(tempGradLevel)}
                onChange={(option: OptionTypeBase) =>
                  setTempGradLevel(option.value)
                }
                options={answersToOptions(LEVEL_OF_STUDY_OPTIONS)}
              />
            ) : (
              <Answer>{short_grad_level.value}</Answer>
            )}
          </Flex>
        </Container>
      )}
    </FullReviewSection>
  );
};

export default FullReviewShortAnswer;
