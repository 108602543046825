import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router";
import styled, { css } from "styled-components";

import { Icon } from "src/components";
import { mediaQueries } from "src/utils/responsive";

import { normalTextStyles } from "../base/Text";
import { RouteName } from "src/constants/route";

type TTextInputProps = React.ComponentPropsWithRef<"input"> & {
  label?: string;
  shortAnswer?: boolean;
  review?: boolean;
  setError?: (id: number, value: boolean) => void;
  focusOnFlip?: boolean;
  route?: RouteName;
  error?: boolean;
};

const TextInput: React.FC<TTextInputProps> = ({
  label,
  required,
  type = "text",
  review = false,
  shortAnswer = false,
  focusOnFlip,
  route,
  error = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (inputRef.current && focusOnFlip && route === location.pathname) {
      inputRef.current.focus();
    }
  }, [location.pathname]);

  return (
    <>
      <Wrapper shortAnswer={shortAnswer}>
        <StyledInput
          aria-label={label}
          required={required}
          shortAnswer={shortAnswer}
          review={review}
          ref={inputRef}
          hasValue={rest.value != ""}
          type={type}
          error={error}
          {...rest}
        />
      </Wrapper>
    </>
  );
};

const longAnswerStyles = css`
  height: 53px;
  padding: 0px 16px;
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.body};
  color: ${({ theme }) => theme.color.text.grey};
  font-size: ${({ theme }) => theme.fontSize.body}px;
  font-style: normal;
  line-height: 16px;
`;

const shortAnswerStyles = css`
  height: 64px;
  padding: 16px;
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontSize.h3D}px;
  color: ${({ theme }) => theme.color.primary.blue2};
  line-height: 16px;

  ${mediaQueries.largeMobile} {
    font-size: ${({ theme }) => theme.fontSize.h3M}px;
    height: 45px;
  }
`;

const StyledInput = styled.input<TTextInputProps & { hasValue?: boolean }>`
  ${normalTextStyles}
  display: inline-block;
  outline: none;
  border: ${({ theme, error }) =>
    error ? `1px solid ${theme.color.secondary.red}` : `1px solid #006691`};
  border-radius: 16px;
  background: #fafcff;
  ${({ shortAnswer }) => (shortAnswer ? shortAnswerStyles : longAnswerStyles)};
  height: ${({ review }) => review && "64px"};

  &::placeholder {
    opacity: 0.5;
  }
  &:focus {
    opacity: 1;
  }
  ${mediaQueries.largeMobile} {
    font-size: ${({ theme }) => theme.fontSize.h4M}px;
    border-radius: 8px;
  }
`;

const Wrapper = styled.div<TTextInputProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &:after {
    height: ${({ shortAnswer }) => (shortAnswer ? "6px" : "4px")};
  }
  margin-bottom: 15px;
`;

export default TextInput;
