import { useState, useEffect } from "react";

/**
 * A hook that provides the dimensions
 * of the screen.
 */
export const useScreenSize = () => {
  const [width, setWidth] = useState<number>(screen.width);
  const [height, setHeight] = useState<number>(screen.height);

  const handleScreenResize = () => {
    if (screen?.width > 0) {
      setWidth(screen.width);
    }
    if (screen?.height > 0) {
      setHeight(screen.height);
    }
  };

  useEffect(() => {
    handleScreenResize();
    window.addEventListener("resize", handleScreenResize);

    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  return { screenWidth: width, screenHeight: height };
};
