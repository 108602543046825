import React, { useCallback, useEffect } from "react";
import tw, { styled } from "twin.macro";

import { Spacer } from "src/components";
import { useSiteContext } from "src/contexts/SiteContext";
import {
  STORYBOOK_SIZE,
  TStorybookPageProps,
} from "src/contexts/StorybookContext/Storybook/constants";

const Container = styled.div`
  /* ${tw`w-full h-full`} */
  width: 100%;
  height: 45vh;
  border-left: 2.5px solid #d9d5ce;
  z-index: 10;
`;

const ShortAnswerPage: React.FC<TStorybookPageProps> = ({
  offset,
  style,
  children,
  ...rest
}) => {
  const { isMobile } = useSiteContext();

  const onKeyPress = useCallback((e: KeyboardEvent) => {}, []);

  const pageStyle = isMobile
    ? {
        background: "white",
      }
    : {
        background: "#FFFCFC",
        width: STORYBOOK_SIZE.width / 2 + (offset || 0) * 2,
        height: STORYBOOK_SIZE.height,
      };

  useEffect(() => {
    window.addEventListener("keypress", onKeyPress);
    return () => window.removeEventListener("keypress", onKeyPress);
  }, [onKeyPress]);
  return (
    <Container style={{ ...style, ...pageStyle }} {...rest}>
      <div
        tw="flex flex-col justify-center align-items[center] h-full p-64 md:p-56 lm:p-24 lm:justify-between"
        css={[isMobile && tw`mt-32`]}
      >
        {children}
      </div>
    </Container>
  );
};

export default ShortAnswerPage;
