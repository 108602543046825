import React, { useMemo } from "react";
import { useTheme } from "styled-components";

import {
  XIcon,
  EditIcon,
  PlusIcon,
  VideoIcon,
  ChevronIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckmarkIcon,
  BlueCheckmarkIcon,
  GreenCheckmarkIcon,
  PictureIcon,
  DownloadIcon,
} from "src/static/icons";

export type TIconName =
  | "x"
  | "edit"
  | "plus"
  | "video"
  | "chevron"
  | "chevronLeft"
  | "chevronRight"
  | "checkmark"
  | "blueCheckmark"
  | "greenCheckmark"
  | "picture"
  | "download";

export type TIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  name: TIconName;
  /**
   * Pass in the name of a globalConstant fontSize value or a css value. Defaults to `bodyBig` size.
   */
  size?: string;
};

const NAME_TO_ICON_MAP: Record<
  TIconName,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  x: XIcon,
  edit: EditIcon,
  plus: PlusIcon,
  video: VideoIcon,
  chevron: ChevronIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  checkmark: CheckmarkIcon,
  blueCheckmark: BlueCheckmarkIcon,
  greenCheckmark: GreenCheckmarkIcon,
  picture: PictureIcon,
  download: DownloadIcon,
};

const Icon: React.FC<TIconProps> = ({ name, size = "bodyL", ...rest }) => {
  const theme = useTheme();

  const internalSize = theme.fontSize[size]
    ? `${theme.fontSize[size]}px`
    : size;

  const IconSVG = useMemo(() => NAME_TO_ICON_MAP[name], [name]);

  if (!IconSVG) console.warn(`Invalid icon name: ${name}`);

  return IconSVG ? <IconSVG width="16px" height="16px" {...rest} /> : null;
};

export default Icon;
