import { ComponentPropsWithoutRef } from "react";

export const STORYBOOK_SIZE = {
  width: 1366,
  height: 613,
};

export const COVER_SIZE = {
  width: 700,
  height: 625,
};

export type TStorybookPageProps = ComponentPropsWithoutRef<"div"> & {
  offset?: number;
};
