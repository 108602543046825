import { getAccessToken } from "@hackthenorth/north";
import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

import { Flex, Spacer } from "src/components";
import { RouteName } from "src/constants/route";
import { TSiteContextValue, useResponse, useSiteContext } from "src/contexts";
import { mediaQueries } from "src/utils/responsive";

import Section from "./Section";

export const TOTAL_HEADER_HEIGHT_PX = 72;

export const NOSHOW_ROUTES = [
  RouteName.LANDING,
  RouteName.APPLICATION_SUBMITTED,
];

type TRouteInfo = {
  title: string;
  to?: RouteName; // where it goes on click, defaults to the same routename as what displays the section
  completedResolver: (
    steps: TSiteContextValue["steps"],
    responseValues: ReturnType<typeof useResponse>["responses"],
    isApplicationSubmitted: boolean | undefined
  ) => boolean; // defaults to visited
};

export const ROUTE_INFO: Partial<Record<RouteName, TRouteInfo>> = {
  [RouteName.BUILD_A_BOAT]: {
    title: "Build-a-boat",
    completedResolver: (steps, responseValues) =>
      Boolean(responseValues.boat_custom_accessory.value) &&
      Boolean(responseValues.boat_custom_color.value) &&
      Boolean(responseValues.boat_custom_expression.value) &&
      steps[RouteName.BUILD_A_BOAT].visited &&
      steps[RouteName.SHORT_ANSWER_NAME].visited,
  },
  [RouteName.SHORT_ANSWER]: {
    title: "About me",
    to: RouteName.SHORT_ANSWER_NAME,
    completedResolver: (steps, responseValues) =>
      [
        RouteName.SHORT_ANSWER_NAME,
        RouteName.SHORT_ANSWER_GRAD,
        RouteName.SHORT_ANSWER_LOCATION,
        RouteName.SHORT_ANSWER_EXPERIENCE,
      ].every((route) => steps[route].visited) &&
      [
        responseValues.short_grad_level.value,
        responseValues.short_location.value,
        responseValues.short_hackathon_count.value,
        responseValues.short_name.value,
      ].every((value) => value !== "" && value !== null),
  },
  [RouteName.CREATE_ACCOUNT]: {
    title: "Create my account",
    completedResolver: () => Boolean(getAccessToken()),
  },
  [RouteName.LONG_ANSWER]: {
    title: "Write my story",
    completedResolver: (steps, responseValues) =>
      steps[RouteName.LONG_ANSWER].visited &&
      [
        responseValues.long_obstacle.value,
        responseValues.long_desire.value,
        responseValues.long_collaboration.value,
        responseValues.long_hacker_type.value,
      ].every((value) =>
        typeof value === "string"
          ? value !== "" && value !== null
          : value.length !== 0
      ),
  },
  [RouteName.SURVEY]: {
    title: "Survey",
    completedResolver: (steps) =>
      steps[RouteName.SURVEY].visited && steps[RouteName.REVIEW].visited,
  },
  [RouteName.REVIEW]: {
    title: "Review",
    completedResolver: (steps, _, isApplicationSubmitted) =>
      steps[RouteName.REVIEW].visited && Boolean(isApplicationSubmitted),
  },
};

const Header = () => {
  const location = useLocation();
  const { responses, isApplicationSubmitted } = useResponse();
  const { steps } = useSiteContext();

  const shouldShow =
    ![RouteName.LANDING, RouteName.APPLICATION_SUBMITTED].includes(
      location.pathname as any
    ) && !isApplicationSubmitted;

  const shownSteps = Object.entries(steps)
    .filter(([stepName]) => !!ROUTE_INFO[stepName])
    .map(([stepName, stepDetails]) => ({
      ...stepDetails,
      route: stepName as RouteName,
      info: ROUTE_INFO[stepName] as TRouteInfo,
      completed: ROUTE_INFO[stepName].completedResolver?.(
        steps,
        responses,
        isApplicationSubmitted
      ),
    }));

  return (
    <Container show={shouldShow} aria-hidden={!shouldShow}>
      <ContentContainer align="center">
        <Spacer width="5%" />
        {shownSteps.map(({ route, visited, current, completed, info }, i) => {
          const isPrevStepCompleted =
            i === 0 ? true : shownSteps[i - 1].completed;
          const isNotLastStep = i !== shownSteps.length - 1;
          return (
            <React.Fragment key={route}>
              <Section
                title={info.title}
                route={route as RouteName}
                visited={visited}
                current={current}
                completed={completed}
                to={info.to ?? (route as RouteName)}
              />
              {isNotLastStep && (
                <Line
                  progress={
                    completed && isPrevStepCompleted ? 100 : current ? 50 : 0
                  }
                />
              )}
            </React.Fragment>
          );
        })}
        <Spacer width="5%" />
      </ContentContainer>
    </Container>
  );
};

export default Header;

const Container = styled.header<{ show: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: ${({ show }) => (show ? 1 : -2)};
  width: 100%;
  height: ${TOTAL_HEADER_HEIGHT_PX}px;

  transition: opacity 400ms;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const ContentContainer = styled(Flex)`
  width: 100%;
  /**
   * Hide scrollbar but keep functionality
   * NOTE: https://stackoverflow.com/a/49278385/13796346
   */
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  mask-image: linear-gradient(
    to right,
    transparent,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    black,
    transparent
  );
`;

const Line = styled.div<{ progress: number }>`
  background-image: linear-gradient(
    to right,
    #7ec2e4 45%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 20px 2px;
  background-repeat: repeat-x;
  height: 2px;
  min-width: 100px;
  flex-grow: 1;
  mask-image: linear-gradient(
    to right,
    ${({ progress }) =>
      [
        ...new Array(Math.round(progress / 10)).fill("black"),
        ...new Array(10 - Math.round(progress / 10)).fill("transparent"),
      ].join()}
  );

  ${mediaQueries.tablet} {
    min-width: 72px;
  }

  ${mediaQueries.largeMobile} {
    min-width: 48px;
  }
`;
