import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

import {
  CustomBoat,
  ShortAnswerQuestion,
  Text,
  TextInput,
  ShortAnswerPage,
  InkTransition,
  Spacer,
  TexturedAsset,
} from "src/components";
import { RouteName } from "src/constants/route";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import StorybookPaginator from "src/contexts/StorybookContext/Paginator";
import { TStorybookPageProps } from "src/contexts/StorybookContext/Storybook/constants";
import { NameImg, TextureUrl } from "src/static";
import { mediaQueries } from "src/utils/responsive";

import AssetPage from "../AssetPage";

export const BodyText = styled(Text).attrs({
  heading: true,
  size: "h2D",
})`
  ${mediaQueries.largeMobile} {
    font-size: ${({ theme }) => theme.fontSize.h3M}px;
  }
`;

const Name: React.FC<TStorybookPageProps> = (props) => {
  const {
    responses: {
      short_name,
      boat_custom_color,
      boat_custom_expression,
      boat_custom_accessory,
    },
    saveResponses,
  } = useResponse();

  const { value, setValue } = short_name;
  const { onTurnNext } = useStorybookContext();
  const { isMobile } = useSiteContext();

  const hasContent = value !== null && value !== "" && value !== undefined;

  return (
    <>
      <AssetPage {...props}>
        {hasContent ? (
          <InkTransition>
            <TexturedAsset texture={TextureUrl}>
              <NameImg />
            </TexturedAsset>
            {!isMobile && (
              <CustomBoatContainer>
                <CustomBoat
                  color={boat_custom_color?.value || "pink"}
                  expression={boat_custom_expression?.value || "smile"}
                  accessory={boat_custom_accessory?.value || "flag"}
                />
              </CustomBoatContainer>
            )}
          </InkTransition>
        ) : null}
      </AssetPage>
      <ShortAnswerPage {...props}>
        <ShortAnswerQuestion
          // display first name
          buttonText={`Hi ${value.split(" ")[0]}!`}
          showButton={hasContent}
        >
          <BodyText>Time to set sail in the open seas! My name is</BodyText>
          {isMobile ? <Spacer height={24} /> : <Spacer height={48} />}
          <TextInput
            shortAnswer
            focusOnFlip
            route={RouteName.SHORT_ANSWER_NAME}
            placeholder="My full name"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e: any) => {
              if (
                e.key === "Enter" &&
                hasContent &&
                location.pathname == RouteName.SHORT_ANSWER_NAME
              ) {
                onTurnNext();
              }
            }}
            tabIndex={0}
          />{" "}
        </ShortAnswerQuestion>
        {isMobile && <StorybookPaginator />}
      </ShortAnswerPage>
    </>
  );
};

const CustomBoatContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 90px;
`;

export default Name;
