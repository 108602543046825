import React, { useMemo } from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import "twin.macro";

import { Text, Flex, GradientText } from "src/components";
import { ALT_FORM_TITLES, LONG_ANSWER_TITLES } from "src/constants/copy";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { ErrorSignIcon } from "src/static/icons";
import { mediaQueries } from "src/utils/responsive";

export type TLongAnswerQuestionProps = {
  /**
   * indicates if a question is required or not, true by default
   */
  isRequired?: boolean;
  /**
   * this would be the input
   */
  children: React.ReactNode;
  step: number;
  showSurveyButton?: boolean;
  errors: string[];
  isSaved: boolean;
  requiresAtLeastOne?: boolean; //bruh
  didEdit?: boolean;
  isAlternativeForm?: boolean;
};

const ReactScrollContainer = styled(Element)`
  height: auto;
  margin: 30px 0px;
  ${mediaQueries.largeMobile} {
    height: auto;
    margin-top: 36px;
  }
`;

const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.color.primary.blue2};
  margin-right: 15px;
`;

const StatusText = styled(Text)<{ hasError?: boolean }>`
  color: ${({ theme, hasError }) =>
    hasError ? theme.color.secondary.red : theme.color.secondary.green};
`;

const StatusTextContainer = styled(Flex)`
  margin: 6px 0;
`;

export const ExclamationIcon = styled(ErrorSignIcon)`
  margin-right: 5px;
`;

export default function LongAnswerQuestion({
  isRequired = true,
  children,
  step,
  errors,
  isSaved,
  didEdit,
  requiresAtLeastOne = false,
  isAlternativeForm = false,
}: TLongAnswerQuestionProps) {
  const isEmpty = useMemo(
    () => didEdit && errors.includes("This field is required."),
    [didEdit, errors]
  );

  const getStatusText = () => {
    if (errors.length > 0) {
      if (isEmpty && isRequired) {
        return "This field is required.";
      }
      return null;
    } else if (isSaved) {
      return "Saved ✓";
    } else {
      return null;
    }
  };

  const titles = isAlternativeForm ? ALT_FORM_TITLES : LONG_ANSWER_TITLES;
  const {
    responses: { short_grad_level },
  } = useResponse();

  const title = titles[step];
  const { isMobile } = useSiteContext();
  const isHighSchoolStudent = short_grad_level.value == "high school";
  const isOtherStudent = short_grad_level.value == "other";
  const getStepNumber = (step: number) => {
    if (isAlternativeForm) {
      if (isOtherStudent && step > 2) {
        return step - 3;
      }
      if (isHighSchoolStudent && step > 3) {
        return step - 1;
      }
    }
    return step;
  };

  const getTotalQuestions = (total: number) => {
    if (isAlternativeForm) {
      if (isOtherStudent) {
        return total - 3;
      }
      if (isHighSchoolStudent) {
        return total - 1;
      }
    }
    return total;
  };
  const questionLength = getTotalQuestions(titles.length);
  const currentStep = getStepNumber(step) + 1;

  return (
    <ReactScrollContainer id={title} name={title}>
      <Flex column>
        <GradientText>
          QUESTION {currentStep} of {questionLength}
        </GradientText>
        <Text heading size={isMobile ? "h3M" : "h3D"} element="h2">
          {title}
        </Text>
      </Flex>

      <StatusTextContainer align="center">
        {requiresAtLeastOne && <Subtitle>Select at least one.</Subtitle>}
        <Flex align="center">
          {isEmpty && isRequired && <ExclamationIcon />}
          <StatusText hasError={isEmpty}>{getStatusText()}</StatusText>
        </Flex>
      </StatusTextContainer>

      {children}
    </ReactScrollContainer>
  );
}
