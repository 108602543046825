import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";

import { CustomBoat } from "src/components";
import { wave, waveSwell, boatBounce } from "src/constants/animations";
import { useResponse, useSiteContext } from "src/contexts";
import { WaveUrl } from "src/static";
import { mediaQueries } from "src/utils/responsive";
import { useWindowSize } from "src/utils/useWindowSize";

export const BOAT_POSITIONS = [-1, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const SCALE_BREAKPOINT = 2400;

const Waves: React.FC = () => {
  const {
    responses: {
      boat_custom_color,
      boat_custom_expression,
      boat_custom_accessory,
    },
  } = useResponse();

  const { boatPosition, boatQuote } = useSiteContext();
  const { windowWidth } = useWindowSize();
  const [isBooping, doTheBoop] = useState(false);

  let scaleFactor = 1.0;
  if (windowWidth && windowWidth <= SCALE_BREAKPOINT) {
    scaleFactor = windowWidth / SCALE_BREAKPOINT;
  }

  return (
    <>
      <WavesWrapper>
        <Wave />
        <Wave className="alt" />
        <Wave className="alt2" />
      </WavesWrapper>
      {boatPosition !== 0 && (
        <BoatWrapper
          boatPosition={boatPosition}
          scaleFactor={scaleFactor}
          className={isBooping ? "boop" : ""}
          onClick={() => {
            doTheBoop(true);
          }}
          onAnimationEnd={() => doTheBoop(false)}
        >
          <BoatBobbingContainer>
            <CustomBoat
              color={boat_custom_color?.value || "pink"}
              expression={boat_custom_expression?.value || "smile"}
              accessory={boat_custom_accessory?.value || "flag"}
              speechBubbleMarkup={boatQuote}
              AltSpeechBubble
            />
          </BoatBobbingContainer>
        </BoatWrapper>
      )}
    </>
  );
};

const WavesWrapper = styled.div`
  height: 198px;
  width: 100%;
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  left: 0;
  pointer-events: none;
`;

const Wave = styled.div`
  background: url(${WaveUrl}) repeat-x;
  position: absolute;
  bottom: -25px;
  width: 6890px;
  height: 100%;
  animation: ${wave} 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);

  &.alt {
    animation: ${wave} 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      ${waveSwell} 7s ease -1.25s infinite;
    animation-delay: 0.5s;
  }

  &.alt2 {
    animation: ${wave} 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.25s infinite,
      ${waveSwell} 7s ease -1.25s infinite;
    animation-delay: 1s;
  }
`;

const BoatBobbing = keyframes`
  0% {
    transform: translateY(-10px);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(10px);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(-10px);
  }
`;

const BoatBobbingAnimation = css`
  animation-name: ${BoatBobbing};
  animation-duration: 5s;
  animation-iteration-count: infinite;
`;

const BoatBobbingContainer = styled.div`
  ${BoatBobbingAnimation}
`;

const BoatAnimation = css<{ boatPosition: number; scaleFactor: number }>`
  // Second value sets the length of this path
  offset-path: path("M0.5 0.5H2000");
  transition: 5s;
  offset-distance: ${({ boatPosition, scaleFactor }) =>
    `${scaleFactor * BOAT_POSITIONS[boatPosition]}%`};
`;

const BoatWrapper = styled.div<{ boatPosition: number; scaleFactor: number }>`
  z-index: 10;
  position: absolute;
  left: -100px;
  bottom: 20px;
  /* pointer-events: none; */
  cursor: pointer;
  ${BoatAnimation}

  &.boop {
    animation: ${boatBounce} 1s ease-out;
  }
`;

export default Waves;
