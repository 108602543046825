import React from "react";
import { OptionTypeBase } from "react-select";
import tw, { styled, css } from "twin.macro";

import {
  ShortAnswerQuestion,
  Text,
  Spacer,
  DropdownTagInput,
  ShortAnswerPage,
  InkTransition,
  TexturedAsset,
} from "src/components";
import {
  LEVEL_OF_STUDY_OPTIONS,
  GRADUATING_YEAR_OPTIONS,
} from "src/constants/copy";
import { RouteName } from "src/constants/route";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import StorybookPaginator from "src/contexts/StorybookContext/Paginator";
import { TStorybookPageProps } from "src/contexts/StorybookContext/Storybook/constants";
import { GraduatingStudentImg, StudentImg, TextureUrl } from "src/static";
import { answersToOptions, answerToOption } from "src/utils/react-select";
import { mediaQueries } from "src/utils/responsive";
import { useDeviceSize } from "src/utils/useDeviceSize";

import AssetPage from "../AssetPage";
import { BodyText } from "../NamePage";

const CURRENT_GRADUATING_YEAR = 2021;
const NEXT_GRADUATING_YEAR = 2022;

const Grad: React.FC<TStorybookPageProps> = (props) => {
  const {
    responses: { short_grad_level, short_grad_year },
    saveResponses,
  } = useResponse();
  const { onTurnNext } = useStorybookContext();

  const { value: gradLevel, setValue: setGradLevel } = short_grad_level;
  const { value: gradYear, setValue: setGradYear } = short_grad_year;

  const article =
    gradLevel === "undergraduate" || gradLevel === "other" ? "an" : "a";

  const hasGradContent =
    (gradLevel !== "" && GRADUATING_YEAR_OPTIONS.includes(gradYear)) ||
    gradLevel === "other";

  const goToNextStep = () => {
    if (hasGradContent && location.pathname == RouteName.SHORT_ANSWER_GRAD) {
      onTurnNext();
    }
  };

  const { isMobile } = useSiteContext();

  return (
    <>
      <AssetPage {...props}>
        {" "}
        {hasGradContent ? (
          <InkTransition>
            <TexturedAsset texture={TextureUrl}>
              <GradAssetWrapper isMobile={isMobile}>
                {gradYear === CURRENT_GRADUATING_YEAR ||
                gradYear === NEXT_GRADUATING_YEAR ? (
                  <GraduatingStudentImg />
                ) : (
                  <StudentImg />
                )}
              </GradAssetWrapper>
            </TexturedAsset>
          </InkTransition>
        ) : null}
      </AssetPage>
      <ShortAnswerPage {...props}>
        <StyledShortAnswerQuestion
          showButton={hasGradContent}
          buttonText={`Continue`}
        >
          <div tw="flex flex-row gap-x-20 ">
            <BodyText>I am {article}</BodyText>
            <GradTypeDropdown
              shortAnswer
              placeholder="level of study"
              value={gradLevel ? answerToOption(gradLevel) : undefined}
              onChange={(option: OptionTypeBase) => setGradLevel(option.value)}
              options={answersToOptions(LEVEL_OF_STUDY_OPTIONS)}
              onEnter={goToNextStep}
              tabIndex={0}
            />
          </div>
          {gradLevel !== "other" ? (
            <>
              <BodyText>student and will be</BodyText>
              <Spacer height={20} />
              <div tw="flex flex-row gap-x-20">
                <BodyText>graduating in</BodyText>
                <GradYearDropdown
                  shortAnswer
                  placeholder="year"
                  value={gradYear ? answerToOption(gradYear) : undefined}
                  onChange={(option: OptionTypeBase) =>
                    setGradYear(option.value)
                  }
                  options={answersToOptions(GRADUATING_YEAR_OPTIONS)}
                  onEnter={goToNextStep}
                  tabIndex={0}
                />
              </div>
            </>
          ) : (
            <BodyText>student.</BodyText>
          )}
        </StyledShortAnswerQuestion>
        {isMobile && <StorybookPaginator />}
      </ShortAnswerPage>
    </>
  );
};
const StyledShortAnswerQuestion = styled(ShortAnswerQuestion)`
  max-width: 100%;
`;

const GradTypeDropdown = styled(DropdownTagInput)`
  width: 340px;
  ${mediaQueries.largeMobile} {
    width: 200px;
  }
`;

const GradYearDropdown = styled(DropdownTagInput)`
  width: 200px;
  ${mediaQueries.largeMobile} {
    width: 150px;
  }
`;

export const ShortAnswerMobileSVGStyles = css`
  ${mediaQueries.largeMobile} {
    width: 375px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

const GradAssetWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ isMobile }) =>
    isMobile &&
    `
    transform: translateX(25%) translateY(-8%) scale(0.9);
    max-width: 400px;
    margin-left: auto;
  `}
  ${ShortAnswerMobileSVGStyles}
`;

export default Grad;
