import React from "react";
import styled, { css } from "styled-components";

import { Button, TButtonProps, Icon } from "src/components";
import { mediaQueries } from "src/utils/responsive";

const CardContainer = styled(Button)<{
  selected: boolean;
  isLoading?: boolean;
}>`
  background: ${({ selected, theme }) =>
    selected ? theme.color.primary.blue3 : theme.color.white};
  border: 2px solid ${({ theme }) => theme.color.primary.blue3};
  border-radius: 8px;
  box-sizing: border-box;
  width: 324px;
  height: 155px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &,
  &:focus {
    cursor: ${({ isLoading }) => (isLoading ? "wait" : "pointer")};
  }

  &:hover,
  &:focus {
    opacity: 1;
  }

  ${mediaQueries.largeMobile} {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 118px;
    width: 148px;
  }
`;

const SharedCardSelectorStyles = css`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
`;

const CardSelectorBox = styled.div`
  ${SharedCardSelectorStyles}
  background: ${({ theme }) => theme.color.white};
  border: 2px solid ${({ theme }) => theme.color.primary.blue3};
`;

const CardSelectorIcon = styled(Icon)`
  ${SharedCardSelectorStyles}
`;

export type TSelectCardProps = {
  selected: boolean;
  isLoading?: boolean;
} & TButtonProps;

const SelectCard: React.FC<TSelectCardProps> = ({
  selected,
  children,
  ...rest
}) => {
  return (
    <CardContainer selected={selected} {...rest}>
      {selected ? (
        <CardSelectorIcon name="blueCheckmark" size="100%" />
      ) : (
        <CardSelectorBox />
      )}
      {children}
    </CardContainer>
  );
};

export default SelectCard;
