/** ============================ */
/*  This file was autogenerated  */
/*  ✨ with @graphql-codegen ✨  */
/* ============================= */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention */
import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../services/api/types.generated";

import { ResponseFragmentFragment } from "./responseFragment.generated";
import { ResponseFragmentFragmentDoc } from "./responseFragment.generated";

const defaultOptions = {};
export type CreateResponseMutationVariables = Types.Exact<{
  createData: Types.ClaimCreateInput;
}>;

export type CreateResponseMutation = { __typename?: "Mutation" } & {
  createClaim: { __typename?: "Claim" } & ResponseFragmentFragment;
};

export const CreateResponseDocument = gql`
  mutation CreateResponse($createData: ClaimCreateInput!) {
    createClaim(data: $createData) {
      ...ResponseFragment
    }
  }
  ${ResponseFragmentFragmentDoc}
`;

/**
 * __useCreateResponseMutation__
 *
 * To run a mutation, you first call `useCreateResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResponseMutation, { data, loading, error }] = useCreateResponseMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useCreateResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateResponseMutation,
    CreateResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateResponseMutation,
    CreateResponseMutationVariables
  >(CreateResponseDocument, options);
}
export type CreateResponseMutationHookResult = ReturnType<
  typeof useCreateResponseMutation
>;
export type CreateResponseMutationResult =
  ApolloReactCommon.MutationResult<CreateResponseMutation>;
export type CreateResponseMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateResponseMutation,
    CreateResponseMutationVariables
  >;
