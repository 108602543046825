import Bugsnag from "@bugsnag/js";
import { useAuth } from "@hackthenorth/north";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useNavigate } from "react-router";

import { successToast, errorToast } from "src/components";
import { BaseRoute, RouteName } from "src/constants/route";
import {
  APPS_PIPELINE_SLUG,
  IS_EARLY_SUBMISSION,
} from "src/services/api/constants";
import { IS_PRODUCTION } from "src/utils/env";
import { getFieldAnswer } from "src/utils/hackerapi";
import { useLocalStorage } from "src/utils/useLocalStorage";

import { useUserContext } from "../UserContext";

import {
  RESPONSES_KEY,
  VISITED_KEY,
  VERSION_KEY,
  QuestionName,
  questionsConfig,
  TValueForQuestion,
  TQuestionValues,
  PipelineStage,
  OWNER_ID,
  defaultResponseValuesSaved,
  NEW_APPLICATION_DEFAULTS,
  TQuestionConfigEntry,
} from "./config";
import { useCreateResponseMutation } from "./graphql/createResponses.generated";
import {
  GetResponseQueryResult,
  useGetResponseQuery,
} from "./graphql/getResponses.generated";
import { useUpdateResponseMutation } from "./graphql/updateResponses.generated";

const hackerAppsPipelineId = IS_PRODUCTION ? 1446 : 1457; // todo: we need to get the hackerapps pipeline on production pls

// transforms hackerapi claim into a shape we store in state
function claimToState(
  claim: Exclude<GetResponseQueryResult["data"], undefined>["claims"][0]
) {
  return {
    visitedSteps: getFieldAnswer(claim.fields, QuestionName.VISITED_STEPS),
    responseValues: Object.keys(questionsConfig).reduce(
      (acc, key) => ({ ...acc, [key]: getFieldAnswer(claim.fields, key) }),
      {}
    ) as TQuestionValues,
    versionNumber: getFieldAnswer(claim.fields, QuestionName.VERSION_NUMBER),
    ownerId: claim.user.id,
  };
}

// transforms stored state into a hackerapi claim shape
function stateToClaimAnswers(
  visitedSteps: RouteName[],
  responseValues: TQuestionValues,
  versionNumber: number
) {
  return [
    ...Object.entries(responseValues).map(([questionName, responseValue]) => ({
      slug: questionName,
      answer: responseValue,
    })),
    {
      slug: QuestionName.VISITED_STEPS,
      answer: visitedSteps,
    },
    {
      slug: QuestionName.VERSION_NUMBER,
      answer: versionNumber,
    },
  ];
}

/**
 * Handles all the logic of saving claims to the valid locations (localStorage and/or hackerapi).
 * ALL usages of claim data should go through this hook so that usage is agnostic of the actual
 * storage location (i.e. treat it just like a React state variable, do not think about where to store it)
 */
function useApplicationClaim() {
  const { token } = useAuth();
  const { logOut } = useUserContext();
  const navigate = useNavigate();
  const { data, loading, error, refetch } = useGetResponseQuery({
    variables: {
      myId: token?.id ?? 0,
      applicationPipelineSlug: APPS_PIPELINE_SLUG,
    },
    skip: !token,
  });
  const claim = data?.claims[0];
  const [createResponse] = useCreateResponseMutation();
  const [updateResponse] = useUpdateResponseMutation();

  const load = useRef(false);
  const [isSavingResponses, setSavingResponses] = useState(false);
  const [isSubmittingResponses, setSubmittingResponses] = useState(false);

  // NOTE: Undefined represents the claim has not been fetched
  const [isResponsesSubmitted, setResponsesSubmitted] = useState<boolean>();

  const [visitedSteps, setVisitedSteps] = useLocalStorage<RouteName[]>(
    VISITED_KEY,
    NEW_APPLICATION_DEFAULTS.visitedSteps
  );
  const [responseValues, setResponseValues] = useLocalStorage(
    RESPONSES_KEY,
    NEW_APPLICATION_DEFAULTS.responseValues
  );
  const [responseValuesSaved, setResponseValuesSaved] = useState(
    NEW_APPLICATION_DEFAULTS.responseValuesSaved
  );
  const [version, setVersion] = useLocalStorage(
    VERSION_KEY,
    NEW_APPLICATION_DEFAULTS.versionNumber
  ); // default value must not be greater than any possible claim version number, otherwise localstorage will always overwrite hackerapi on new sessions
  const [ownerId, setOwnerId] = useLocalStorage<number | null>(
    OWNER_ID,
    NEW_APPLICATION_DEFAULTS.ownerId
  );

  const saveResponses = useCallback(
    async (hideNotification?: boolean) => {
      console.debug(`Saving responses of user ${ownerId}, version ${version}`);
      setSavingResponses(true);
      try {
        // TODO: Change this error message to include a link
        if (!claim?.id) {
          throw new Error("Failed to save application.");
        }
        const claimData = stateToClaimAnswers(
          visitedSteps,
          responseValues,
          version
        );
        const { errors } = await updateResponse({
          variables: { updatedData: { id: claim.id, answers: claimData } },
        });
        if (errors) {
          throw new Error("Failed to save application.");
        }

        if (!hideNotification) {
          successToast("Application saved");
        }
        setResponseValuesSaved(defaultResponseValuesSaved(true)); // all responses saved at once
        return true;
      } catch (e) {
        if (!hideNotification) {
          Bugsnag.notify(`${e.message} for user ${ownerId}`);
          console.error(e.message);

          if (claim?.id == undefined) {
            errorToast(e.message, "login");
          } else {
            errorToast(e.message);
          }
        }

        return false;
      } finally {
        setSavingResponses(false);
      }
    },
    [claim, responseValues, visitedSteps, version, updateResponse, ownerId]
  );

  const submitResponses = async () => {
    console.debug(`Submitting responses`);
    setSubmittingResponses(true);
    try {
      // TODO: Change this error message to include a link
      if (!claim?.id) {
        throw new Error("Failed to submit application.");
      }
      const savedSuccessfully = await saveResponses(true);

      if (!savedSuccessfully) {
        throw new Error("Failed to submit application.");
      }

      const { errors } = await updateResponse({
        variables: {
          updatedData: {
            id: claim.id,
            stage_slug: IS_EARLY_SUBMISSION
              ? PipelineStage.SUBMITTED_EARLY
              : PipelineStage.SUBMITTED,
          },
        },
      });
      if (errors) {
        throw new Error("Failed to submit application.");
      }

      setResponsesSubmitted(true);
      successToast(`Application submitted 🎉`);

      return true;
    } catch (e) {
      Bugsnag.notify(`${e.message} for user ${ownerId}`);
      console.error(e.message);

      if (claim?.id === undefined) {
        errorToast(e.message, "login");
      } else {
        errorToast(e.message);
      }

      return false;
    } finally {
      setSubmittingResponses(false);
    }
  };

  const resetResponses = () => {
    logOut();
    unstable_batchedUpdates(() => {
      setVisitedSteps(NEW_APPLICATION_DEFAULTS.visitedSteps);
      setResponseValues(NEW_APPLICATION_DEFAULTS.responseValues);
      setResponseValuesSaved(NEW_APPLICATION_DEFAULTS.responseValuesSaved);
      setVersion(NEW_APPLICATION_DEFAULTS.versionNumber);
      setOwnerId(NEW_APPLICATION_DEFAULTS.ownerId);
    });

    navigate(BaseRoute.LANDING);
  };

  const onResponseValuesChanged = (keys: QuestionName[]) => {
    setVersion(Date.now());
    setResponseValuesSaved((prevState) => ({
      ...prevState,
      ...keys.reduce((acc, cur) => ({ ...acc, [cur]: false }), {}),
    }));
  };

  const setResponseValue =
    <T extends QuestionName>(questionName: T) =>
    (value: React.SetStateAction<TValueForQuestion<T>>) => {
      setResponseValues((prevState) => ({
        ...prevState,
        [questionName]:
          typeof value === "function"
            ? (value as (p: TValueForQuestion<T>) => TValueForQuestion<T>)(
                prevState[questionName as QuestionName]
              )
            : value,
      }));
      onResponseValuesChanged([questionName]);
    };

  const updateResponseValues = (newValuesMap: Partial<TQuestionValues>) => {
    setResponseValues((prevState) => ({ ...prevState, ...newValuesMap }));
    onResponseValuesChanged(Object.keys(newValuesMap) as QuestionName[]);
  };

  /**
   * On transition from (not logged in) -> (logged in), determine which
   * copy of response data (localstorage or backend hapi) should be kept and
   * used as the source of truth.
   */
  useEffect(() => {
    if (token && !load.current && !loading) {
      console.debug(`Initial load complete`);
      load.current = true;
      if (error) {
        errorToast(
          "An error occurred while fetching your application.",
          "support"
        );
        Bugsnag.notify(
          `An error occurred while fetching your application for user ${ownerId}`
        );

        return;
      }
      if (claim) {
        console.debug(
          `Remote data fetched, attempting resolution of local and remote`
        );
        const {
          visitedSteps: claimVisitedSteps,
          responseValues: claimResponseValues,
          versionNumber: claimVersion,
          ownerId: claimOwnerId, // this SHOULD technically be equivalent to token.id
        } = claimToState(claim);

        console.debug(`Local data:`, {
          visitedSteps,
          responseValues,
          version,
          ownerId,
        });

        console.debug(`Remote data:`, claimToState(claim));

        const isResponsesSubmitted =
          claim.stage.slug !== PipelineStage.IN_PROGRESS;

        // always prefer "newer" data over "older" (local) data unless newer data is related to older
        const shouldOverwriteLocal =
          isResponsesSubmitted ||
          ownerId === null || // local data not owned by anyone
          ownerId !== claimOwnerId || // local data is unrelated
          (ownerId === claimOwnerId && claimVersion >= version); // remote data is newer

        if (shouldOverwriteLocal) {
          console.debug(
            `Overwriting local data of user ${ownerId}, version ${version} with remote data of user ${claimOwnerId}, version ${claimVersion}`
          );
          // keep claim data
          unstable_batchedUpdates(() => {
            setVisitedSteps(claimVisitedSteps);
            setResponseValues(claimResponseValues);
            setVersion(claimVersion);
            setOwnerId(claimOwnerId);
            setResponsesSubmitted(
              // don't directly check if stage is submitted because it could be in other stages (e.g. accepted)
              claim.stage.slug !== PipelineStage.IN_PROGRESS
            );
          });
        } else {
          console.debug(
            `Overwriting remote data of user ${claimOwnerId}, version ${claimVersion} with local data of user ${ownerId}, version ${version}`
          );
          saveResponses();
          setResponsesSubmitted(
            visitedSteps.includes(RouteName.APPLICATION_SUBMITTED)
          );
        }
      } else {
        const newClaimVersionNumber = Date.now();
        console.debug(
          `No remote data found for ${token.id}, creating a new claim associated with local data of version ${newClaimVersionNumber}`
        );
        // no claim, create a new one for them
        const newClaimData = stateToClaimAnswers(
          visitedSteps,
          responseValues,
          newClaimVersionNumber
        );
        createResponse({
          variables: {
            createData: {
              name: `${token.name}'s Hacker Application (${token.id})`,
              pipeline_id: hackerAppsPipelineId,
              stage_slug: PipelineStage.IN_PROGRESS,
              answers: newClaimData,
            },
          },
        }).then(({ errors }) => {
          if (errors) {
            errorToast(
              `Application creation failed with errors: ${errors}`,
              "support"
            );
            Bugsnag.notify(
              `Application creation failed with errors: ${errors}`
            );
          } else {
            successToast(`Application started 🎉`);
            // update the data fetched by getResponses query to indicate there is now a corresponding claim in backend for this data
            refetch();
            load.current = false;
          }
        });
      }
      console.debug(`loading be done`);
    }
    if (!token && !load.current && !loading)
      console.debug(`No token found, using localstorage only`);
  }, [
    refetch,
    error,
    token,
    claim,
    loading,
    createResponse,
    responseValues,
    saveResponses,
    setResponseValues,
    setVersion,
    version,
    visitedSteps,
    setVisitedSteps,
    ownerId,
    setOwnerId,
  ]);

  return {
    isFetchingResponses: loading,
    isSavingResponses,
    isSubmittingResponses,
    isResponsesSubmitted,
    visitedSteps,
    responseValues,
    responseValuesSaved,

    updateResponseValues,
    setVisitedSteps,
    setResponseValue,
    saveResponses,
    submitResponses,
    resetResponses,
  };
}

/**
 * CONTEXT
 */
type TQuestionState<N extends QuestionName> = {
  value: TValueForQuestion<N>;
  /**
   * Whether the newest question value has been saved in HAPI
   */
  saved: boolean;
  /**
   * List of descriptions of errors relating to this question. Calculated by the
   * `validator` function in question configuration.
   */
  errors: string[];
  /**
   * Whether any errors exist for this question. Based on the `errors` field.
   */
  valid: boolean;
  /**
   * Options for this question, as specified in the question config.
   */
  config: TQuestionConfigEntry;
  setValue: (v: React.SetStateAction<TValueForQuestion<N>>) => void;
};

type TResponseContextValue = {
  /**
   * Whether the application is currently being fetched.
   */
  isFetchingResponses: boolean;
  /**
   * Whether the application is currently being saved.
   */
  isSavingResponses: boolean;
  /**
   * Whether the application is currently being submitted.
   */
  isSubmittingResponses: boolean;
  /**
   * Whether the application claim is in SUBMITTED stage (if so, it should not be editable).
   */
  isApplicationSubmitted: boolean | undefined;
  /**
   * Contains value, metadata, and a setter for each question in the config, keyed by the
   * question name (which is the same as the field slug).
   */
  responses: {
    [K in keyof typeof questionsConfig]: TQuestionState<K>;
  };

  /**
   * Allows you to update multiple responses at the same time. Provide an object
   * mapping question names to values.
   */
  updateResponseValues: ReturnType<
    typeof useApplicationClaim
  >["updateResponseValues"];
  /**
   * Saves responses to HAPI. Returns whether or not the save succeeded.
   */
  saveResponses: ReturnType<typeof useApplicationClaim>["saveResponses"];
  /**
   * Saves responses to HAPI and marks the application as submitted.  Returns whether or not the submit succeeded.
   */
  submitResponses: ReturnType<typeof useApplicationClaim>["submitResponses"];
  /**
   * Resets all state to their initial defaults. Can be used when restarting application.
   */
  resetResponses: ReturnType<typeof useApplicationClaim>["resetResponses"];
};

// some of the values in context are passed only as helpers to SiteContext and should not be used elsewhere
type TResponseContextValueWithSiteContextHelpers = TResponseContextValue & {
  visitedSteps: ReturnType<typeof useApplicationClaim>["visitedSteps"];
  setVisitedSteps: ReturnType<typeof useApplicationClaim>["setVisitedSteps"];
};

export const ResponseContext: React.Context<TResponseContextValueWithSiteContextHelpers> =
  createContext(
    undefined as unknown as TResponseContextValueWithSiteContextHelpers
  );

export function ResponseContextProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const {
    isFetchingResponses,
    isSavingResponses,
    isSubmittingResponses,
    isResponsesSubmitted,
    visitedSteps,
    responseValues,
    responseValuesSaved,
    updateResponseValues,
    setResponseValue,
    setVisitedSteps,
    saveResponses,
    submitResponses,
    resetResponses,
  } = useApplicationClaim();

  const { isAuthenticated } = useUserContext();

  const responses = Object.entries(responseValues).reduce(
    (acc, [key, value]) => {
      const configEntry = questionsConfig[key as QuestionName];
      const errors = configEntry?.validator?.(value) ?? [];
      acc[key] = {
        value,
        errors,
        saved: responseValuesSaved[key],
        valid: errors.length === 0,
        config: configEntry,
        setValue: setResponseValue(key as QuestionName),
      };
      return acc;
    },
    {}
  ) as TResponseContextValue["responses"];

  /**
   * Explaination:
   * State 1: Loading (undefined) - When the user is logged in and we're still fetching the claim
   * State 2: Submitted (true)
   *    - When the user is not logged in but has been to "/submitted" page
   *    - When the user is logged in, we've finished loading and the claim is in a submitted stage
   * State 3: Not Submitted (false)
   *    - When the user is not logged in and hasn't been to "/submitted" page
   *    - When the user is logged in, we've finished loading and the claim is not in a submitted stage
   *
   */
  const isApplicationSubmitted =
    isAuthenticated && isResponsesSubmitted === undefined
      ? undefined
      : (!isAuthenticated &&
          visitedSteps.includes(RouteName.APPLICATION_SUBMITTED)) ||
        (isAuthenticated && isResponsesSubmitted === true)
      ? true
      : false;

  return (
    <ResponseContext.Provider
      value={{
        isFetchingResponses,
        isSavingResponses,
        isSubmittingResponses,
        isApplicationSubmitted,

        responses,
        updateResponseValues,
        saveResponses,
        submitResponses,
        resetResponses,

        // 😤 for SiteContext use only 👮
        visitedSteps,
        setVisitedSteps,
      }}
    >
      {children}
    </ResponseContext.Provider>
  );
}

export const useResponse = (): TResponseContextValue => {
  const value = useContext(ResponseContext);
  if (value === undefined) {
    throw new Error(
      "`useResponse` hook used outside ResponseContext. Did you forget to wrap your app with a ResponseProvider?"
    );
  }
  const { setVisitedSteps, visitedSteps, ...rest } = value;
  return rest;
};
