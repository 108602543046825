import React, { ComponentPropsWithoutRef } from "react";
import tw from "twin.macro";

type TAssetPageProps = ComponentPropsWithoutRef<"div">;

const BlankPage: React.FC<TAssetPageProps> = ({ children, style, ...rest }) => {
  return <div style={{ background: "#F6F4F2", ...style }} {...rest}></div>;
};

export default BlankPage;
