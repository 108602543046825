import React from "react";
import toast, { Toaster } from "react-hot-toast";
import "twin.macro";

import { useUserContext } from "src/contexts";
import { sharedTheme } from "src/theme/shared";

/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */

export const successToast = (message: string) => toast.success(message);

export const errorToast = (message: string, resolution?: "login" | "support") =>
  toast.error(() => {
    const { logIn } = useUserContext();

    return (
      <div tw="px-4">
        <p tw="m-0 mb-4">{message}</p>
        {resolution === "login" ? (
          <p tw="m-0">
            Please{" "}
            <a
              tw="font-bold cursor-pointer hover:(underline)"
              onClick={() => logIn()}
              role="button"
              tabIndex={0}
            >
              login
            </a>{" "}
            to continue.
          </p>
        ) : resolution === "support" ? (
          <p tw="m-0">
            Please contact{" "}
            <a
              tw="text-secondary-red font-bold cursor-pointer no-underline hover:(underline)"
              href="mailto:hello@hackthenorth.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@hackthenorth.com
            </a>
          </p>
        ) : null}
      </div>
    );
  });

const Toast = () => (
  <Toaster
    position="top-right"
    toastOptions={{
      success: {
        style: {
          padding: "12px 24px",
          backgroundColor: sharedTheme.color.toast.green,
          fontFamily: sharedTheme.fontFamily.body,
          fontSize: sharedTheme.fontSize.body,
          color: sharedTheme.color.secondary.green,
          border: `1px solid ${sharedTheme.color.secondary.green}`,
          maxWidth: "500px",
        },
        iconTheme: {
          primary: sharedTheme.color.secondary.green,
          secondary: sharedTheme.color.white,
        },
      },
      error: {
        style: {
          padding: "12px 24px",
          backgroundColor: sharedTheme.color.toast.red,
          fontFamily: sharedTheme.fontFamily.body,
          fontSize: sharedTheme.fontSize.body,
          color: sharedTheme.color.secondary.red,
          border: `1px solid ${sharedTheme.color.secondary.red}`,
          maxWidth: "500px",
        },
        iconTheme: {
          primary: sharedTheme.color.secondary.red,
          secondary: sharedTheme.color.white,
        },
      },
    }}
  />
);
export default Toast;
