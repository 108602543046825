import { JWTRole, useAuth } from "@hackthenorth/north";
import React, { createContext, useContext, useMemo, useEffect } from "react";

import { client } from "src/services/api/client";
import { EVENT_SLUG } from "src/services/api/constants";

import { useGetMeLazyQuery } from "./graphql/getMe.generated";

export type TInfo = {
  name: string;
};

type TUserContextState = {
  id: number | null;
  info: TInfo | null;
  role: JWTRole | null;
  isAuthenticated: boolean;

  logOut: () => void;
  logIn: () => void;
  signUp: () => void;
  error?: string;
};

const DEFAULT_STATE: TUserContextState = {
  id: null,
  role: null,
  info: null,
  isAuthenticated: false,

  logOut: () => {},
  logIn: () => {},
  signUp: () => {},
};

const UserContext: React.Context<TUserContextState> =
  createContext(DEFAULT_STATE);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider: React.FC = ({ children }) => {
  const { token, logOut: authLogOut, loginUrl, signupUrl } = useAuth();
  const [getMe, { data, error }] = useGetMeLazyQuery();

  // wait to fetch the user since the query will not re-run even if token changes
  useEffect(() => {
    if (token) getMe();
  }, [token, getMe]);

  /**
   * Build UserState
   */

  const id: TUserContextState["id"] = useMemo(() => token?.id ?? null, [token]);

  const role: TUserContextState["role"] = useMemo(
    () => (token?.roles[EVENT_SLUG] ?? [])[0] ?? null,
    [token]
  );

  const isAuthenticated: TUserContextState["isAuthenticated"] = useMemo(
    () => !!token,
    [token]
  );

  const info: TUserContextState["info"] = useMemo(
    () =>
      data && isAuthenticated
        ? {
            name: data.me.name,
          }
        : null,
    [data, isAuthenticated]
  );

  const logOut: TUserContextState["logOut"] = () => {
    authLogOut();
    client.cache.reset();
  };

  const logIn: TUserContextState["logIn"] = () => {
    window.location.href = loginUrl();
  };

  const signUp: TUserContextState["signUp"] = () => {
    window.location.href = signupUrl();
  };

  /**
   * Build state
   */
  const userState: TUserContextState = {
    id,
    info,
    role,
    isAuthenticated,
    logOut,
    logIn,
    signUp,
    error: error?.message,
  };

  return (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  );
};
