import React, { useMemo } from "react";
import { styled } from "twin.macro";

import { CustomBoat } from "src/components";
import { mobileBoatBob } from "src/constants/animations";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import {
  STORYBOOK_SIZE,
  TStorybookPageProps,
} from "src/contexts/StorybookContext/Storybook/constants";

const AssetPage: React.FC<TStorybookPageProps> = ({
  offset,
  children,
  style,
  ...rest
}) => {
  const { isMobile } = useSiteContext();
  const {
    responses: {
      boat_custom_color,
      boat_custom_expression,
      boat_custom_accessory,
    },
    updateResponseValues,
  } = useResponse();

  const pageStyle = useMemo(
    () =>
      isMobile
        ? {
            position: "absolute" as const,
            width: "100%",
            height: "50vh",
            transform: "translateY(-50vh)",
          }
        : {
            background: "#FFFCFC",
            width: STORYBOOK_SIZE.width / 2 - (offset || 0) * 2,
            height: STORYBOOK_SIZE.height,
          },
    [isMobile, offset]
  );
  return (
    <div style={{ ...style, ...pageStyle }} {...rest}>
      {isMobile && (
        <MobileBoatWrapper>
          <CustomBoat
            color={boat_custom_color?.value || "pink"}
            expression={boat_custom_expression?.value || "smile"}
            accessory={boat_custom_accessory?.value || "flag"}
          />
        </MobileBoatWrapper>
      )}
      {children}
    </div>
  );
};

const MobileBoatWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: -5px;
  left: -15px;
  z-index: 10;
  animation: ${mobileBoatBob} 8s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
`;

export default AssetPage;
