import React, { ComponentPropsWithoutRef } from "react";
import tw, { styled } from "twin.macro";

import { Button, Spacer, Text } from "src/components";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import { mediaQueries } from "src/utils/responsive";

export type TShortAnswerQuestionProps = ComponentPropsWithoutRef<"div"> & {
  /**
   * text to show on the primary button on Short Answer Question Page
   */
  buttonText: string;
  /**
   * determines whether or not to show Continue Button
   */
  showButton: boolean;
  /**
   * this would be the text, input and any other components to be shown
   * above the action buttons
   */
  children: React.ReactNode;
};

export default function ShortAnswerQuestion({
  buttonText,
  showButton,
  children,
  ...rest
}: TShortAnswerQuestionProps) {
  const { isMobile } = useSiteContext();

  const { onTurnNext } = useStorybookContext();

  return (
    <Container {...rest}>
      {children}
      {!isMobile ? (
        <>
          <ButtonWrapper css={[!showButton && tw`invisible`]}>
            <>
              <EnterText>Press ENTER or</EnterText>
              <Spacer width="16px" />
            </>
            <MaxWidthButton onClick={() => onTurnNext()}>
              <EllipsisText>{buttonText}</EllipsisText>
            </MaxWidthButton>
          </ButtonWrapper>
          <Spacer height="36px" />
        </>
      ) : null}
    </Container>
  );
}

const Container = styled.div<React.HTMLProps<HTMLDivElement>>`
  width: 100%;
  max-width: 512px;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  align-items: center;
  transition: opacity height 250ms;
  margin-top: 68px;
  display: flex;
  height: auto;
  opacity: 1;
  overflow: auto;
  justify-content: flex-end;
  ${mediaQueries.tablet} {
    margin-top: 42px;
    margin-bottom: 24px;
  }
`;
const EnterText = styled(Text)`
  margin-left: 16px;
`;

const MaxWidthButton = styled(Button)`
  max-width: 250px;
  overflow: hidden;

  ${mediaQueries.tablet} {
    padding: 14px 42px;
  }

  ${mediaQueries.largeMobile} {
    padding: 7px 36px;
  }
`;

const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.fontSize.h2D}px;
  }

  ${mediaQueries.largeMobile} {
    font-size: ${({ theme }) => theme.fontSize.h3D}px;
  }
`;
