import React from "react";
import { styled } from "twin.macro";

import Flex from "src/components/base/Flex";
import Text from "src/components/base/Text";
import { useUserContext } from "src/contexts";
import { deviceBreakpoints, mediaQueries } from "src/utils/responsive";

import Button from "../base/Button";
import Spacer from "../base/Spacer";

export const Authenticate = () => {
  const { logIn, signUp } = useUserContext();
  return (
    <Background>
      <WhiteContainer>
        <div tw="h-full w-full flex flex-col justify-evenly items-center lm:justify-start gap-y-28">
          <Spacer height="48px" />
          <HeaderContainer tw="mx-16">
            <HeaderText>Let&apos;s start by creating an account.</HeaderText>
          </HeaderContainer>

          <Button tw="leading-loose" onClick={() => signUp()}>
            Sign Up
          </Button>
          <Flex row>
            <InformationText>Already have an account?</InformationText>
            <Button link tw="leading-loose" onClick={() => logIn()}>
              Log in
            </Button>
          </Flex>
          <Spacer height="48px" />
        </div>
      </WhiteContainer>
    </Background>
  );
};

const InformationText = styled(Text)`
  font-size: 16px;
  line-height: 180%;
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

const TextContainer = styled(Flex)`
  max-width: 600px;
  text-align: center;
`;

const HeaderText = styled(Text)`
  color: ${({ theme }) => theme.color.text.blue};
  line-height: 120%;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontSize.h1D}px;
  ${mediaQueries.largeMobile} {
    font-size: ${({ theme }) => theme.fontSize.h3M}px;
  }
`;

const HeaderContainer = styled.div`
  max-width: 500px;
  ${mediaQueries.largeMobile} {
    max-width: 300px;
  }
`;

const StyledButton = styled(Button)`
  height: 60px;
  margin-bottom: 15px;
`;

export const WhiteContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  height: 80%;
  position: relative;
  padding-top: 32px;
  min-width: 80%;
  max-width: ${deviceBreakpoints.medium}px;
  border-radius: 2%;
`;

export const ChildrenWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
`;

export const Background = styled.div`
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
`;
