import React, { ComponentPropsWithRef } from "react";
import styled from "styled-components";

import { useStorybookContext } from "../index";

type TMobileWrapperProps = ComponentPropsWithRef<"div">;

const MobileWrapper: React.FC<TMobileWrapperProps> = ({ children }) => {
  const { curPage, pageToChildIndex } = useStorybookContext();

  const question = children ? children[pageToChildIndex(curPage)] : <div></div>;

  return <MobileContainer>{question}</MobileContainer>;
};

const MobileContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export default MobileWrapper;
