import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
  useMemo,
  ComponentPropsWithoutRef,
} from "react";
import { useLocation } from "react-router";

import { RouteName } from "src/constants/route";

import { useResponse } from "../ResponseContext/ResponseContext";
import { useSiteContext } from "../SiteContext";
import { useUserContext } from "../UserContext";

import MobileWrapper from "./MobileWrapper";
import Storybook from "./Storybook";
import { STORYBOOK_SIZE, COVER_SIZE } from "./Storybook/constants";

type TStorybookContextState = {
  /**
   * The current page for the flipbook
   */
  curPage: number;
  numPages: number;
  pageToChildIndex: (pg: number) => number;
  onTurnNext: () => void;
  onTurnPrev: () => void;
  turnToQuestion: (q: RouteName) => void;
};

const DEFAULT_STATE: TStorybookContextState = {
  curPage: 1,
  numPages: 1,
  pageToChildIndex: (pg: number) => 0,
  onTurnNext: () => console.error("No parent StorybookContextProvider found!"),
  onTurnPrev: () => console.error("No parent StorybookContextProvider found!"),
  turnToQuestion: (q: RouteName) =>
    console.error("No parent StorybookContextProvider found!"),
};

const StorybookContext: React.Context<TStorybookContextState> =
  createContext(DEFAULT_STATE);

export const useStorybookContext = () => useContext(StorybookContext);

/**
 * skipFirstPage is for skipping the blank pages at the start of the storybook.
 * Blank pages are needed since the cover and content pages are different.
 * see https://puu.sh/HTShc/8645af63c6.png, this essentially "glues" the back of the cover to the first page
 *
 * Similarly, disableLastPage will make it so that the user can't turn to the empty pages
 * that are between the last question and back cover.
 */
type TProps = ComponentPropsWithoutRef<"div"> & {
  routes?: RouteName[];
  skipFirstPage?: boolean;
  disableLastPage?: boolean;
  // enabling certain properties for the Application Submitted component
  appSubmittedBook?: boolean;
};

export const StorybookContextProvider: React.FC<TProps> = ({
  routes = [],
  skipFirstPage = false,
  disableLastPage = false,
  appSubmittedBook = false,
  children: rawChildren,
  ...rest
}) => {
  const {
    responses: { short_grad_level },
    saveResponses,
  } = useResponse();
  const $ = (window as any).$;
  const ref = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const { navigate, isMobile } = useSiteContext();
  const { isAuthenticated } = useUserContext();

  /**
   * Because the turn.js instance is only initialized once, we need to avoid
   * the `when.turned` function forming a closure around the value of `navigate`
   * at the time of initialization. If that happens, the `navigate` call inside `when.turned`
   * will always have `visitedSteps` (and any other values) set to whatever the value was at
   * initialization which causes unexpected behaviour; we want the most up-to-date value always.
   *
   * Since ref.current always refers to the same value in memory, it "breaks" out of the scope of
   * the closure to always reference the most updated value of `navigate`.
   */
  const navigateRef = useRef(navigate);
  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  const mounted = useRef(false);

  const MIN_PAGE = 1;
  const MAX_PAGE = 20;
  const [curPage, setCurPage] = useState<number>(
    appSubmittedBook ? 2 : MIN_PAGE
  );
  const numPages = MAX_PAGE;

  // conditionally rendered elements that don't display are still counted a children
  // so we need to filter them out
  const children = React.Children.toArray(rawChildren).filter(Boolean);

  // this component operates under the assumption that the children in
  // `stages/ShortAnswerStage` are laid out as such:
  // front_pg / [img1_pg, q1_pg] / ... / [imgn_pg, qn_pg] / back_pg
  // so each element in between is a fragment containing two pages
  const pageToChildIndex = (pg: number) => {
    const index = pg - 1;
    if (index == 0) return 0;
    if (index == numPages - 1) children.length - 1;
    return 1 + Math.floor((index - 1) / 2);
  };

  const safeSetCurPage = (newPage: number) => {
    if (newPage < MIN_PAGE) {
      newPage = MIN_PAGE;
    }
    if (newPage > MAX_PAGE) {
      newPage = MAX_PAGE;
    }
    setCurPage(newPage);
  };

  useEffect(() => {
    // Prevents storybook component from initializing more than once
    if (ref && ref.current && $ && !$(ref.current).turn("page") && !isMobile) {
      $(ref.current).turn({
        width: "100%",
        height: "100%",
        // display: "double",
        page: appSubmittedBook ? 2 : 1,
        autoCenter: true,
      });
    }
  }, [ref, routes]);

  useEffect(() => {
    const routeIndex = routes.indexOf(location.pathname as RouteName);
    const isRouteFound = routeIndex != -1;

    if (isRouteFound) {
      safeSetCurPage(routeIndex + 1);
    }
  }, [location.pathname, ref, $]);

  useEffect(() => {
    if (ref && ref.current && $) {
      $(ref.current).turn("page", curPage);
    }

    if (!appSubmittedBook) {
      // update the current route to reflect curPage
      const targetRoute = routes[curPage - 1];
      const routeIndex = routes.indexOf(targetRoute);
      const isRouteFound = routeIndex != -1;
      const isCurrentRoute =
        routeIndex === routes.indexOf(location.pathname as RouteName);

      if (mounted && mounted.current && isRouteFound && !isCurrentRoute) {
        navigateRef.current(targetRoute);
      }
    }

    if (mounted && !mounted.current) {
      mounted.current = true;
    }
    // update the current route to reflect curPage
  }, [curPage, $]);

  const onTurnNext = () => {
    if (appSubmittedBook) {
      safeSetCurPage(4);
      return;
    }
    saveResponses(!isAuthenticated).then((savedSuccessfully) => {
      if (!isAuthenticated || savedSuccessfully) {
        const isLastPage = numPages - curPage <= 4;

        const isPageBeforeSchool = curPage >= 10 && curPage < 12;
        const isPageBeforeGrad = curPage >= 8 && curPage < 10;

        if (isPageBeforeGrad && short_grad_level.value === "other") {
          turnToQuestion(RouteName.SHORT_ANSWER_LOCATION);
          return; // do the actual routing next render
        }
        if (isPageBeforeSchool && short_grad_level.value === "high school") {
          turnToQuestion(RouteName.SHORT_ANSWER_LOCATION);
          return; // do the actual routing next render
        }

        if (isLastPage) {
          navigate(RouteName.CREATE_ACCOUNT);
          return;
        }

        if (skipFirstPage && curPage == 1 && numPages >= 4) {
          bufferBlankPage();
          safeSetCurPage(4);
        } else if (!(disableLastPage && isLastPage)) {
          safeSetCurPage(curPage + 2);
        }
      }
    });
  };

  // TODO: Rewrite
  const onTurnPrev = () => {
    saveResponses(!isAuthenticated).then((savedSuccessfully) => {
      if (!isAuthenticated || savedSuccessfully) {
        const isPageAfterSchool = curPage >= 14 && curPage < 16;

        if (isPageAfterSchool && short_grad_level.value == "high school") {
          turnToQuestion(RouteName.SHORT_ANSWER_SCHOOL);
          return; // do the actual routing next render
        }

        if (isPageAfterSchool && short_grad_level.value == "other") {
          turnToQuestion(RouteName.SHORT_ANSWER_GRAD);
          return; // do the actual routing next render
        }

        if (skipFirstPage && Math.floor(curPage / 2) == 2 && numPages >= 1) {
          bufferBlankPage();
          safeSetCurPage(1);
        } else {
          safeSetCurPage(curPage - 2);
        }
      }
    });
  };

  // if the passed in route name is part of storybook, turn to that page
  const turnToQuestion = (q: RouteName) => {
    const routeIndex = routes.indexOf(q);
    const isRouteFound = routeIndex != -1;
    const isRouteEqual = routeIndex == curPage;

    if (isRouteFound && !isRouteEqual) {
      bufferBlankPage();
      safeSetCurPage(routeIndex + 1);
    }
  };

  const bufferBlankPage = () => {
    if (ref && ref.current && $) {
      $(ref.current).turn("page", 2);
    }
  };

  /**
   * Build state
   */
  const state: TStorybookContextState = {
    curPage,
    numPages,
    pageToChildIndex,
    onTurnPrev,
    onTurnNext,
    turnToQuestion,
  };

  const Wrapper = isMobile ? MobileWrapper : Storybook;

  return (
    <StorybookContext.Provider value={state}>
      <Wrapper ref={ref} hidePaginator={appSubmittedBook} {...rest}>
        {children}
      </Wrapper>
    </StorybookContext.Provider>
  );
};

export { STORYBOOK_SIZE, COVER_SIZE };
