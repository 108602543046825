import { RouteName } from "src/constants/route";
import { isValidUrl } from "src/utils/links";

// Note: must be the same as the field slugs in hackerapi
export enum QuestionName {
  FULL_NAME = "short_name",
  SCHOOL_NAME = "short_school",
  GRAD_YEAR = "short_grad_year",
  GRAD_LEVEL = "short_grad_level",
  PROGRAM = "short_program",
  LOCATION = "short_location",
  HACKATHON_COUNT = "short_hackathon_count",
  VISITED_STEPS = "visited_steps",
  VERSION_NUMBER = "version_number",
  BOAT_COLOR = "boat_custom_color",
  BOAT_EXPRESSION = "boat_custom_expression",
  BOAT_ACCESSORY = "boat_custom_accessory",
  LONG_DESIRE = "long_desire",
  LONG_COLLABORATION = "long_collaboration",
  LONG_OBSTACLE = "long_obstacle",
  LONG_LINKS = "long_links",
  LONG_HACKER_TYPE = "long_hacker_type",
  GENDER = "survey_gender",
  RACE = "survey_race",
  ETHNICITY = "survey_ethnicity",
  EDUCATION = "survey_education",
  PERSONAL_EXPERIENCE = "survey_personal_experience",
  FAMILY_EXPERIENCE = "survey_family_experience",
  GROUPS = "survey_groups",
  UNDERREP_GROUPS = "survey_underrep_groups",
  TECH_PREFERENCES = "survey_tech_preferences",
  WORKSHOP_PREFERENCES = "survey_workshop_preferences",
}

export const shortAnswerRouteToResponse = {
  [RouteName.SHORT_ANSWER_NAME]: "short_name" as const,
  [RouteName.SHORT_ANSWER_SCHOOL]: "short_school" as const,
  [RouteName.SHORT_ANSWER_GRAD]: ["short_grad_level", "short_grad_year"],
  [RouteName.SHORT_ANSWER_PROGRAM]: "short_program" as const,
  [RouteName.SHORT_ANSWER_EXPERIENCE]: "short_hackathon_count" as const,
  [RouteName.SHORT_ANSWER_LOCATION]: "short_location" as const,
};

/**
 * Update this to track a new question in ResponseContext
 * Note: only questions specified in this config will be tracked by responseValues.
 */
export const questionsConfig = {
  [QuestionName.FULL_NAME]: configEntry({
    name: "Name",
    type: "string",
    required: true,
  }),
  [QuestionName.SCHOOL_NAME]: configEntry({
    name: "School",
    type: "string",
    required: true,
  }),
  [QuestionName.GRAD_YEAR]: configEntry({
    name: "Grad year",
    type: "number",
    required: true,
  }),
  [QuestionName.GRAD_LEVEL]: configEntry({
    name: "Grad level",
    type: "string",
    required: true,
  }),
  [QuestionName.LOCATION]: configEntry({
    name: "Location",
    type: "string",
    required: true,
  }),
  [QuestionName.HACKATHON_COUNT]: configEntry({
    name: "Hackathon Count",
    type: "string",
    required: true,
  }),
  [QuestionName.PROGRAM]: configEntry({
    name: "Program",
    type: "string",
    required: true,
  }),
  [QuestionName.BOAT_COLOR]: configEntry({
    type: "string",
    required: true,
    defaultValue: "pink",
  }),
  [QuestionName.BOAT_EXPRESSION]: configEntry({
    type: "string",
    required: true,
    defaultValue: "smile",
  }),
  [QuestionName.BOAT_ACCESSORY]: configEntry({
    type: "string",
    required: true,
    defaultValue: "flag",
  }),
  [QuestionName.LONG_DESIRE]: configEntry({
    type: "string",
    required: true,
    max: 500,
  }),
  [QuestionName.LONG_COLLABORATION]: configEntry({
    type: "string",
    required: true,
    max: 1200,
  }),
  [QuestionName.LONG_OBSTACLE]: configEntry({
    type: "string",
    required: true,
    max: 1200,
  }),
  [QuestionName.LONG_LINKS]: configEntry({
    type: "links",
    name: "links",
  }),
  [QuestionName.LONG_HACKER_TYPE]: configEntry({
    type: "list",
    required: true,
  }),
  [QuestionName.GENDER]: configEntry({
    type: "list",
  }),
  [QuestionName.RACE]: configEntry({
    type: "list",
  }),
  [QuestionName.ETHNICITY]: configEntry({
    type: "list",
  }),
  [QuestionName.EDUCATION]: configEntry({
    type: "boolean",
  }),
  [QuestionName.PERSONAL_EXPERIENCE]: configEntry({
    type: "string",
  }),
  [QuestionName.FAMILY_EXPERIENCE]: configEntry({
    type: "boolean",
  }),
  [QuestionName.GROUPS]: configEntry({
    type: "list",
  }),
  [QuestionName.UNDERREP_GROUPS]: configEntry({
    type: "string",
  }),
  [QuestionName.TECH_PREFERENCES]: configEntry({
    type: "list",
  }),
  [QuestionName.WORKSHOP_PREFERENCES]: configEntry({
    type: "list",
  }),
};

export const RESPONSES_KEY = "_htn2021-application-responses";
export const VISITED_KEY = "_htn2021-application-visited_steps";
export const VERSION_KEY = "_htn2021-application_version";
export const OWNER_ID = "_htn2021-application_owner";

export const PipelineStage = {
  IN_PROGRESS: "in_progress",
  SUBMITTED_EARLY: "submitted_early",
  SUBMITTED: "submitted_application",
};

/**
 * UTILITY TYPEZ
 */
type TValueForQuestionType<T> = T extends "number"
  ? number
  : T extends "string"
  ? string
  : T extends "list" | "links"
  ? string[]
  : T extends "boolean"
  ? boolean | null
  : unknown;

type TQuestionType = "boolean" | "string" | "list" | "number" | "links";

export type TQuestionConfigEntry<T extends TQuestionType = TQuestionType> = {
  /**
   * Friendly descriptive name of the field to be displayed in errors.
   * E.g. name="School of study"
   */
  name?: string;
  /**
   * Format of the question's answer.
   */
  type: T;
  /**
   * Whether or not a question is required for a valid application. Required questions will
   * automatically validate the presence of the answer value, and will include an error stating
   * the question is required if an answer is not present.
   */
  required: boolean;
  /**
   * Maximum characters or items in an answer. Only applies to string and list types. Questions
   * with a max value will automatically include an error stating the maximum has been reached
   * if the answer value exceeds the max value.
   */
  max?: number;
  /**
   * Check if a question's value is valid.
   * @returns list of error strings to be displayed in UI, if any.
   */
  validator: (value: TValueForQuestionType<T>) => string[];
  /**
   * Default value in a new set of application responses. Overrides
   * the default default value. Default default default, default default.
   */
  defaultValue?: TValueForQuestionType<T>;
};

// Allows typescript to correctly infer types from the `type` field, and provides defaults
function configEntry<T extends TQuestionType>(
  config: Pick<TQuestionConfigEntry<T>, "type" | "name"> &
    Partial<TQuestionConfigEntry<T>>
): TQuestionConfigEntry<T> {
  return {
    required: false,
    ...config,
    validator: (value) => [
      ...(config.validator?.(value) ?? []),
      ...(config.required &&
      ((config.type == "string" && !value) ||
        (config.type == "list" && !(value as string[]).length))
        ? [`${config.name ?? "This field"} is required.`]
        : []),
      ...(config.name == "links"
        ? (value as string[]).map((link) =>
            isValidUrl(link) ? "" : "Invalid URL"
          )
        : []),
      ...(config.max &&
      ["string", "list"].includes(config.type) &&
      (value as string | string[]).length > config.max
        ? [`You've reached the limit for ${config.name ?? "this field"}.`]
        : []),
    ],
  };
}

export type TValueForQuestion<N extends QuestionName> = TValueForQuestionType<
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  typeof questionsConfig[N]["type"]
>;

export type TQuestionValues = {
  [K in keyof typeof questionsConfig]: TValueForQuestion<K>;
};

function getDefaultValueForQuestionType(type: TQuestionType) {
  switch (type) {
    case "boolean":
      return null;
    case "string":
      return "";
    case "list":
      return [];
    case "number":
      return 0;
    case "links":
      return ["", "", ""];
    default:
      throw new Error(`Received unknown value ${type} for question type!`);
  }
}

export function defaultResponseValuesSaved(saved: boolean) {
  return Object.keys(questionsConfig).reduce((acc, key) => {
    acc[key] = saved;
    return acc;
  }, {}) as {
    [K in keyof typeof questionsConfig]: boolean;
  };
}

export const NEW_APPLICATION_DEFAULTS = {
  visitedSteps: [],
  responseValues: Object.entries(questionsConfig).reduce(
    (acc, [curKey, { type, defaultValue }]) => {
      acc[curKey] = defaultValue ?? getDefaultValueForQuestionType(type);
      return acc;
    },
    {}
  ) as TQuestionValues,
  responseValuesSaved: defaultResponseValuesSaved(false),
  versionNumber: -1,
  ownerId: null,
};
