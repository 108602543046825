import React, { useState, Fragment } from "react";
import tw, { styled } from "twin.macro";

import { ExclamationIcon } from "src/components";
import { TagInput, Flex, Spacer, Text, TextAreaInput } from "src/components";
import { HACKER_TYPES, LONG_ANSWER_TITLES } from "src/constants/copy";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { isValidUrl } from "src/utils/links";

import TextInput from "../TextInput";

import FullReviewSection from "./FullReviewSection";

const Title = styled(Text).attrs({
  heading: true,
  size: "h2",
  element: "h3",
})`
  color: ${({ theme }) => theme.color.primary.blue1};
`;

const LinkTitle = styled(Text).attrs({
  element: "h3",
})`
  color: ${({ theme }) => theme.color.primary.blue1};
  width: 140px;
`;

const Answer = styled(Text).attrs({
  heading: true,
  size: "h3",
})`
  word-break: break-word;
  color: ${({ theme }) => theme.color.primary.blue2};
`;

const PlatformLinkText = styled(Text)`
  color: ${({ theme }) => theme.color.text.blue};
  font-family: ${({ theme }) => theme.fontFamily.body};
  line-height: 33px;
  font-weight: 500;
  font-size: 18;
  margin-right: 15px;
`;

const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.color.primary.blue2};
  margin-right: 15px;
`;

const StatusText = styled(Text)<{ error: boolean }>`
  color: ${({ theme, error }) =>
    error ? theme.color.secondary.red : theme.color.secondary.green};
`;

const FullReviewLongAnswer = () => {
  const { isMobile } = useSiteContext();

  const {
    responses: {
      long_desire,
      long_collaboration,
      long_obstacle,
      long_links,
      long_hacker_type,
    },
    updateResponseValues,
    saveResponses,
  } = useResponse();

  const [didFocus, setDidFocus] = useState({
    links: false,
    hacker_type: false,
  });
  const [inputSelected, setInputSelected] = useState("");
  const [tempDesire, setTempDesire] = useState(long_desire.value);
  const [tempCollaboration, setTempCollaboration] = useState(
    long_collaboration.value
  );
  const [tempObstacle, setTempObstacle] = useState(long_obstacle.value);
  const [tempLinks, setTempLinks] = useState(long_links.value);
  const [tempHackerType, setTempHackerType] = useState(long_hacker_type.value);

  const [desireErrors, setDesireErrors] = useState<string[]>([]);
  const [collaborationErrors, setCollaborationErrors] = useState<string[]>([]);
  const [obstacleErrors, setObstacleErrors] = useState<string[]>([]);

  const [tempLinksInvalid, setTempLinksInvalid] = useState(
    new Array(long_links.value.length).fill(false)
  );

  return (
    <>
      <FullReviewSection
        title="QUESTION 1"
        onSave={() => {
          long_desire.setValue(tempDesire);
          saveResponses();
        }}
        onCancel={() => {
          setDesireErrors([]);
          setTempDesire(long_desire.value);
        }}
        saveDisabled={desireErrors.length > 0}
      >
        {(isEditing: boolean) => (
          <Flex column>
            <Text heading size={isMobile ? "h4M" : "h3D"}>
              {LONG_ANSWER_TITLES[0]}
            </Text>
            <Spacer height={24} />
            {isEditing ? (
              <div>
                {desireErrors[0] === "This field is required." && (
                  <Flex align="center">
                    <ExclamationIcon />
                    <StatusText error={true}>{desireErrors[0]}</StatusText>
                  </Flex>
                )}
                <TextAreaInput
                  placeholder="Type your answer here..."
                  charLimit={500}
                  errors={desireErrors}
                  value={tempDesire ?? ""}
                  didEdit={true}
                  onChange={(e: any) => {
                    if (e.target.value.length > 500) {
                      setDesireErrors([
                        "You've reached the limit for this field.",
                      ]);
                    } else if (e.target.value.length <= 0) {
                      setDesireErrors(["This field is required."]);
                    } else {
                      setDesireErrors([]);
                    }
                    setTempDesire(e.target.value);
                  }}
                  isSelected={inputSelected == "desire"}
                  onBlur={() => setInputSelected("")}
                  onFocus={() => setInputSelected("desire")}
                />
              </div>
            ) : (
              <Answer>{long_desire.value}</Answer>
            )}
          </Flex>
        )}
      </FullReviewSection>
      <FullReviewSection
        title="QUESTION 2"
        onSave={() => {
          long_collaboration.setValue(tempCollaboration);
          saveResponses();
        }}
        onCancel={() => {
          setCollaborationErrors([]);
          setTempCollaboration(long_collaboration.value);
        }}
        saveDisabled={collaborationErrors.length > 0}
      >
        {(isEditing: boolean) => (
          <Flex column>
            <Text heading size={isMobile ? "h4M" : "h3D"}>
              {LONG_ANSWER_TITLES[1]}
            </Text>
            <Spacer height={24} />
            {isEditing ? (
              <div>
                {collaborationErrors[0] === "This field is required." && (
                  <Flex align="center">
                    <ExclamationIcon />
                    <StatusText error={true}>
                      {collaborationErrors[0]}
                    </StatusText>
                  </Flex>
                )}
                <TextAreaInput
                  placeholder="Type your answer here..."
                  charLimit={1200}
                  errors={collaborationErrors}
                  value={tempCollaboration ?? ""}
                  didEdit={true}
                  onChange={(e: any) => {
                    if (e.target.value.length > 1200) {
                      setCollaborationErrors([
                        "You've reached the limit for this field.",
                      ]);
                    } else if (e.target.value.length <= 0) {
                      setCollaborationErrors(["This field is required."]);
                    } else {
                      setCollaborationErrors([]);
                    }
                    setTempCollaboration(e.target.value);
                  }}
                  isSelected={inputSelected == "collaboration"}
                  onBlur={() => setInputSelected("")}
                  onFocus={() => setInputSelected("collaboration")}
                />
              </div>
            ) : (
              <Answer>{long_collaboration.value}</Answer>
            )}
          </Flex>
        )}
      </FullReviewSection>
      <FullReviewSection
        title="QUESTION 3"
        onSave={() => {
          long_obstacle.setValue(tempObstacle);
          saveResponses();
        }}
        onCancel={() => {
          setObstacleErrors([]);
          setTempObstacle(long_obstacle.value);
        }}
        saveDisabled={obstacleErrors.length > 0}
      >
        {(isEditing: boolean) => (
          <Flex column>
            <Text heading size={isMobile ? "h4M" : "h3D"}>
              {LONG_ANSWER_TITLES[2]}
            </Text>
            <Spacer height={24} />
            {isEditing ? (
              <div>
                {obstacleErrors[0] === "This field is required." && (
                  <Flex align="center">
                    <ExclamationIcon />
                    <StatusText error={true}>{obstacleErrors[0]}</StatusText>
                  </Flex>
                )}
                <TextAreaInput
                  placeholder="Type your answer here..."
                  charLimit={1200}
                  errors={obstacleErrors}
                  value={tempObstacle ?? ""}
                  didEdit={true}
                  onChange={(e: any) => {
                    if (e.target.value.length > 1200) {
                      setObstacleErrors([
                        "You've reached the limit for this field.",
                      ]);
                    } else if (e.target.value.length <= 0) {
                      setObstacleErrors(["This field is required."]);
                    } else {
                      setObstacleErrors([]);
                    }
                    setTempObstacle(e.target.value);
                  }}
                  isSelected={inputSelected == "obstacle"}
                  onBlur={() => setInputSelected("")}
                  onFocus={() => setInputSelected("obstacle")}
                />
              </div>
            ) : (
              <Answer>{long_obstacle.value}</Answer>
            )}
          </Flex>
        )}
      </FullReviewSection>
      <FullReviewSection
        title="QUESTION 4"
        onSave={() => {
          long_hacker_type.setValue(tempHackerType);
          saveResponses();
        }}
        onCancel={() => setTempHackerType(long_hacker_type.value)}
        saveDisabled={tempHackerType.length === 0}
      >
        {(isEditing: boolean) => (
          <Flex column>
            <Text heading size={isMobile ? "h4M" : "h3D"}>
              {LONG_ANSWER_TITLES[3]}
            </Text>
            <Spacer height={10} />
            {isEditing ? (
              <>
                <Flex align="center">
                  <Subtitle>Select at least one.</Subtitle>
                  {tempHackerType.length === 0 && (
                    <Flex align="center">
                      <ExclamationIcon />
                      <StatusText error={true}>Required</StatusText>
                    </Flex>
                  )}
                </Flex>
                <Spacer height={10} />
                <TagInput
                  multi
                  options={HACKER_TYPES}
                  selectedOptions={tempHackerType}
                  setSelectedOptions={(newSelection) => {
                    setTempHackerType(newSelection);
                  }}
                />
              </>
            ) : (
              <Answer>{long_hacker_type.value?.join(", ")}</Answer>
            )}
          </Flex>
        )}
      </FullReviewSection>
      <FullReviewSection
        title="QUESTION 5"
        onSave={() => {
          tempLinks.forEach((link, i) => {
            if (tempLinksInvalid[i]) {
              tempLinksInvalid[i] = false;
              tempLinks[i] = long_links.value[i];
            }
          });
          const cleanLinks = [
            ...tempLinks.slice(0, 3),
            ...tempLinks.slice(3).filter((link) => link !== ""),
          ];
          setTempLinks(cleanLinks);
          long_links.setValue(cleanLinks);
          saveResponses();
        }}
        onCancel={() => {
          setTempLinks(long_links.value);
        }}
        saveDisabled={
          tempLinksInvalid.filter((validity) => validity).length !== 0
        }
      >
        {(isEditing: boolean) => (
          <Flex column>
            <Text heading size={isMobile ? "h4M" : "h3D"}>
              {LONG_ANSWER_TITLES[4]}
            </Text>
            <Spacer height={24} />
            {!long_links.value.join("") && !isEditing && (
              <Answer>No links provided</Answer>
            )}
            {long_links.value.map((link, i) => {
              const error = tempLinksInvalid[i];

              return (
                <Fragment key={`${link}-${i}`}>
                  {isEditing ? (
                    <>
                      <Flex align="center" style={{ marginBottom: "16px" }}>
                        <Title style={{ marginRight: "16px" }}>
                          {i === 0
                            ? "Github:"
                            : i === 1
                            ? "LinkedIn:"
                            : i === 2
                            ? "Devpost:"
                            : "Other:"}
                        </Title>
                        <Flex>
                          {error && <ExclamationIcon />}
                          <StatusText error={error}>
                            {error ? "Invalid URL" : ""}
                          </StatusText>
                        </Flex>
                      </Flex>
                      <TextInput
                        placeholder="Please specify the URL"
                        value={tempLinks[i] ?? ""}
                        onChange={(e: any) => {
                          const newLinks = [...tempLinks];
                          newLinks[i] = e.target.value;
                          setTempLinks(newLinks);
                          const newInvalid = [...tempLinksInvalid];
                          newInvalid[i] = !isValidUrl(e.target.value);
                          setTempLinksInvalid(newInvalid);
                        }}
                      />
                      {i === long_links.value.length - 1 && (
                        <Text
                          link
                          tw="text-left"
                          onClick={() => {
                            let newLinks = [...long_links.value];
                            newLinks.push("");
                            long_links.setValue(newLinks);
                            newLinks = [...tempLinks];
                            newLinks.push("");
                            setTempLinks(newLinks);
                            const newLinksInvalid = [...tempLinksInvalid];
                            newLinksInvalid.push(false);
                            setTempLinksInvalid(newLinksInvalid);
                          }}
                        >
                          + Add another link
                        </Text>
                      )}
                    </>
                  ) : (
                    <>
                      {link !== "" && (
                        <>
                          <Flex align="center">
                            <LinkTitle>
                              {i === 0
                                ? "Github"
                                : i === 1
                                ? "LinkedIn"
                                : i === 2
                                ? "Devpost"
                                : i === 3
                                ? "Other"
                                : ""}
                            </LinkTitle>
                            <Answer>{link}</Answer>
                          </Flex>
                          {i < 3 && <Spacer height={24} />}
                        </>
                      )}
                    </>
                  )}
                </Fragment>
              );
            })}
          </Flex>
        )}
      </FullReviewSection>
    </>
  );
};

export default FullReviewLongAnswer;
