import React, { useMemo } from "react";
import styled from "styled-components";

import { Flex, Spacer, Text } from "src/components";
import { ExclamationIcon } from "src/components";

type TTextAreaInputProps = Omit<
  React.ComponentPropsWithRef<"textarea">,
  "value"
> & {
  charLimit?: number;
  errors?: string[];
  value: string;
  didEdit?: boolean;
  isSelected?: boolean;
};

const DEFAULT_CHARACTER_LIMIT = 1000;

const StyledTextArea = styled.textarea<{
  isSelected: boolean;
  hasError: boolean;
}>`
  display: inline-block;
  outline: none;
  border: 0px;
  margin-top: 20px;
  line-height: 1.5;
  padding: 15px;
  resize: vertical;
  width: 100%;
  height: 40vh;
  max-height: 40vh;
  border-radius: 16px;
  border: ${({ theme, hasError, isSelected }) =>
    hasError
      ? `1px solid ${theme.color.secondary.red}`
      : isSelected
      ? `3px solid ${theme.color.primary.blue3}`
      : `1px solid #006691`};
  font-weight: normal;
  color: ${({ theme }) => theme.color.text.grey};
  font-size: ${({ theme }) => theme.fontSize.body}px;
  font-family: ${({ theme }) => theme.fontFamily.body};

  &::placeholder {
    opacity: 0.6;
  }
`;

const Footer = styled.div<{ overCharLimit: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ overCharLimit }) =>
    overCharLimit ? "space-between" : "flex-end"};
`;

const StyledText = styled(Text)<{ error: boolean }>`
  align-self: flex-end;
  color: ${({ theme, error }) =>
    error ? theme.color.secondary.red : theme.color.primary.blue2};
  font-size: ${({ theme }) => theme.fontSize.body}px;
`;

const TextAreaInput: React.FC<TTextAreaInputProps> = ({
  charLimit = DEFAULT_CHARACTER_LIMIT,
  value,
  errors = [],
  didEdit = false,
  isSelected = false,
  ...rest
}) => {
  const hasError = useMemo(
    () =>
      errors.includes("You've reached the limit for this field.") ||
      (didEdit && errors.includes("This field is required.")),
    [didEdit, errors]
  );

  const overCharLimit = useMemo(
    () => errors.includes("You've reached the limit for this field."),
    [errors]
  );

  return (
    <Flex column>
      <StyledTextArea
        value={value}
        isSelected={isSelected}
        hasError={hasError}
        {...rest}
      />
      <Spacer height={16} />
      <Footer overCharLimit={overCharLimit}>
        {overCharLimit && (
          <Flex align="center">
            <ExclamationIcon />
            <StyledText error>
              Sorry! You&apos;ve reached the character limit
            </StyledText>
          </Flex>
        )}
        <StyledText error={hasError}>
          {value.length}/{charLimit} characters
        </StyledText>
      </Footer>
    </Flex>
  );
};

export default TextAreaInput;
