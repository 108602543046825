import download from "downloadjs";
import htmlToCanvas from "html2canvas";
import React, {
  ComponentPropsWithoutRef,
  useCallback,
  useContext,
} from "react";
import tw, { styled } from "twin.macro";

import { Link, Button, Spacer } from "src/components";
import { BaseRoute } from "src/constants/route";
import { useSiteContext } from "src/contexts";
import { ResponseContext } from "src/contexts/ResponseContext/ResponseContext";
import { TextureUrl } from "src/static";

type Props = ComponentPropsWithoutRef<"div">;

const SubmittedPage: React.FC<Props> = ({ children, ...rest }) => {
  const { resetResponses } = useContext(ResponseContext);
  const { isMobile, navigate } = useSiteContext();

  const handleDownloadButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    saveScene();
  };

  const saveScene = useCallback(() => {
    htmlToCanvas(document.querySelector("#boat-container") as HTMLElement, {
      scale: 1,
      scrollY: -window.scrollY,
      // ignoreElements: (elem) => elem.id === "scene-shadow",
    }).then((dataUrl) => download(dataUrl.toDataURL(), "my-boat.png"));
  }, []);

  const Wrapper = isMobile ? MobileWrapper : CoverWrapper;

  return (
    <Wrapper {...rest}>
      <ContentWrapper isMobile={isMobile}>
        <h1 tw="z-10">Thanks for applying to Hack the North 2021!</h1>
        {isMobile && (
          <p tw="z-10 italic">
            If you don&apos;t receive a confirmation email within the next 10
            minutes, please reach out to us at{" "}
            <StyledLink
              href="mailto:hello@hackthenorth.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@hackthenorth.com
            </StyledLink>
            .
          </p>
        )}

        <p tw="z-10">
          Sit tight &mdash; our team will be reviewing your application over the
          next few weeks. Decisions will be sent via email in multiple rounds.
          Keep an eye on our social media for updates!
        </p>
        <p tw="z-10">
          Have any questions for us? Check our{" "}
          <StyledLink
            href="https://hackthenorth.com/#faq"
            target="_blank"
            rel="noopener"
          >
            FAQ
          </StyledLink>
          , or email us at{" "}
          <StyledLink
            href="mailto:hello@hackthenorth.com"
            target="_blank"
            rel="noopener"
          >
            hello@hackthenorth.com
          </StyledLink>
          .
        </p>
        <p tw="z-10">
          P.S. download your personalized boat buddy below and share it on
          social media with the hashtag <b>#HackTheNorth</b>!
        </p>
        <ButtonWrapper isMobile={isMobile} tw="z-10">
          <Button
            onClick={handleDownloadButton}
            icon="download"
            leftIcon
            tw="z-10 w-full flex-1 px-16"
          >
            Download my boat
          </Button>
          <Button
            secondary
            onClick={() => navigate(BaseRoute.REVIEW)}
            tw="z-10 w-full flex-1 px-16"
          >
            View my application
          </Button>
        </ButtonWrapper>
        {/* TODO move this to top right, and add social buttons */}
        <NewAppWrapper isMobile={isMobile}>
          <StyledLinkButton
            isMobile={isMobile}
            link
            onClick={() => resetResponses()}
            tw="z-10"
          >
            Log out
          </StyledLinkButton>
        </NewAppWrapper>
        {isMobile && <Spacer height={64} />}
        {!isMobile && <TextureWrapper />}
      </ContentWrapper>
    </Wrapper>
  );
};

const MobileWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 10;

  h1 {
    margin: 0;
    color: ${({ theme }) => theme.color.primary.blue1};
  }

  p {
    font-family: ${({ theme }) => theme.fontFamily.body};
    color: ${({ theme }) => theme.color.primary.blue1};
    line-height: 1.8;
    margin: 12px 0;
  }
  a {
    color: ${({ theme }) => theme.color.primary.blue2};
    font-weight: bold;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.white};
  font-weight: bold;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: ${({ theme }) => theme.color.white};
    width: 0px;
    height: 1px;
    transition: width 250ms;
  }
`;

const StyledLinkButton = styled(Button)<{ isMobile: boolean }>`
  color: ${({ isMobile, theme }) => (isMobile ? undefined : theme.color.white)};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.body}px;
  font-weight: bold;

  margin-top: 2em;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: ${({ theme }) => theme.color.white};
    width: 0px;
    height: 2px;
    transition: width 250ms;
  }
`;

const CoverWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.color.white};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background: linear-gradient(
    90deg,
    #4777a2 0%,
    #5583ab 92.96%,
    #3a678e 93.16%,
    #3a678e 100%
  );
  width: 100%;
  height: 100%;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.h1D}px;
    margin-top: 60px;
  }

  p {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.body}px;
    margin: 12px 0;
  }

  .subheading {
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: ${({ theme }) => theme.fontSize.h3D}px;
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

const TextureWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background: url(${TextureUrl}),
    linear-gradient(
      90deg,
      #4777a2 0%,
      #5583ab 92.96%,
      #3a678e 93.16%,
      #3a678e 100%
    );
  background-blend-mode: screen;
  opacity: 12%;
`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? "0 24px" : "16px 96px 64px 64px")};
  align-items: flex-start;
  z-index: 10;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #b3c0cf;
`;

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  row-gap: 16px;
  column-gap: 16px;
  align-items: center;
  margin-top: 16px;
`;

const NewAppWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-end")};
`;

export default SubmittedPage;
