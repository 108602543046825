import React from "react";
import styled from "styled-components";

import {
  ShortAnswerQuestion,
  ShortAnswerPage,
  Text,
  GeoCoder,
  InkTransition,
  Spacer,
  TexturedAsset,
} from "src/components";
import { RouteName } from "src/constants/route";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import StorybookPaginator from "src/contexts/StorybookContext/Paginator";
import { TStorybookPageProps } from "src/contexts/StorybookContext/Storybook/constants";
import { LocationImg, TextureUrl } from "src/static";
import { answerToOption } from "src/utils/react-select";

import AssetPage from "../AssetPage";
import { ShortAnswerMobileSVGStyles } from "../GradPage";
import { BodyText } from "../NamePage";

const LocationAssetWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  ${({ isMobile }) =>
    isMobile &&
    `
    transform: translateX(25%) translateY(-8%) scale(0.9);
    max-width: 400px;
    margin-left: auto;
  `}
  ${ShortAnswerMobileSVGStyles}
`;

const Location: React.FC<TStorybookPageProps> = (props) => {
  const { isMobile } = useSiteContext();
  const {
    responses: { short_location },
    saveResponses,
  } = useResponse();
  const { value, setValue } = short_location;
  const { onTurnNext } = useStorybookContext();
  const hasContent = value !== null && value !== "" && value !== undefined;

  return (
    <>
      <AssetPage {...props}>
        {hasContent ? (
          <InkTransition>
            <TexturedAsset texture={TextureUrl}>
              <LocationAssetWrapper isMobile={isMobile}>
                {/* fix bottom placement */}
                <LocationImg />
              </LocationAssetWrapper>
            </TexturedAsset>
          </InkTransition>
        ) : null}
      </AssetPage>
      <ShortAnswerPage {...props}>
        <ShortAnswerQuestion showButton={hasContent} buttonText="Welcome">
          <BodyText>And I will be journeying to Hack the North from</BodyText>
          {isMobile ? <Spacer height={24} /> : <Spacer height={46} />}

          <GeoCoder
            shortAnswer
            focusOnFlip
            route={RouteName.SHORT_ANSWER_LOCATION}
            placeholder="My city"
            value={value ? answerToOption(value) : undefined}
            onChange={(e: any) => setValue(e.value)}
            onKeyDown={(e: any) => {
              if (
                e.key === "Enter" &&
                hasContent &&
                location.pathname == RouteName.SHORT_ANSWER_LOCATION
              ) {
                onTurnNext();
              }
            }}
            tabIndex={0}
          />
        </ShortAnswerQuestion>
        {isMobile && <StorybookPaginator />}
      </ShortAnswerPage>
    </>
  );
};

export default Location;
