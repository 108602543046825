import { endSpan, startSpan, log } from "@hackthenorth/analytics";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { Routes, Route, Link } from "react-router-dom";
import styled from "styled-components";
import "twin.macro";
import { UAParser } from "ua-parser-js";

import {
  Header,
  Playground,
  NotFound,
  Stars,
  Waves,
  Clouds,
  Toast,
  AboutLink,
} from "src/components";
import { waveMobile } from "src/constants/animations";
import { RouteName } from "src/constants/route";
import { useSiteContext } from "src/contexts/SiteContext";
import {
  ShortAnswerStage,
  CreateAccountStage,
  LongAnswerStage,
  SurveyStage,
  ReviewStage,
  ApplicationSubmittedStage,
} from "src/stages";
import { WaveMobileUrl } from "src/static";

import AlternativeForm from "../AlternativeForm";
import Button from "../base/Button";

const AppContent = () => {
  const { isMobile, shouldIncreasePerformance } = useSiteContext();

  const location = useLocation();
  const analyticsSpan = useRef(startSpan("/", "navigation"));

  useEffect(() => {
    endSpan(analyticsSpan.current);
    analyticsSpan.current = startSpan(location.pathname, "navigation");
  }, [location.pathname]);

  const Wrapper =
    isMobile && !shouldIncreasePerformance
      ? MobileContentWrapper
      : ContentWrapper;

  useEffect(() => {
    const userAgent = new UAParser();

    const deviceType = userAgent.getDevice().type;

    /**
     * Possible device types: console, mobile, tablet, smarttv, wearable, embedded
     * See
     */
    log({
      identifier: deviceType ?? "desktop",
      duration: 0,
      startTime: 0,
      stream: "platform",
    });
  }, []);

  return (
    <Background>
      {shouldIncreasePerformance ? null : (
        <>
          <Stars />
          <Clouds />
          <Header />
          <AboutLink />
          {!isMobile && <Waves />}
        </>
      )}

      <Toast />

      <Wrapper>
        {isMobile && !shouldIncreasePerformance && <MobileWave />}
        <Routes>
          <Route path="/alternative-form" element={<AlternativeForm />} />
          <Route path={RouteName.LANDING} element={<ShortAnswerStage />} />
          <Route path={RouteName.BUILD_A_BOAT} element={<ShortAnswerStage />} />
          <Route
            path={RouteName.SHORT_ANSWER_NAME}
            element={<ShortAnswerStage />}
          />
          <Route
            path={RouteName.SHORT_ANSWER_GRAD}
            element={<ShortAnswerStage />}
          />
          <Route
            path={RouteName.SHORT_ANSWER_SCHOOL}
            element={<ShortAnswerStage />}
          />
          <Route
            path={RouteName.SHORT_ANSWER_PROGRAM}
            element={<ShortAnswerStage />}
          />
          <Route
            path={RouteName.SHORT_ANSWER_LOCATION}
            element={<ShortAnswerStage />}
          />
          <Route
            path={RouteName.SHORT_ANSWER_EXPERIENCE}
            element={<ShortAnswerStage />}
          />
          <Route
            path={RouteName.CREATE_ACCOUNT}
            element={<CreateAccountStage />}
          />
          <Route path={RouteName.LONG_ANSWER} element={<LongAnswerStage />} />
          <Route path={RouteName.SURVEY} element={<SurveyStage />} />
          <Route path={RouteName.REVIEW} element={<ReviewStage />} />
          <Route
            path={RouteName.APPLICATION_SUBMITTED}
            element={<ApplicationSubmittedStage />}
          />
          <Route path={RouteName.PLAYGROUND} element={<Playground />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Wrapper>
    </Background>
  );
};

const Background = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #d3deec 14.43%, #faeded 100%);
  display: flex;
  flex-direction: column;
`;

const MobileContentWrapper = styled.div`
  min-height: 50vh;
  width: 100%;
  top: 50vh;
  position: absolute;
  background: white;
`;

const MobileWave = styled.div`
  background: url(${WaveMobileUrl}) repeat-x;
  position: absolute;
  top: -47px;
  width: 2992px;
  height: 48px;
  animation: ${waveMobile} 8s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  z-index: 10;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
`;

export default AppContent;
