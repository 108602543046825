import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Operation,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getAccessToken } from "@hackthenorth/north";
import { createUploadLink } from "apollo-upload-client";

import { APP_ENV } from "src/utils/env";

import { FieldWithAnswer } from "./types.generated";

const getClientUrl = () =>
  APP_ENV === "production"
    ? "https://api.hackthenorth.com/v3/graphql"
    : "https://staging-api.hackthenorth.com/v3/graphql";

const request = (operation: Operation) => {
  const token = getAccessToken();
  operation.setContext({
    headers: {
      authorization: token ?? "",
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    requestLink,
    createUploadLink({
      uri: getClientUrl(),
    }) as unknown as ApolloLink, // todo: update package when https://github.com/jaydenseric/apollo-upload-client/pull/175 is released
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      FieldWithAnswer: {
        // `FieldWithAnswer`s from different claims have the same `field.id`, but contain different `field.answer`'s so we need to tell the cache they're different
        keyFields: (o) => (o as FieldWithAnswer).answer?.id?.toString(),
      },
    },
  }),
});
