import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { Icon, Text } from "src/components";
import { useSiteContext } from "src/contexts/SiteContext";

export type TFooterProps = ComponentPropsWithoutRef<"div"> & {
  leftContent: React.ReactNode;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  curStage: number;
  totalSteps: number;
  ans: any;
  questionIndexInContext: number;
};

export type TChevronProps = {
  disabled: boolean;
  isUpsideDown?: boolean;
};

const Container = styled.div`
  display: flex;

  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.white};

  width: 100%;
`;

const Chevron = styled(Icon)<TChevronProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transform: ${({ isUpsideDown }) =>
    isUpsideDown ? "rotate(180deg) translateY(1px)" : null};
`;

const ButtonGroup = styled.div`
  display: flex;
  border-radius: 5px;
  margin-left: 16px;
`;

const ArrowButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  border: 2px solid #004d85;
  background-color: ${({ theme }) => theme.color.secondary.highlight};
  cursor: pointer;
  height: 36px;
  width: 45px;

  &:focus-within,
  &:active {
    outline: none;
  }

  &:last-child {
    border-left: none;
    border-radius: 0 5px 5px 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary.red};
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.color.secondary.highlight};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

// this is only used for short and long answers
export default function Footer({
  leftContent,
  goToNextStep,
  goToPrevStep,
  curStage,
  totalSteps,
  ans,
  questionIndexInContext,
  ...rest
}: TFooterProps) {
  const { isMobile } = useSiteContext();
  const { questionsHasError } = { questionsHasError: {} };
  const hasError = questionsHasError[questionIndexInContext];

  const onArrowClick = (direction = "next") => {
    if (direction === "next" && curStage < totalSteps) {
      goToNextStep();
    }
    if (direction === "prev" && curStage > 0) {
      goToPrevStep();
    }
  };

  const isNextArrowDisabled =
    curStage + 1 === totalSteps || ans == null || ans === "" || hasError;
  const isPrevArrowDisabled = curStage === 0;

  return (
    <Container {...rest}>
      <Flex>{leftContent}</Flex>
      <Flex>
        <Text size="body">
          {curStage + 1} of {totalSteps}{" "}
          {!isMobile && (totalSteps === 1 ? "step" : "steps")}
        </Text>
        <ButtonGroup>
          <ArrowButton
            onClick={() => onArrowClick("prev")}
            disabled={isPrevArrowDisabled}
          >
            <Chevron
              name="chevron"
              disabled={isPrevArrowDisabled}
              isUpsideDown
            />
          </ArrowButton>
          <ArrowButton
            onClick={() => onArrowClick("next")}
            disabled={isNextArrowDisabled}
          >
            <Chevron name="chevron" disabled={isNextArrowDisabled} />
          </ArrowButton>
        </ButtonGroup>
      </Flex>
    </Container>
  );
}
