/** ============================ */
/*  This file was autogenerated  */
/*  ✨ with @graphql-codegen ✨  */
/* ============================= */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention */
import { gql } from "@apollo/client";

import * as Types from "../../../../services/api/types.generated";

export type ResponseFragmentFragment = { __typename?: "Claim" } & Pick<
  Types.Claim,
  "id" | "name" | "stage_id"
> & {
    stage: { __typename?: "Stage" } & Pick<Types.Stage, "id" | "slug">;
    user: { __typename?: "User" } & Pick<Types.User, "id">;
    fields: Array<
      { __typename?: "FieldWithAnswer" } & Pick<
        Types.FieldWithAnswer,
        "id" | "slug" | "field_type" | "updated_at"
      > & {
          answer?: Types.Maybe<
            { __typename?: "FieldAnswer" } & Pick<
              Types.FieldAnswer,
              "id" | "value" | "updated_at"
            >
          >;
        }
    >;
  };

export const ResponseFragmentFragmentDoc = gql`
  fragment ResponseFragment on Claim {
    id
    name
    stage_id
    stage {
      id
      slug
    }
    user {
      id
    }
    fields {
      id
      slug
      field_type
      updated_at
      answer {
        id
        value
        updated_at
      }
    }
  }
`;
