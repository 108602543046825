import { OptionTypeBase, OptionsType } from "react-select";

export const answerToOption = (answer: string | number): OptionTypeBase => {
  return { label: answer, value: answer };
};

export const answersToOptions = (
  answers: (string | number)[]
): OptionsType<OptionTypeBase> => answers.map(answerToOption);

export const optionsToAnswers = (options: OptionsType<OptionTypeBase>) =>
  options ? options.map((option) => option.value) : [];
