import classNames from "classnames";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { BaseProps, FeatherIcon } from "./FeatherIcon";

const DEFAULT_CHECK_ICON = "Check";
const DEFAULT_CHECKBOX_SIZE = 24;
const DEFAULT_CHECK_SPACING = 8;

type CheckboxProps = {
  /**
   * Size of the checkbox in `px`. Defaults to `24`.
   */
  size?: number;
  /**
   * Callback for when the checkbox value is changed
   */
  onChange?: (newVal: boolean) => void;
  /**
   * The value of the checkbox (whether it's checked or not)
   */
  checked: boolean;
  /**
   * Set the size of the "check" icon in `px`. Defaults to a value `8px` than the `size` attribute
   */
  checkSize?: number;
  /**
   * Set the color of the "check" icon. Defaults to `black`.
   */
  checkColor?: string;
  /**
   * Set the "check" icon by specifying an icon name. Defaults to `Check`.
   */
  checkIcon?: string;
} & BaseProps<"input">;

const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

const CheckboxContainer = styled.label<{ size?: number; checked: boolean }>`
  position: relative;
  background-color: ${({ theme, checked }) =>
    checked ? theme.color.primary.blue2 : theme.color.white};
  display: flex;
  border: ${({ theme }) => `2px solid ${theme.color.primary.blue2}`};
  border-radius: 5px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size || DEFAULT_CHECKBOX_SIZE}px;
  height: ${({ size }) => size || DEFAULT_CHECKBOX_SIZE}px;
  box-sizing: border-box;
  cursor: pointer;
`;

const CheckBox: React.FC<CheckboxProps> = ({
  className,
  disabled,
  size,
  onChange,
  checked,
  checkColor,
  checkSize,
  checkIcon,
  ...rest
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  const checkboxSize = size || DEFAULT_CHECKBOX_SIZE;

  const onInternalChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      if (onChange) onChange(checked);
    },
    [onChange]
  );

  return (
    <CheckboxContainer
      className={classNames(className, "North--CheckboxInternal", {
        checked,
        focused,
        disabled,
      })}
      checked={checked}
      size={checkboxSize}
    >
      <CheckboxInput
        type="checkbox"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        checked={checked}
        onChange={onInternalChange}
        disabled={disabled}
        {...rest}
      />
      {checked && (
        <FeatherIcon
          className="North--CheckboxIcon"
          name={checkIcon || DEFAULT_CHECK_ICON}
          size={checkSize || checkboxSize - DEFAULT_CHECK_SPACING}
          color={checkColor}
        />
      )}
    </CheckboxContainer>
  );
};

export default CheckBox;
