import { useAuth } from "@hackthenorth/north";
import React, { useState, ComponentPropsWithoutRef } from "react";
import { useNavigate } from "react-router";
import tw, { styled, css } from "twin.macro";

import { Button, Modal, Text } from "src/components";
import { getFurthestVisitedRoute, RouteName } from "src/constants/route";
import { useResponse, useUserContext } from "src/contexts";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import StorybookPaginator from "src/contexts/StorybookContext/Paginator";
import { BoatCutoutImg, TextureUrl } from "src/static";

type Props = ComponentPropsWithoutRef<"div">;

const CoverPage: React.FC<Props> = ({ children, style, ...rest }) => {
  const { isAuthenticated, logIn } = useUserContext();
  const { onTurnNext } = useStorybookContext();
  const { isMobile, visitedSteps, navigate } = useSiteContext();
  const { isApplicationSubmitted, resetResponses } = useResponse(); // todo: show separate copy for after submitted
  const routerNavigate = useNavigate();

  const Wrapper = isMobile ? MobileWrapper : CoverWrapper;

  const [isModalOpen, setModalOpen] = useState(false);

  /* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */
  const [primaryMarkup, primaryAction, secondaryMarkup] = (() => {
    const hasStartedApplication = visitedSteps.includes(
      RouteName.SHORT_ANSWER_NAME
    );

    const secondaryMarkup = isAuthenticated ? (
      <a
        tw="text-white ml-8 cursor-pointer underline hover:(text-grey-hover)"
        onClick={() => resetResponses()}
        role="button"
        tabIndex={0}
      >
        Log out
      </a>
    ) : (
      <>
        Returning applicant?
        <a
          tw="text-white ml-8 cursor-pointer underline hover:(text-grey-hover)"
          onClick={() => logIn()}
          role="button"
          tabIndex={0}
        >
          Log in
        </a>
      </>
    );

    if (hasStartedApplication) {
      if (isApplicationSubmitted) {
        return [
          "View my completed application",
          () => navigate(RouteName.APPLICATION_SUBMITTED),
          secondaryMarkup,
        ] as const;
      }
      return [
        "Continue my application",
        () =>
          navigate(
            getFurthestVisitedRoute(visitedSteps) ?? RouteName.BUILD_A_BOAT
          ),
        secondaryMarkup,
      ] as const;
    }

    if (isMobile) {
      return [
        "Start my application",
        () => setModalOpen(true),
        secondaryMarkup,
      ] as const;
    }

    return ["Start my application", onTurnNext, secondaryMarkup] as const;
  })();
  /* eslint-enable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */

  return (
    <Wrapper style={isMobile ? {} : style} {...rest}>
      <h2>Hack the North 2021</h2>
      {isMobile ? (
        <h1>Application</h1>
      ) : (
        <ApplicationTitle title="Application">Application</ApplicationTitle>
      )}
      {!isMobile && <BoatCutoutImg tw="mt-48 mb-32 z-10" />}
      <p tw="w-3/5 md:w-5/6 text-center z-10">
        Join 3,000+ students from around the globe to build an incredible
        project in just 36 hours.
      </p>
      <Button tw="z-10" onClick={primaryAction}>
        {primaryMarkup}
      </Button>
      <p tw="z-10 mb-8!">{secondaryMarkup}</p>
      {isMobile && !isApplicationSubmitted && (
        <div tw="leading-loose px-8 pb-24 flex flex-col items-center">
          <p tw="my-0!">Having trouble loading?</p>
          <Button link onClick={() => routerNavigate("/alternative-form")}>
            Fill out this form instead.
          </Button>
        </div>
      )}
      {isMobile && <StorybookPaginator tw="z-10" />}
      {!isMobile && <TextureWrapper />}
      <Modal
        isOpen={isModalOpen}
        title="Hey there!"
        onClose={() => setModalOpen(false)}
        actions={<Button onClick={() => onTurnNext()}>Got it</Button>}
        css={[isMobile && tw`min-w-9/10 w-196`]}
        isMobile={isMobile}
      >
        <Text>
          We recommend that you complete this application on a desktop browser
          for the best experience.
        </Text>
      </Modal>
    </Wrapper>
  );
};

export default CoverPage;

const ApplicationTitle = styled.h1`
  position: relative;
  color: #00000075;
  ::after {
    content: attr(title);
    top: 2px;
    left: 2px;
    color: rgb(255, 255, 255);
    position: absolute;
  }
  ::before {
    content: attr(title);
    top: 1px;
    left: 1px;
    color: rgba(255, 255, 255, 0.69);
    position: absolute;
  }
`;

const CoverWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: linear-gradient(
    90deg,
    #3a678e 0%,
    #3a678e 6.84%,
    #5583ab 7.04%,
    #4777a2 100%
  );
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 36px;
    line-height: 1;
    z-index: 10;
  }

  h1 {
    margin: 0;
    font-size: 68px;
    line-height: 1;
    z-index: 10;
  }
`;

const MobileWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  h1 {
    margin: 0;
    color: ${({ theme }) => theme.color.primary.blue2};
  }

  h2 {
    margin: 0;
    color: ${({ theme }) => theme.color.primary.blue1};
  }

  p {
    font-family: ${({ theme }) => theme.fontFamily.body};
    color: ${({ theme }) => theme.color.primary.blue1};
    line-height: 1.8;
    margin: 24px 0;
  }
  a {
    color: ${({ theme }) => theme.color.primary.blue2};
    font-weight: bold;
  }
`;

const TextureWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: url(${TextureUrl}),
    linear-gradient(
      90deg,
      #3a678e 0%,
      #3a678e 6.84%,
      #5583ab 7.04%,
      #4777a2 100%
    );
  background-blend-mode: screen;
  opacity: 12%;
`;
