/** ============================ */
/*  This file was autogenerated  */
/*  ✨ with @graphql-codegen ✨  */
/* ============================= */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention */
import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../services/api/types.generated";

import { ResponseFragmentFragment } from "./responseFragment.generated";
import { ResponseFragmentFragmentDoc } from "./responseFragment.generated";

const defaultOptions = {};
export type GetResponseQueryVariables = Types.Exact<{
  myId: Types.Scalars["Int"];
  applicationPipelineSlug: Types.Scalars["String"];
}>;

export type GetResponseQuery = { __typename?: "Query" } & {
  claims: Array<{ __typename?: "Claim" } & ResponseFragmentFragment>;
};

export const GetResponseDocument = gql`
  query GetResponse($myId: Int!, $applicationPipelineSlug: String!) {
    claims(
      take: 1
      where: {
        pipeline: { is: { slug: { equals: $applicationPipelineSlug } } }
        user_id: { equals: $myId }
      }
    ) {
      ...ResponseFragment
    }
  }
  ${ResponseFragmentFragmentDoc}
`;

/**
 * __useGetResponseQuery__
 *
 * To run a query within a React component, call `useGetResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResponseQuery({
 *   variables: {
 *      myId: // value for 'myId'
 *      applicationPipelineSlug: // value for 'applicationPipelineSlug'
 *   },
 * });
 */
export function useGetResponseQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetResponseQuery,
    GetResponseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetResponseQuery, GetResponseQueryVariables>(
    GetResponseDocument,
    options
  );
}
export function useGetResponseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetResponseQuery,
    GetResponseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetResponseQuery,
    GetResponseQueryVariables
  >(GetResponseDocument, options);
}
export type GetResponseQueryHookResult = ReturnType<typeof useGetResponseQuery>;
export type GetResponseLazyQueryHookResult = ReturnType<
  typeof useGetResponseLazyQuery
>;
export type GetResponseQueryResult = ApolloReactCommon.QueryResult<
  GetResponseQuery,
  GetResponseQueryVariables
>;
