import React from "react";
import styled, { css } from "styled-components";

export type TTextProps = React.ComponentPropsWithoutRef<"p"> & {
  heading?: boolean;
  size?: string;

  /**
   * What HTML element type to use to render the component
   * NOTE: https://github.com/styled-components/styled-components/issues/2449
   */
  element?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  link?: boolean;
};

export const normalTextStyles = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.body}px;
  color: ${({ theme }) => theme.color.text.blue};
  line-height: 1.5;
  margin: 0;
`;

export const linkTextStyles = css`
  color: ${({ theme }) => theme.color.primary.blue2};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: bold;
  font-style: normal;
  text-align: center;
  margin-left: 5px;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 80%;
    text-decoration: underline;
  }
`;

const StyledText = styled.p<TTextProps>`
  ${normalTextStyles}
  font-family: ${({ theme, heading }) =>
    heading ? theme.fontFamily.heading : theme.fontFamily.body};
  font-size: ${({ theme, size = "bodyL" }) =>
    theme.fontSize[size] ? theme.fontSize[size] : size}px;
  ${({ link }) => link && linkTextStyles};
`;

const Text: React.FC<TTextProps> = ({ element = "p", link = false, children, ...rest }) => (
  <StyledText as={element} link={link} {...rest}>
    {children}
  </StyledText>
);

export default Text;
