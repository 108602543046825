import React, { useEffect } from "react";
import { useTheme } from "styled-components";

import {
  Icon,
  Text,
  Button,
  GradientText,
  Link,
  ResponsiveText,
  TextInput,
  TextAreaInput,
} from "src/components";
import { RouteName } from "src/constants/route";
import { useSiteContext, useThemeContext } from "src/contexts";

import { BOAT_POSITIONS } from "../Waves";

const DROPDOWN_OPTIONS = [
  {
    label: "option 1",
    value: "option 1",
  },
  {
    label: "option 2",
    value: "option 2",
  },
  {
    label: "option 3",
    value: "option 3",
  },
  {
    label: "option 4",
    value: "option 4",
  },
];

const Playground = () => {
  const theme = useTheme();
  const { toggleMode } = useThemeContext();
  const { boatPosition, setBoatPosition } = useSiteContext();

  return (
    <>
      <Text heading size="h1">
        Hi! My name is
      </Text>
      <Icon name="x" />
      <Icon name="edit" />
      <Icon name="plus" />
      <Icon name="video" />
      <Icon name="chevron" />
      <Icon name="checkmark" />
      <Icon name="blueCheckmark" />
      <Icon name="greenCheckmark" />
      <br />
      <Text heading size="title">
        Title
      </Text>
      <br />
      <Text heading size="h1">
        Heading 1
      </Text>
      <br />
      <Text heading size="h2">
        Heading 2
      </Text>
      <br />
      <Text heading size="h3">
        Heading 3
      </Text>
      <br />
      <Text heading size="h1M">
        Heading Mobile
      </Text>
      <br />
      <Text>Some Text</Text>
      <ResponsiveText width="80%" height={500} isMultiLine>
        In show dull give need so held. One order all scale sense her gay style
        wrote. Incommode our not one ourselves residence. Shall there whose
        those stand she end. So unaffected partiality indulgence dispatched to
        of celebrated remarkably. Unfeeling are had allowance own perceived
        abilities. For norland produce age wishing. To figure on it spring
        season up. Her provision acuteness had excellent two why intention. As
        called mr needed praise at. Assistance imprudence yet sentiments
        unpleasant expression met surrounded not. Be at talked ye though secure
        nearer. Those an equal point no years do. Depend warmth fat but her but
        played. Shy and subjects wondered trifling pleasant. Prudent cordial
        comfort do no on colonel as assured chicken. Smart mrs day which begin.
        Snug do sold mr it if such. Terminated uncommonly at at estimating. Man
        behaviour met moonlight extremity acuteness direction. Wise busy past
        both park when an ye no. Nay likely her length sooner thrown sex lively
        income. The expense windows adapted sir. Wrong widen drawn ample eat off
        doors money. Offending belonging promotion provision an be oh consulted
        ourselves it. Blessing welcomed ladyship she met humoured sir breeding
        her. Six curiosity day assurance bed necessary. In it except to so
        temper mutual tastes mother. Interested cultivated its continuing now
        yet are. Out interested acceptance our partiality affronting unpleasant
        why add. Esteem garden men yet shy course. Consulted up my tolerably
        sometimes perpetual oh. Expression acceptance imprudence particular had
        eat unsatiable.
      </ResponsiveText>
      <br></br>
      <Link href="https://hackthenorth.com">External Link</Link>
      <br />
      <Button>Button</Button>
      <br />
      <Button disabled>Primary Disabled</Button>
      <br />
      <Button secondary>Secondary</Button>
      <br />
      <Button disabled secondary>
        Secondary Disabled
      </Button>
      <br />
      <Button
        onClick={() =>
          setBoatPosition((boatPosition - 1) % BOAT_POSITIONS.length)
        }
      >
        Boat left
      </Button>
      <Button
        onClick={() =>
          setBoatPosition((boatPosition + 1) % BOAT_POSITIONS.length)
        }
      >
        Boat right
      </Button>
      <br />
      <Button icon="plus">Add</Button>
      <GradientText>GradientText</GradientText>
      <br />
      <TextInput placeholder="placeholder text" />
      <br />
      <TextInput placeholder="placeholder text" required />
      <br />
      <div style={{ height: "500px" }}>
        <TextAreaInput
          placeholder="From Hack the North, I'm looking to gain..."
          value=""
        />
      </div>
      <br />
    </>
  );
};
export default Playground;
