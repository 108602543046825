import { OptionTypeBase, OptionsType } from "react-select";

import {
  AlbertaMascotImg,
  CarletonMascotImg,
  McGillMascotImg,
  McMasterMascotImg,
  OtherSchoolMascotImg,
  TorontoMascotImg,
  UBCMascotImg,
  RyersonMascotImg,
  UWaterlooMascotImg,
  YorkMascotImg,
  WesternMascotImg,
  AlbertaMascotNoReflectionImg,
  CarletonMascotNoReflectionImg,
  McGillMascotNoReflectionImg,
  McMasterMascotNoReflectionImg,
  OtherSchoolMascotNoReflectionImg,
  RyersonMascotNoReflectionImg,
  TorontoMascotNoReflectionImg,
  UBCMascotNoReflectionImg,
  UWaterlooMascotNoReflectionImg,
  WesternMascotNoReflectionImg,
  YorkMascotNoReflectionImg,
  ProgramArtImg,
  ProgramArtNoPostersImg,
  ProgramBusinessImg,
  ProgramBusinessNoPostersImg,
  ProgramCSImg,
  ProgramCSNoPostersImg,
  ProgramDefaultImg,
  ProgramDefaultNoPostersImg,
  ProgramEngineeringImg,
  ProgramEngineeringNoPostersImg,
  ProgramMathImg,
  ProgramMathNoPostersImg,
  ProgramScienceImg,
  ProgramScienceNoPostersImg,
  ProgramSocSciImg,
  ProgramSocSciNoPostersImg,
} from "src/static";

export const LEVEL_OF_STUDY_OPTIONS: string[] = [
  "high school",
  "undergraduate",
  "post-graduate",
  "other",
];

export const SCHOOL_OPTIONS: string[] = [
  "University of Western Ontario",
  "Carleton University",
  "McGill University",
  "McMaster University",
  "Simon Fraser University",
  "University of British Columbia",
  "University of Toronto",
  "University of Ottawa",
  "University of Waterloo",
  "York University",
  "University of Alberta",
  "Ryerson University",
];

export enum School {
  UWO = "University of Western Ontario",
  CARLETON = "Carleton University",
  MCGILL = "McGill University",
  MCMASTER = "McMaster University",
  UBC = "University of British Columbia",
  UT = "University of Toronto",
  UW = "University of Waterloo",
  YU = "York University",
  RYERSON = "Ryerson University",
  OTHER = "Other",
  ALBERTA = "University of Alberta",
}

export const SCHOOL_TO_MASCOT: Record<School, any> = {
  [School.ALBERTA]: AlbertaMascotImg,
  [School.CARLETON]: CarletonMascotImg,
  [School.MCGILL]: McGillMascotImg,
  [School.MCMASTER]: McMasterMascotImg,
  [School.OTHER]: OtherSchoolMascotImg,
  [School.UBC]: UBCMascotImg,
  [School.UT]: TorontoMascotImg,
  [School.UW]: UWaterlooMascotImg,
  [School.UWO]: WesternMascotImg,
  [School.YU]: YorkMascotImg,
  [School.RYERSON]: RyersonMascotImg,
};

export const SCHOOL_TO_MASCOT_NO_REFLECTION: Record<School, any> = {
  [School.ALBERTA]: AlbertaMascotNoReflectionImg,
  [School.CARLETON]: CarletonMascotNoReflectionImg,
  [School.MCGILL]: McGillMascotNoReflectionImg,
  [School.MCMASTER]: McMasterMascotNoReflectionImg,
  [School.OTHER]: OtherSchoolMascotNoReflectionImg,
  [School.UBC]: UBCMascotNoReflectionImg,
  [School.UT]: TorontoMascotNoReflectionImg,
  [School.UW]: UWaterlooMascotNoReflectionImg,
  [School.UWO]: WesternMascotNoReflectionImg,
  [School.YU]: YorkMascotNoReflectionImg,
  [School.RYERSON]: RyersonMascotNoReflectionImg,
};

export const GRADUATING_YEAR_OPTIONS: number[] = [
  2021, 2022, 2023, 2024, 2025, 2026, 2027,
];

export const LONG_ANSWER_TITLES = [
  "Why do you want to come to Hack the North?* ",
  "Tell us about the best experience you've had working in a team. What made it different from the other teams you've worked with?* ",
  "Describe a time you faced an unexpected obstacle. How did you handle the situation?* ",
  "What type of hacker are you?*",
  "Where can we learn more about you?",
];

export const ALT_FORM_TITLES = [
  "What is your full name?*",
  "What is your level of study?*",
  "What is your year of graduation?*",
  "What institution do you attend?*",
  "What program are you studying?",
  "What location are you going to be hacking from?*",
  "How many hackathons have you attended?",
  "Why do you want to come to Hack the North?* ",
  "Tell us about the best experience you've had working in a team. What made it different from the other teams you've worked with?* ",
  "Describe a time you faced an unexpected obstacle. How did you handle the situation?* ",
  "What type of hacker are you?*",
  "Where can we learn more about you?",
];

export const HACKER_TYPES = [
  "Software",
  "Design",
  "Hardware",
  "Undecided/Other",
];

export const LINK_TYPES = [
  ["Github", "www.github.com/..."],
  ["Linkedin", "www.linkedin.com/in/..."],
  ["Devpost", "www.devpost.com/..."],
];

export const SURVEY_QUESTION_TITLES = [
  "What is your gender?",
  "What racial identity or identities best describes you?",
  "What ethnicity or ethnicities do you identify with?",
  "Are you, or will you be, the first in your family to pursue post secondary education?",
  "How many years have you been studying, working, or self-learning in a field of technology?",
  "Do you have any direct family members that have worked or studied in a field of technology?",
  "Do you identify with any of the following groups?",
  "Are you, or have you been, part of a club or organization for supporting underrepresented groups in tech or engineering? If so, which ones?",
  "What areas of technology are you most excited about?",
  "Which topic(s) would you most want to see covered in a workshop or workshop series?",
];

export const GENDER_OPTIONS = [
  "Male",
  "Female",
  "Non-binary, genderfluid, or two-spirited",
  "Another gender identity",
  "Prefer not to specify",
];

// export const RACE_OPTIONS = [
//   "Black",
//   "East Asian",
//   "Indigenous — First Nations, Métis, and/or Inuit",
//   "Latin American/Latina/Latino/Latinx",
//   "Middle Eastern",
//   "South Asian",
//   "Southeast Asian",
//   "White",
//   "Prefer not to specify",
//   "Another option(s) not listed",
// ];

export const RACE_OPTIONS: OptionsType<OptionTypeBase> = [
  {
    value: "Black",
    label: "Black",
  },
  {
    value: "East Asian",
    label: "East Asian",
  },
  {
    value: "Indigenous — First Nations, Métis, and/or Inuit",
    label: "Indigenous — First Nations, Métis, and/or Inuit",
  },
  {
    value: "Latin American/Latina/Latino/Latinx",
    label: "Latin American/Latina/Latino/Latinx",
  },
  {
    value: "Middle Eastern",
    label: "Middle Eastern",
  },
  {
    value: "South Asian",
    label: "South Asian",
  },
  {
    value: "Southeast Asian",
    label: "Southeast Asian",
  },
  {
    value: "White",
    label: "White",
  },
  {
    value: "Prefer not to specify",
    label: "Prefer not to specify",
  },
];

export const ETHNICITY_OPTIONS: OptionsType<OptionTypeBase> = [
  {
    value: "North American Indigenous origins",
    label: "North American Indigenous origins",
  },
  {
    value: "Carribean origins",
    label: "Carribean origins",
  },
  {
    value: "Latin, Central, and South American origins",
    label: "Latin, Central, and South American origins",
  },
  {
    value: "Western European origins",
    label: "Western European origins",
  },
  {
    value: "Northern European origins",
    label: "Northern European origins",
  },
  {
    value: "Eastern European origins",
    label: "Eastern European origins",
  },
  {
    value: "Southern European origins",
    label: "Southern European origins",
  },
  {
    value: "Other European origins",
    label: "Other European origins",
  },
  {
    value: "Central and West African origins",
    label: "Central and West African origins",
  },
  {
    value: "North African origins",
    label: "North African origins",
  },
  {
    value: "Southern and East African origins",
    label: "Southern and East African origins",
  },
  {
    value: "West and Central Asian and Middle Eastern origins",
    label: "West and Central Asian and Middle Eastern origins",
  },
  {
    value: "South Asian origins",
    label: "South Asian origins",
  },
  {
    value: "East and Southeast Asian origins",
    label: "East and Southeast Asian origins",
  },
  {
    value: "Oceanic Origins",
    label: "Oceanic Origins",
  },
  {
    value: "Prefer not to specify",
    label: "Prefer not to specify",
  },
];

export const UNDERREP_GROUPS_OPTIONS = [
  "LGBTQIA+",
  "Person with disability",
  "Mature student",
  "Non-native English speaker",
];

export const YES_NO_OPTIONS = ["Yes", "No"];

export const TECHNOLOGY_OPTIONS = [
  "Android",
  "Augmented Reality",
  "Backend",
  "Blockchain",
  "Design",
  "DevOps",
  "Embedded",
  "Frontend",
  "Hardware",
  "Healthcare",
  "iOS",
  "Internet of Things",
  "Machine Learning",
  "Robotics",
  "Virtual Reality",
];

export const WORKSHOP_OPTIONS = [
  "AI/Machine Learning",
  "Android",
  "Augmented Reality",
  "Blockchain",
  "Data Science",
  "Design",
  "Embedded",
  "Game Development",
  "Hardware",
  "iOS",
  "Job Search",
  "Product Management",
  "Security Engineering",
  "Web Development",
  "Virtual Reality",
];

export const PROGRAM_TO_ASSET = (
  program: string,
  posters: boolean
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => {
  if (PROGRAM_ART.includes(program)) {
    return posters ? ProgramArtImg : ProgramArtNoPostersImg;
  } else if (PROGRAM_BUSINESS.includes(program)) {
    return posters ? ProgramBusinessImg : ProgramBusinessNoPostersImg;
  } else if (PROGRAM_CS.includes(program)) {
    return posters ? ProgramCSImg : ProgramCSNoPostersImg;
  } else if (PROGRAM_ENGINEERING.includes(program)) {
    return posters ? ProgramEngineeringImg : ProgramEngineeringNoPostersImg;
  } else if (PROGRAM_MATH.includes(program)) {
    return posters ? ProgramMathImg : ProgramMathNoPostersImg;
  } else if (PROGRAM_SCIENCE.includes(program)) {
    return posters ? ProgramScienceImg : ProgramScienceNoPostersImg;
  } else if (PROGRAM_SOCSCI.includes(program)) {
    return posters ? ProgramSocSciImg : ProgramSocSciNoPostersImg;
  }
  return posters ? ProgramDefaultImg : ProgramDefaultNoPostersImg;
};

export const PROGRAM_OPTIONS = [
  "Aboriginal Studies",
  "Accounting",
  "Accounting and Financial Management",
  "Actuarial Science",
  "Acupuncture",
  "Administrative Assistant",
  "Advertising and Marketing",
  "African Studies",
  "Agriculture",
  "Ancient History",
  "Animation",
  "Anthropology",
  "Applied Mathematics",
  "Applied Statistics",
  "Archaeology",
  "Architecture",
  "Art History",
  "Art Therapy",
  "Arts Management",
  "Asian American Studies",
  "Asian Canadian Studies",
  "Asian Geographies",
  "Asian Literatures and Cultures",
  "Astronomical Science",
  "Astronomy",
  "Astronomy and Astrophysics",
  "Astronomy and Physics",
  "Athletic Training",
  "Audio and Video Production",
  "Audiology and Speech Pathology",
  "Automotive Engineering",
  "Aviation",
  "Baking And Pastry",
  "Behavioral Science",
  "Biochemistry",
  "Biodiversity and Conservation Biology",
  "Bioengineering",
  "Bioethics",
  "Bioinformatics",
  "Bioinformatics and Computational Biology",
  "Biological Chemistry",
  "Biological Physics",
  "Biology",
  "Biology for Health Sciences",
  "Biomedical Communications",
  "Biomedical Engineering",
  "Biomedical Physics",
  "Biomedical Science",
  "Biomedical Toxicology",
  "Biophysics",
  "Biotechnology",
  "Biotechnology/Chartered Professional Accountancy",
  "Biotechnology/Economics",
  "Book and Media Studies",
  "Botany",
  "Business Administration",
  "Business Administration and Computer Science Double Degree",
  "Business Administration and Mathematics Double Degree",
  "Canadian Studies",
  "Caribbean Studies",
  "Cell and Molecular Biology",
  "Chemical Engineering",
  "Chemical Physics",
  "Chemical and Biomolecular Engineering",
  "Chemistry",
  "Child Care",
  "Child Development",
  "Chiropractic",
  "Christian Counseling",
  "Christianity and Culture",
  "Cinema Studies",
  "Cinematography And Film",
  "City Studies",
  "Civil Engineering",
  "Classical Civilization",
  "Classical Studies",
  "Clinical Psychology",
  "Cognitive Science",
  "Combinatorics and Optimization",
  "Commerce",
  "Communication",
  "Comparative Literature",
  "Comparative Physiology",
  "Computational Biology",
  "Computational Mathematics",
  "Computational Neuroscience",
  "Computer Engineering",
  "Computer Graphics",
  "Computer Networking",
  "Computer Programming",
  "Computer Science",
  "Computer and Cognitive Science",
  "Computing and Financial Management",
  "Conditional Admission to Pharmacy (CAP)",
  "Conservation and Biodiversity",
  "Construction Management",
  "Cosmetology",
  "Counseling Psychology",
  "Creative Writing",
  "Criminal Justice",
  "Criminology",
  "Critical Writing Program",
  "Croatian and Serbian Studies",
  "Culinary Arts",
  "Dance",
  "Dental Assistant",
  "Dental Hygienist",
  "Dental Medicine",
  "Dentistry",
  "Developmental And Child Psychology",
  "Developmental Biology",
  "Dietetics",
  "Digital Media Design",
  "Drama",
  "Early Childhood Education",
  "Earth Sciences",
  "East Asian Languages and Civilization",
  "East Asian Studies",
  "Economics",
  "Educational Leadership and Administration",
  "Electrical Engineering",
  "Elementary Education",
  "Engineering Management",
  "English",
  "English Language Programs",
  "Environment and Business",
  "Environment and Resource Studies",
  "Environmental Engineering",
  "Environmental Health",
  "Environmental Science",
  "Environmental Studies",
  "Environmental and Wildlife Management",
  "Equine Studies",
  "Esthetician",
  "European Studies",
  "European Union Studies",
  "Exceptionality in Human Learning",
  "Exercise Physiology",
  "Fashion Design",
  "Fashion Merchandising",
  "Finance",
  "Finance and Economics",
  "Financial Economics",
  "Fine Arts",
  "Finnish Studies",
  "Fire Science",
  "Food Science",
  "Forensic Psychology",
  "Forensic Science",
  "Forest Biomaterials Science",
  "Forestry",
  "Francophone Studies",
  "French",
  "French Studies",
  "French and Italian",
  "Game Design",
  "General Physics",
  "General Studies",
  "Genome Biology",
  "Geocomputational Science",
  "Geographic Information Systems",
  "Geographical Information Systems",
  "Geography",
  "Geography and Aviation",
  "Geography and Environmental Management",
  "Geological Engineering",
  "Geology",
  "Geomatics",
  "Geoscience",
  "German Studies",
  "German and Ukrainian",
  "Germanic Languages and Literatures",
  "German",
  "Global Asia Studies",
  "Global Business and Digital Arts",
  "Global Health",
  "Graphic Design",
  "Greek",
  "Health Informatics",
  "Health Promotion",
  "Health Studies",
  "Health and Disease",
  "Health and Societies",
  "Healthcare Administration",
  "Hispanic Studies",
  "History",
  "History and Philosophy of History and Technology",
  "History and Political Science",
  "History of Art",
  "History of Religions",
  "Holistic Health and Nutrition",
  "Honours Arts and Business",
  "Honours Arts",
  "Hotel and Hospitality Management",
  "Human Biology",
  "Human Geography",
  "Human Resources",
  "Human Services",
  "Hungarian Studies",
  "Illustration",
  "Immunology",
  "Independent Studies",
  "Industrial Design",
  "Information Security",
  "Information Systems",
  "Information Technology",
  "Information Technology Management",
  "Integrative Biology",
  "Interactive Digital Media",
  "Interior Design",
  "International Affairs",
  "International Business",
  "International Development Studies",
  "International Relations",
  "International Studies and Business",
  "Islamic Studies",
  "Italian",
  "Italian Culture and Communication Studies",
  "Italian Studies",
  "Kinesiology",
  "Knowledge Integration",
  "Language Certificate Program",
  "Language Teaching and Learning: French and Italian",
  "Latin",
  "Latin American and Latino Studies",
  "Lauder Institute of Management",
  "Law",
  "Law and Justice Administration",
  "Legal Studies",
  "Legal Studies and History",
  "Liberal Arts",
  "Liberal Studies",
  "Life Physics",
  "Life Sciences",
  "Linguistics",
  "Literary Studies",
  "Literature and Film Studies",
  "Logistics and Supply Chain Management",
  "Makeup Artist",
  "Management",
  "Management Engineering",
  "Management and Accounting",
  "Management and Finance",
  "Management and Human Resources",
  "Management and Information Technology",
  "Management and International Business",
  "Management and Marketing",
  "Management and Technology",
  "Marine Biology",
  "Marriage and Family Therapy",
  "Massage Therapy",
  "Material Culture",
  "Materials Engineering",
  "Materials Science",
  "Materials Science and Engineering",
  "Mathematical Finance",
  "Mathematical Physics",
  "Mathematical Sciences",
  "Mathematical Studies",
  "Mathematical Optimization",
  "Mathematics",
  "Mathematics and Philosophy",
  "Mathematics and Physics",
  "Mathematics/Business Administration",
  "Mathematics/Chartered Professional Accountancy",
  "Mathematics/Financial Analysis and Risk Management",
  "Mathematics/Teaching",
  "Mechanical Engineering",
  "Mechatronics Engineering",
  "Media Studies",
  "Medical Assistant",
  "Medical Radiation Sciences",
  "Medical Transcription",
  "Medicinal Chemistry",
  "Medicine",
  "Medieval Studies",
  "Mental Health Counseling",
  "Mental Health Studies",
  "Microbiology",
  "Ministry",
  "Modern Languages Program",
  "Modern Middle Eastern Studies",
  "Molecular Biology",
  "Molecular Biology and Biotechnology",
  "Molecular Genetics and Microbiology",
  "Museum Studies",
  "Music Management",
  "Music Therapy",
  "Music and Culture",
  "Music in Performance",
  "Nanoscience",
  "Nanotechnology Engineering",
  "Neuroscience",
  "Nursing",
  "Nursing and Health Care Management",
  "Nutritional Sciences",
  "Optometry",
  "Organizational Psychology",
  "Organizational and Nonprofit Management",
  "Paralegal",
  "Parks and Recreation Management",
  "Peace and Conflict Studies",
  "Petroleum Engineering",
  "Pharmaceutical Chemistry",
  "Pharmacology",
  "Pharmacology and Biomedical Toxicology",
  "Pharmacy",
  "Philosophy",
  "Philosophy and Physics",
  "Philosophy of Science",
  "Photojournalism",
  "Physical Education",
  "Physical Education and Health",
  "Physical Sciences",
  "Physician Assistant",
  "Physics",
  "Physics and Astronomy",
  "Physiology",
  "Planetary Science",
  "Planning",
  "Polish Language and Literature",
  "Polish Studies",
  "Political Science",
  "Prehistoric Archeology",
  "Professional Writing and Communication",
  "Property Management",
  "Psycholinguistics",
  "Psychology",
  "Public Administration",
  "Public Health",
  "Public Relations",
  "Pure Mathematics",
  "Radio And Television Broadcasting",
  "Recreation and Leisure Studies",
  "Religious Studies",
  "Renaissance Studies",
  "Respiratory Therapy",
  "Risk Management and Insurance",
  "Russian and East European Studies",
  "Science and Aviation",
  "Science and Business",
  "Semiotics and Communication Theory",
  "Sexual Diversity Studies",
  "Sexuality, Marriage, and Family Studies",
  "Sign Language",
  "Slavic Languages & Literatures",
  "Social Development Studies",
  "Social Work",
  "Sociology",
  "Software Engineering",
  "Spanish",
  "Speech Communication",
  "Sports Management",
  "Sports Medicine",
  "Statistics",
  "Statistics and Mathematics",
  "Statistics for Health Statistics Management",
  "Structural Engineering",
  "Substance Abuse and Addiction Counseling",
  "Surgical Technologist",
  "Sustainable Energy",
  "Synthetic and Catalytic Chemistry",
  "Systems Design Engineering",
  "Systems Engineering",
  "Systems Science and Engineering",
  "Teacher Education Program",
  "Theatre Arts",
  "Theatre and Performance Studies",
  "Therapeutic Recreation",
  "Tourism and Parks Management",
  "Urban Education Program",
  "Urban Planning",
  "Urban Studies",
  "Visual Culture and Communication",
  "Visual Studies",
  "Web Design",
  "Wildlife Biology",
  "Women and Gender Studies",
  "Women's Studies",
  "Writing and Rhetoric",
  "Zoology",
];

const PROGRAM_BUSINESS = [
  "Accounting",
  "Accounting and Financial Management",
  "Biotechnology/Chartered Professional Accountancy",
  "Biotechnology/Economics",
  "Business Administration",
  "Business Administration and Computer Science Double Degree",
  "Business Administration and Mathematics Double Degree",
  "Commerce",
  "Computing and Financial Management",
  "Economics",
  "Environment and Business",
  "Finance",
  "Finance and Economics",
  "Financial Economics",
  "Global Business and Digital Arts",
  "Honours Arts and Business",
];

const PROGRAM_CS = [
  "Computer Graphics",
  "Computer Networking",
  "Computer Programming",
  "Computer Science",
  "Computer and Cognitive Science",
  "Information Security",
  "Information Systems",
  "Information Technology",
  "Information Technology Management",
];

const PROGRAM_MATH = [
  "Actuarial Science",
  "Applied Mathematics",
  "Applied Statistics",
  "Bioinformatics",
  "Bioinformatics and Computational Biology",
  "Combinatorics and Optimization",
  "Computational Biology",
  "Computational Mathematics",
  "Computational Neuroscience",
  "Geomatics",
  "Mathematical Finance",
  "Mathematical Physics",
  "Mathematical Sciences",
  "Mathematical Studies",
  "Mathematical Optimization",
  "Mathematics",
  "Mathematics and Philosophy",
  "Mathematics and Physics",
  "Mathematics/Business Administration",
  "Mathematics/Chartered Professional Accountancy",
  "Mathematics/Financial Analysis and Risk Management",
  "Mathematics/Teaching",
  "Pure Mathematics",
  "Statistics",
  "Statistics and Mathematics",
  "Statistics for Health Statistics Management",
];

const PROGRAM_ENGINEERING = [
  "Automotive Engineering",
  "Bioengineering",
  "Chemical Engineering",
  "Chemical and Biomolecular Engineering",
  "Civil Engineering",
  "Computer Engineering",
  "Electrical Engineering",
  "Engineering Management",
  "Environmental Engineering",
  "Geological Engineering",
  "Materials Engineering",
  "Materials Science and Engineering",
  "Mechanical Engineering",
  "Mechatronics Engineering",
  "Nanotechnology Engineering",
  "Petroleum Engineering",
  "Software Engineering",
  "Structural Engineering",
  "Systems Design Engineering",
  "Systems Engineering",
  "Systems Science and Engineering",
];

const PROGRAM_SOCSCI = [
  "African Studies",
  "Ancient History",
  "Anthropology",
  "Art History",
  "Asian American Studies",
  "Asian Canadian Studies",
  "Asian Geographies",
  "Asian Literatures and Cultures",
  "Canadian Studies",
  "Caribbean Studies",
  "Christianity and Culture",
  "Clinical Psychology",
  "Communication",
  "Counseling Psychology",
  "Croatian and Serbian Studies",
  "Developmental And Child Psychology",
  "East Asian Languages and Civilization",
  "East Asian Studies",
  "Educational Leadership and Administration",
  "European Studies",
  "European Union Studies",
  "Finnish Studies",
  "Forensic Psychology",
  "Francophone Studies",
  "French Studies",
  "Geographic Information Systems",
  "Geographical Information Systems",
  "Geography",
  "Geography and Aviation",
  "Geography and Environmental Management",
  "German Studies",
  "Germanic Languages and Literatures",
  "Global Asia Studies",
  "Healthcare Administration",
  "Hispanic Studies",
  "History",
  "History and Philosophy of History and Technology",
  "History and Political Science",
  "History of Art",
  "History of Religions",
  "Human Geography",
  "Hungarian Studies",
  "International Affairs",
  "International Business",
  "International Development Studies",
  "International Relations",
  "International Studies and Business",
  "Islamic Studies",
  "Italian Culture and Communication Studies",
  "Italian Studies",
  "Language Certificate Program",
  "Language Teaching and Learning: French and Italian",
  "Latin",
  "Latin American and Latino Studies",
  "Lauder Institute of Management",
  "Linguistics",
  "Management",
  "Management Engineering",
  "Management and Accounting",
  "Management and Finance",
  "Management and Human Resources",
  "Management and Information Technology",
  "Management and International Business",
  "Management and Marketing",
  "Management and Technology",
  "Medieval Studies",
  "Mental Health Counseling",
  "Mental Health Studies",
  "Ministry",
  "Modern Languages Program",
  "Modern Middle Eastern Studies",
  "Organizational Psychology",
  "Organizational and Nonprofit Management",
  "Parks and Recreation Management",
  "Peace and Conflict Studies",
  "Philosophy",
  "Philosophy and Physics",
  "Philosophy of Science",
  "Planning",
  "Polish Language and Literature",
  "Polish Studies",
  "Political Science",
  "Prehistoric Archeology",
  "Professional Writing and Communication",
  "Property Management",
  "Psycholinguistics",
  "Psychology",
  "Public Administration",
  "Public Relations",
  "Religious Studies",
  "Renaissance Studies",
  "Russian and East European Studies",
  "Semiotics and Communication Theory",
  "Sexual Diversity Studies",
  "Sexuality, Marriage, and Family Studies",
  "Sign Language",
  "Slavic Languages & Literatures",
  "Social Development Studies",
  "Social Work",
  "Sociology",
  "Speech Communication",
  "Substance Abuse and Addiction Counseling",
  "Tourism and Parks Management",
  "Urban Education Program",
  "Urban Planning",
  "Urban Studies",
  "Visual Culture and Communication",
  "Women and Gender Studies",
  "Women's Studies",
];

const PROGRAM_ART = [
  "Administrative Assistant",
  "Advertising and Marketing",
  "Animation",
  "Archaeology",
  "Audio and Video Production",
  "Baking And Pastry",
  "Book and Media Studies",
  "Cinema Studies",
  "Cinematography And Film",
  "City Studies",
  "Classical Civilization",
  "Classical Studies",
  "Comparative Literature",
  "Comparative Physiology",
  "Construction Management",
  "Creative Writing",
  "Criminal Justice",
  "Criminology",
  "Critical Writing Program",
  "Culinary Arts",
  "Dance",
  "Digital Media Design",
  "Drama",
  "Early Childhood Education",
  "Elementary Education",
  "English",
  "English Language Programs",
  "Fashion Design",
  "Fashion Merchandising",
  "Fine Arts",
  "French",
  "French and Italian",
  "German and Ukrainian",
  "German",
  "Graphic Design",
  "Greek",
  "Honours Arts",
  "Illustration",
  "Industrial Design",
  "Interactive Digital Media",
  "Interior Design",
  "Italian",
  "Law",
  "Law and Justice Administration",
  "Legal Studies",
  "Legal Studies and History",
  "Liberal Arts",
  "Liberal Studies",
  "Literary Studies",
  "Literature and Film Studies",
  "Makeup Artist",
  "Material Culture",
  "Media Studies",
  "Museum Studies",
  "Music Management",
  "Music Therapy",
  "Music and Culture",
  "Music in Performance",
  "Paralegal",
  "Photojournalism",
  "Radio And Television Broadcasting",
  "Recreation and Leisure Studies",
  "Spanish",
  "Teacher Education Program",
  "Theatre Arts",
  "Theatre and Performance Studies",
  "Visual Studies",
  "Web Design",
  "Writing and Rhetoric",
];

const PROGRAM_SCIENCE = [
  "Agriculture",
  "Astronomical Science",
  "Astronomy",
  "Astronomy and Astrophysics",
  "Astronomy and Physics",
  "Athletic Training",
  "Audiology and Speech Pathology",
  "Aviation",
  "Behavioral Science",
  "Biochemistry",
  "Biodiversity and Conservation Biology",
  "Bioethics",
  "Biology",
  "Biology for Health Sciences",
  "Biomedical Communications",
  "Biomedical Engineering",
  "Biomedical Physics",
  "Biomedical Science",
  "Biomedical Toxicology",
  "Biophysics",
  "Biotechnology",
  "Botany",
  "Cell and Molecular Biology",
  "Chemical Physics",
  "Chemistry",
  "Chiropractic",
  "Cognitive Science",
  "Conditional Admission to Pharmacy (CAP)",
  "Conservation and Biodiversity",
  "Dental Hygienist",
  "Dental Medicine",
  "Dentistry",
  "Developmental Biology",
  "Dietetics",
  "Earth Sciences",
  "Exercise Physiology",
  "Food Science",
  "Forensic Science",
  "Forest Biomaterials Science",
  "General Physics",
  "Genome Biology",
  "Geocomputational Science",
  "Geology",
  "Geoscience",
  "Global Health",
  "Human Biology",
  "Immunology",
  "Integrative Biology",
  "Kinesiology",
  "Life Physics",
  "Life Sciences",
  "Marine Biology",
  "Materials Science",
  "Medical Radiation Sciences",
  "Medicinal Chemistry",
  "Medicine",
  "Microbiology",
  "Molecular Biology",
  "Molecular Biology and Biotechnology",
  "Molecular Genetics and Microbiology",
  "Nanoscience",
  "Neuroscience",
  "Nursing",
  "Nursing and Health Care Management",
  "Nutritional Sciences",
  "Optometry",
  "Pharmaceutical Chemistry",
  "Pharmacology",
  "Pharmacology and Biomedical Toxicology",
  "Pharmacy",
  "Physical Sciences",
  "Physics",
  "Physics and Astronomy",
  "Physiology",
  "Planetary Science",
  "Public Health",
  "Science and Aviation",
  "Science and Business",
  "Sustainable Energy",
  "Synthetic and Catalytic Chemistry",
  "Wildlife Biology",
  "Zoology",
];

export const HACKATHON_COUNT_OPTIONS = ["0", "1", "2", "3", "4+"];
export const EXPERIENCE_OPTIONS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8+",
];

// based on https://data.team.hackthenorth.com/question/152
export const DEFAULT_CITIES = [
  "Waterloo, Ontario, Canada",
  "Toronto, Ontario, Canada",
  "Vancouver, British Columbia, Canada",
  "Ottawa, Ontario, Canada",
  "London, Ontario, Canada",
  "Hamilton, Ontario, Canada",
  "Montreal, Quebec, Canada",
];

export const RACE_TOOLTIP =
  "Racial identity refers to the set of physical appearances common to a group of people such as skin colour, facial features, and hair texture.";
export const ETHNICITY_TOOLTIP =
  "Ethnicity refers to the shared languages, traditions, and cultural heritage of a group of people from a given geographic region.";
export const TECHNOLOGY_MAX = 3;
export const WORKSHOP_MAX = 3;
export const OPTIONS_MAX_COPY =
  "You've selected the maximum number of options! To choose another, please unselect an existing one.";
