import React from "react";

import {
  RedBoatImg,
  PinkBoatImg,
  YellowBoatImg,
  BlueBoatImg,
  BeigeBoatImg,
  PlaidBoatImg,
  SmileImg,
  SmileLightImg,
  JoyImg,
  JoyLightImg,
  EyepatchImg,
  EyepatchLightImg,
  GlassesImg,
  GlassesLightImg,
  FlagImg,
  FlagSelectedImg,
  PlantImg,
  PlantSelectedImg,
  PrideImg,
  PrideSelectedImg,
  BeretImg,
  BeretSelectedImg,
  EggImg,
  EggSelectedImg,
  PirateHatImg,
  PirateHatSelectedImg,
  CowboyHatImg,
  CowboyHatSelectedImg,
  RedBoatHatImg,
  RedBoatHatSelectedImg,
  PinkBoatHatImg,
  PinkBoatHatSelectedImg,
  YellowBoatHatImg,
  YellowBoatHatSelectedImg,
  BlueBoatHatImg,
  BlueBoatHatSelectedImg,
  BeigeBoatHatImg,
  BeigeBoatHatSelectedImg,
  PlaidBoatHatImg,
  PlaidBoatHatSelectedImg,
} from "src/static";

// boat customization options
export const colors: { [name: string]: JSX.Element } = {};
colors["red"] = <RedBoatImg />;
colors["pink"] = <PinkBoatImg />;
colors["yellow"] = <YellowBoatImg />;
colors["blue"] = <BlueBoatImg />;
colors["beige"] = <BeigeBoatImg />;
colors["plaid"] = <PlaidBoatImg />;

export const expressions: { [name: string]: JSX.Element } = {};
expressions["smile"] = <SmileImg />;
expressions["joy"] = <JoyImg />;
expressions["eyepatch"] = <EyepatchImg />;
expressions["glasses"] = <GlassesImg />;

export const expressions_light: { [name: string]: JSX.Element } = {};
expressions_light["smile"] = <SmileLightImg />;
expressions_light["joy"] = <JoyLightImg />;
expressions_light["eyepatch"] = <EyepatchLightImg />;
expressions_light["glasses"] = <GlassesLightImg />;

type TAccessory = {
  normal: JSX.Element | { [name: string]: JSX.Element };
  selected: JSX.Element | { [name: string]: JSX.Element };
};
export const boat_hats: { [name: string]: JSX.Element } = {};
boat_hats["red"] = <RedBoatHatImg />;
boat_hats["pink"] = <PinkBoatHatImg />;
boat_hats["yellow"] = <YellowBoatHatImg />;
boat_hats["blue"] = <BlueBoatHatImg />;
boat_hats["beige"] = <BeigeBoatHatImg />;
boat_hats["plaid"] = <PlaidBoatHatImg />;
export const boat_hats_selected: { [name: string]: JSX.Element } = {};
boat_hats_selected["red"] = <RedBoatHatSelectedImg />;
boat_hats_selected["pink"] = <PinkBoatHatSelectedImg />;
boat_hats_selected["yellow"] = <YellowBoatHatSelectedImg />;
boat_hats_selected["blue"] = <BlueBoatHatSelectedImg />;
boat_hats_selected["beige"] = <BeigeBoatHatSelectedImg />;
boat_hats_selected["plaid"] = <PlaidBoatHatSelectedImg />;

export const accessories: { [name: string]: TAccessory } = {};
accessories["flag"] = {
  normal: <FlagImg />,
  selected: <FlagSelectedImg />,
};
accessories["plant"] = {
  normal: <PlantImg />,
  selected: <PlantSelectedImg />,
};
accessories["pride"] = {
  normal: <PrideImg />,
  selected: <PrideSelectedImg />,
};
accessories["boat_hat"] = {
  normal: boat_hats,
  selected: boat_hats_selected,
};
accessories["beret"] = {
  normal: <BeretImg />,
  selected: <BeretSelectedImg />,
};
accessories["egg"] = {
  normal: <EggImg />,
  selected: <EggSelectedImg />,
};
accessories["pirate_hat"] = {
  normal: <PirateHatImg />,
  selected: <PirateHatSelectedImg />,
};
accessories["cowboy_hat"] = {
  normal: <CowboyHatImg />,
  selected: <CowboyHatSelectedImg />,
};
