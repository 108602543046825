import React from "react";
import styled from "styled-components";

import { Flex, Text } from "src/components";
import { RouteName } from "src/constants/route";
import { useSiteContext } from "src/contexts";
import { CircleCheckmarkIcon } from "src/static/icons";

type TSectionProps = {
  title: string;
  route: RouteName;
  visited: boolean;
  current: boolean;
  completed: boolean;
  to: RouteName;
};

const Section: React.FC<TSectionProps> = ({
  title,
  visited,
  current,
  completed,
  to,
}) => {
  const { navigate } = useSiteContext();
  const onClick = visited ? () => navigate(to) : undefined;

  return (
    <Container
      align="center"
      justify="center"
      current={current}
      disabled={!onClick}
      role="button"
      onClick={onClick}
    >
      <CompletedContainer show={completed}>
        <CircleCheckmarkIcon height="14px" />
      </CompletedContainer>
      <StyledText current={current} visited={visited}>
        {title}
      </StyledText>
    </Container>
  );
};

export default Section;

const Container = styled(Flex)<{ current: boolean; disabled: boolean }>`
  background: ${({ current }) => (current ? "#4779A4" : "white")};
  border-radius: 8px;
  padding: 10px 16px 10px 8px;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  transition: opacity 100ms, background 300ms;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.8)};
  }
`;

const CompletedContainer = styled.span<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ show }) => (show ? "4px" : "0")};

  transition: transform 500ms, padding-right 400ms;
  transform: scale(${({ show }) => (show ? 1 : 0)});
`;

const StyledText = styled(Text).attrs({ element: "span", size: "body" })<{
  current: boolean;
  visited: boolean;
}>`
  white-space: nowrap;
  color: ${({ current, visited }) =>
    current ? "white" : visited ? "#4779A4" : "#B9B9B9"};
  margin-right: 12px;
`;
