import React from "react";
import styled from "styled-components";

import { useSiteContext } from "src/contexts/SiteContext";
import { mediaQueries, deviceBreakpoints } from "src/utils/responsive";

export type TLongAnswerPageProps = {
  children: React.ReactNode;
};

export const WhiteContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  height: 90%;
  position: relative;
  padding-top: 64px;
  max-width: ${deviceBreakpoints.medium}px;
  border-radius: 16px;

  ${mediaQueries.tablet} {
    margin: 0;
  }

  ${mediaQueries.largeMobile} {
    margin: 0;
  }
`;

export const ChildrenWrapper = styled.div`
  height: 100%;
  padding: 0 70px;

  ${mediaQueries.tablet} {
    padding: 0 64px;
  }

  ${mediaQueries.largeMobile} {
    padding: 0 24px;
  }
`;

export const Background = styled.div<{ isMobile: boolean }>`
  background-size: cover;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? "50px" : "250px")};
`;

const LongAnswerPage = ({ children }: TLongAnswerPageProps) => {
  const { isMobile } = useSiteContext();

  return (
    <Background isMobile={isMobile}>
      <WhiteContainer>
        <ChildrenWrapper id="longAnswerQuestionsWrapper">
          {children}
        </ChildrenWrapper>
      </WhiteContainer>
    </Background>
  );
};
export default LongAnswerPage;
