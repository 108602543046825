import React, { ComponentPropsWithoutRef, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

import { InkTransitionImg, InkPhotoFrameImg } from "src/static";
import { mediaQueries } from "src/utils/responsive";

type TInkAnimationProps = ComponentPropsWithoutRef<"div">;

const inkTransition = keyframes`
  0% {
    transform: translateX(-1.25%);
  }

  99% {
    transform: translateX(-98.75%);
    opacity: 1;
  }

  100% {
    transform: translateX(-98.75%);
    opacity: 0;
  }
`;

const InkTransition: React.FC<TInkAnimationProps> = ({ children }) => (
  <InkTransitionContainer>
    <OpacityContainer>{children}</OpacityContainer>
  </InkTransitionContainer>
);

const OpacityContainer = styled.div`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 200ms;
`;

const InkTransitionContainer = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  &::before {
    background-image: url(${InkPhotoFrameImg});
    ${mediaQueries.tablet} {
      background-image: none;
    }
    background-size: 100% 100%;
    background-position: 50% 50%;
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &::after {
    background-image: url(${InkTransitionImg});
    background-size: 100% 100%;
    content: "";
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-1.25%);
    width: 4000%; // TODO: Tweak this
    animation: ${inkTransition} 1.2s steps(39) 0.5s forwards;
  }
`;

export default InkTransition;
