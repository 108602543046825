/* eslint-disable @typescript-eslint/naming-convention */
import { Nullable } from "./typescript";

export type Field<T> = {
  answer?: Nullable<{
    value: T;
    updated_at: string;
  }>;
  field_type: string; // todo: better type for this
  slug?: Nullable<string>;
  updated_at: string; // datetime
  valid_values?: Nullable<string[]>;
};

/**
 * HackerAPIX types
 * These are used to enfoce the typings of values we store in fields like `data`, which are typed JSON by HackerAPI (meaning there's no type-checking)
 */
// hackerapi Date type returns a string with format "yyyy-mm-ddTHH:mm:ss.000Z", with the time being optional
export type HackerAPIDate = string;

export type HackerAPIFile = {
  id: Nullable<string>;
  name: Nullable<string>;
  type: Nullable<string>; // mime type
  uri: Nullable<string>;
  expiry?: Nullable<number>;
};

/**
 * Utilities
 */
export const convertFileToHAPIFile = (file: File): HackerAPIFile => {
  const fileUrl = URL.createObjectURL(file);
  return {
    id: fileUrl,
    name: file.name,
    type: file.type,
    uri: fileUrl,
  };
};

// See: https://techyon.slack.com/archives/C029VHHNT/p1626140654498700
export const getFieldAnswer = <T>(
  fields: Field<T>[] = [],
  answerSlug: string
) => fields.find((f) => f.slug === answerSlug)?.answer?.value ?? null;
