import React from "react";
import { useLocation } from "react-router";
import tw, { styled } from "twin.macro";

import { Link, Text } from "src/components";
import { RouteName } from "src/constants/route";
import { ExternalLinkIcon } from "src/static/icons";

const AboutLink: React.FC = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === RouteName.LANDING && (
        <div tw="absolute right-48 top-48">
          <StyledLink
            href="http://hackthenorth.com/"
            target="_blank"
            rel="noopener"
            tw="flex flex-row items-center"
          >
            <Text link tw="pr-8">
              About the event
            </Text>
            <ExternalLinkIcon />
          </StyledLink>
        </div>
      )}
    </>
  );
};

const StyledLink = styled(Link)`
  &:after {
    /* Disable the animated underline on hover */
    height: 0px !important;
  }
`;

export default AboutLink;
