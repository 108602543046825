import styled from "styled-components";

import { Text, TTextProps } from "src/components";

const GradientText = styled(Text)<TTextProps>`
  display: inline-block;
  background: ${({ theme }) => theme.color.custom.greenBlueGradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export default GradientText;
