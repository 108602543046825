/** ============================ */
/*  This file was autogenerated  */
/*  ✨ with @graphql-codegen ✨  */
/* ============================= */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention */
import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../services/api/types.generated";

import { ResponseFragmentFragment } from "./responseFragment.generated";
import { ResponseFragmentFragmentDoc } from "./responseFragment.generated";

const defaultOptions = {};
export type UpdateResponseMutationVariables = Types.Exact<{
  updatedData: Types.ClaimUpdateInput;
}>;

export type UpdateResponseMutation = { __typename?: "Mutation" } & {
  updateClaim: { __typename?: "Claim" } & ResponseFragmentFragment;
};

export const UpdateResponseDocument = gql`
  mutation UpdateResponse($updatedData: ClaimUpdateInput!) {
    updateClaim(data: $updatedData) {
      ...ResponseFragment
    }
  }
  ${ResponseFragmentFragmentDoc}
`;

/**
 * __useUpdateResponseMutation__
 *
 * To run a mutation, you first call `useUpdateResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResponseMutation, { data, loading, error }] = useUpdateResponseMutation({
 *   variables: {
 *      updatedData: // value for 'updatedData'
 *   },
 * });
 */
export function useUpdateResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateResponseMutation,
    UpdateResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateResponseMutation,
    UpdateResponseMutationVariables
  >(UpdateResponseDocument, options);
}
export type UpdateResponseMutationHookResult = ReturnType<
  typeof useUpdateResponseMutation
>;
export type UpdateResponseMutationResult =
  ApolloReactCommon.MutationResult<UpdateResponseMutation>;
export type UpdateResponseMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateResponseMutation,
    UpdateResponseMutationVariables
  >;
