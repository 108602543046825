import React from "react";

const PaperIcon: React.FC<{ paperColor: string; selected?: boolean }> = ({
  paperColor,
  selected,
}) => {
  const style = { "mix-blend-mode": "multiply" } as React.CSSProperties;

  let paper = <rect width="56" height="42" rx="10" fill="#CF425C" />;

  const strokeColor = "#7EC2E4";
  const strokeWidth = selected ? 4 : 0;
  const rectWidth = 51;
  const rectHeight = 38;
  const rectRadius = 8;

  switch (paperColor) {
    case "red": {
      paper = (
        <rect
          width={rectWidth}
          height={rectHeight}
          rx={rectRadius}
          fill="#CF425C"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
      );
      break;
    }
    case "pink": {
      paper = (
        <rect
          width={rectWidth}
          height={rectHeight}
          rx={rectRadius}
          fill="#EC8387"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
      );
      break;
    }
    case "yellow": {
      paper = (
        <rect
          width={rectWidth}
          height={rectHeight}
          rx={rectRadius}
          fill="#FDC73C"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
      );
      break;
    }
    case "blue": {
      paper = (
        <rect
          width={rectWidth}
          height={rectHeight}
          rx={rectRadius}
          fill="#D0EBF6"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
      );
      break;
    }
    case "beige": {
      paper = (
        <rect
          width={rectWidth}
          height={rectHeight}
          rx={rectRadius}
          fill="#FFF6EE"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          style={
            selected
              ? {}
              : {
                  filter: "drop-shadow( 0 0 1px #bf8654)",
                }
          }
        />
      );
      break;
    }
    case "plaid": {
      paper = (
        <>
          <rect
            x="2"
            y="2"
            width="51"
            height="38"
            rx="8"
            fill="#A51C35"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
          <mask
            id="plaid_choice_mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="47"
            height="34"
          >
            <rect x="4" y="4" width="47" height="34" rx="6" fill="black" />
          </mask>
          <g mask="url(#plaid_choice_mask0)">
            <mask
              id="plaid_choice_mask1"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="-1"
              width="55"
              height="43"
            >
              <rect
                x="0.5"
                y="-0.5"
                width="54"
                height="42"
                rx="9.5"
                fill="#CF425C"
                stroke="black"
              />
            </mask>
            <g mask="url(#plaid_choice_mask1)">
              <g style={style} opacity="0.8">
                <rect
                  x="8.72998"
                  y="-4.97876"
                  width="11.9045"
                  height="46.9787"
                  rx="5.95227"
                  fill="#35393D"
                />
              </g>
              <g style={style} opacity="0.8">
                <path
                  d="M23.6111 0.973508C23.6111 -2.31384 26.276 -4.97876 29.5634 -4.97876C32.8507 -4.97876 35.5156 -2.31384 35.5156 0.973515V36.0477C35.5156 39.335 32.8507 41.9999 29.5634 41.9999C26.276 41.9999 23.6111 39.335 23.6111 36.0477V0.973508Z"
                  fill="#35393D"
                />
              </g>
              <g style={style} opacity="0.8">
                <rect
                  x="46.6765"
                  y="-5.97876"
                  width="11.9045"
                  height="46.9787"
                  rx="5.95227"
                  fill="#35393D"
                />
              </g>
              <g style={style} opacity="0.8">
                <rect
                  x="71.9734"
                  y="9.22021"
                  width="11.0538"
                  height="84.0759"
                  rx="5.52691"
                  transform="rotate(90 71.9734 9.22021)"
                  fill="#35393D"
                />
              </g>
              <g style={style} opacity="0.8">
                <rect
                  x="71.9734"
                  y="30.9463"
                  width="11.0538"
                  height="84.0759"
                  rx="5.52691"
                  transform="rotate(90 71.9734 30.9463)"
                  fill="#35393D"
                />
              </g>
            </g>
          </g>
        </>
      );
      break;
    }
    default: {
      paper = <rect width="56" height="42" rx="10" fill="#CF425C" />;
      break;
    }
  }

  return (
    <svg
      width="70"
      height="65"
      viewBox="-8 -8 70 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {paper}
      {selected && (
        <path
          d="M49 34C43.48 34 39 38.48 39 44C39 49.52 43.48 54 49 54C54.52 54 59 49.52 59 44C59 38.48 54.52 34 49 34ZM46.29 48.29L42.7 44.7C42.31 44.31 42.31 43.68 42.7 43.29C43.09 42.9 43.72 42.9 44.11 43.29L47 46.17L53.88 39.29C54.27 38.9 54.9 38.9 55.29 39.29C55.68 39.68 55.68 40.31 55.29 40.7L47.7 48.29C47.32 48.68 46.68 48.68 46.29 48.29Z"
          fill="#7EC2E4"
        />
      )}
    </svg>
  );
};

export default PaperIcon;
