import React from "react";
import { createGlobalStyle } from "styled-components";

import { useSiteContext } from "src/contexts/SiteContext";
import { useEasterEgg } from "src/utils/useEasterEgg";

const EasterEggStyles = createGlobalStyle`
  html * {
    font-family: "Comic Sans MS" !important;
  }
`;

const EasterEggStyle = () => {
  const { easterEggActive } = useSiteContext();
  useEasterEgg();

  return <>{easterEggActive && <EasterEggStyles />}</>;
};

export default EasterEggStyle;
