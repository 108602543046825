import { useAuth } from "@hackthenorth/north";
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import {
  RouteName,
  getFurthestVisitedRoute,
  PROTECTED_ROUTES,
} from "src/constants/route";
import { ResponseContext } from "src/contexts/ResponseContext/ResponseContext";
import { useUserContext } from "src/contexts/UserContext";
import { useDeviceSize } from "src/utils/useDeviceSize";

export type TSiteContextValue = {
  /**
   * Information about every step in the flow.
   */
  steps: Record<RouteName, { visited: boolean; current: boolean }>;
  visitedSteps: RouteName[];
  isMobile: boolean;

  /**
   * Used to determine if a route is visitable given the current state of the site and the hacker application.
   */
  canVisitRoute: (step: RouteName | null) => boolean;
  navigate: (route: RouteName) => void;

  shouldIncreasePerformance: boolean;
  boatPosition: number;
  setBoatPosition: React.Dispatch<React.SetStateAction<number>>;
  boatQuote: React.ReactNode;
  setBoatQuote: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  easterEggActive: boolean;
  toggleEasterEgg: () => void;
};

export const SiteContext: React.Context<TSiteContextValue> = createContext(
  undefined as unknown as TSiteContextValue
);

/*
  Position 0 means the boat is not displayed on the DOM
  Position 1 means the boat is off screen (will animate on/off)
*/
const ROUTE_TO_BOAT_POSITION: Record<RouteName, number> = {
  [RouteName.LANDING]: 0,
  [RouteName.BUILD_A_BOAT]: 1,
  [RouteName.SHORT_ANSWER]: 1,
  [RouteName.SHORT_ANSWER_NAME]: 3,
  [RouteName.SHORT_ANSWER_GRAD]: 4,
  [RouteName.SHORT_ANSWER_SCHOOL]: 5,
  [RouteName.SHORT_ANSWER_PROGRAM]: 6,
  [RouteName.SHORT_ANSWER_LOCATION]: 7,
  [RouteName.SHORT_ANSWER_EXPERIENCE]: 8,
  [RouteName.CREATE_ACCOUNT]: 3,
  [RouteName.LONG_ANSWER]: 4,
  [RouteName.SURVEY]: 5,
  [RouteName.REVIEW]: 5,
  [RouteName.APPLICATION_SUBMITTED]: 5,
  [RouteName.PLAYGROUND]: 0,
  [RouteName.NOT_FOUND]: 0,
  [RouteName.BLANK]: 0,
};

/**
 * A Context for site related stuff
 */
export const SiteContextProvider: React.FC = ({ children, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { visitedSteps, setVisitedSteps, isApplicationSubmitted } =
    useContext(ResponseContext);

  const [didValidateRoute, setDidValidateRoute] = useState(false);
  const [easterEggActive, setEasterEggActive] = useState(false);
  const toggleEasterEgg = useCallback(
    () => setEasterEggActive((prev) => !prev),
    []
  );

  const [boatPosition, setBoatPosition] = useState<number>(0);
  const [boatQuote, setBoatQuote] = useState<React.ReactNode>(null);

  const isMobile = !!useDeviceSize("tablet");

  const { tokenExpired } = useAuth();

  const curRoute = Object.values(RouteName).find(
    (route) => location.pathname === route
  ) as RouteName | undefined;

  const steps = Object.values(RouteName).reduce((acc, key) => {
    acc[key] = {
      visited: visitedSteps.some((step) => step.startsWith(key as RouteName)),
      current: Boolean(curRoute?.startsWith(key)),
    };
    return acc;
  }, {}) as TSiteContextValue["steps"];

  const canVisitRoute = useCallback<TSiteContextValue["canVisitRoute"]>(
    (step) => {
      if (step === null) return true; // assuming routes that are not tracked as steps are always public
      if (visitedSteps.includes(step)) return true;

      return false;
    },
    [visitedSteps]
  );

  const handleBoatNavigate = (route: RouteName) => {
    if (route !== RouteName.BLANK) {
      setBoatPosition(ROUTE_TO_BOAT_POSITION[route]);
    }

    // this is set on the page
    if (route !== RouteName.SHORT_ANSWER_EXPERIENCE) {
      setBoatQuote(null);
    }
  };

  const internalNavigate = (route: RouteName) => {
    setVisitedSteps((prev) => Array.from(new Set([...prev, route])));
    navigate(route);
  };

  const shouldIncreasePerformance = location.pathname === "/alternative-form";

  useEffect(() => {
    if (curRoute) {
      handleBoatNavigate(curRoute);
    }
  }, [curRoute]);

  useEffect(() => {
    if (tokenExpired) {
      console.error(
        "hElLo iTs yOuR ToKeN HeRe, wArNiNg i dOnT FeEl sO GoOd!! i tHiNk iM eXpIrING 🤢"
      );
      // todo: i don't like the wording of "re-focus", reword it maybe
      toast.error("Token expired. Please re-focus the page to continue.");
    }
  }, [tokenExpired]);

  useEffect(() => {
    if (isApplicationSubmitted === undefined) return;

    const isRouteProtected = curRoute && PROTECTED_ROUTES.includes(curRoute);

    /**
     * If the user has submitted their app, only allow them to access review & submitted pages
     */
    if (isApplicationSubmitted) {
      if (
        isRouteProtected &&
        curRoute !== RouteName.REVIEW &&
        curRoute !== RouteName.APPLICATION_SUBMITTED
      ) {
        internalNavigate(RouteName.APPLICATION_SUBMITTED);
      }
    } else {
      /**
       * If the route is valid, part of the flow and hasn't been visited,
       *    redirect them to the furthest protected route they've been to
       */
      if (isRouteProtected && !canVisitRoute(curRoute as RouteName)) {
        const furthestRoute = getFurthestVisitedRoute(visitedSteps);

        // UX IMPROVEMENT: Renders a page indicating the users they're about to get redirected
        if (furthestRoute) {
          internalNavigate(furthestRoute);
        } else {
          internalNavigate(RouteName.LANDING);
        }
      }
    }

    setDidValidateRoute(true);
  }, [isApplicationSubmitted]);

  return (
    <SiteContext.Provider
      value={{
        steps,
        isMobile,
        visitedSteps,
        navigate: internalNavigate,
        canVisitRoute,
        shouldIncreasePerformance,
        boatPosition,
        setBoatPosition,
        boatQuote,
        setBoatQuote,
        easterEggActive,
        toggleEasterEgg,
      }}
      {...rest}
    >
      {/* TODO: Replace with a spinner */}
      {didValidateRoute ? children : <div />}
    </SiteContext.Provider>
  );
};

export const useSiteContext = () => useContext(SiteContext);
