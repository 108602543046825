import React from "react";
import { OptionTypeBase } from "react-select";
import styled from "styled-components";

import {
  ShortAnswerQuestion,
  DropdownTagInput,
  ShortAnswerPage,
  InkTransition,
  TextInput,
  Spacer,
  TexturedAsset,
} from "src/components";
import { SCHOOL_OPTIONS, SCHOOL_TO_MASCOT } from "src/constants/copy";
import { RouteName } from "src/constants/route";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import StorybookPaginator from "src/contexts/StorybookContext/Paginator";
import { TStorybookPageProps } from "src/contexts/StorybookContext/Storybook/constants";
import { TextureUrl } from "src/static";
import { answerToOption, answersToOptions } from "src/utils/react-select";

import AssetPage from "../AssetPage";
import { ShortAnswerMobileSVGStyles } from "../GradPage";
import { BodyText } from "../NamePage";

const MascotWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  ${({ isMobile }) =>
    isMobile &&
    `
    transform: translateX(25%) translateY(-8%) scale(0.9);
    max-width: 400px;
    margin-left: auto;
  `}
  ${ShortAnswerMobileSVGStyles}
`;

const School: React.FC<TStorybookPageProps> = (props) => {
  const { isMobile } = useSiteContext();

  const {
    responses: { short_school, short_grad_level },
    saveResponses,
  } = useResponse();

  const { value, setValue } = short_school;

  // implement
  const { onTurnNext } = useStorybookContext();
  const Mascot = SCHOOL_TO_MASCOT[value] ?? SCHOOL_TO_MASCOT["Other"];
  const hasContent = value !== null && value !== "" && value !== undefined;
  const isHighSchoolStudent = short_grad_level.value == "high school";

  return (
    <>
      <AssetPage {...props}>
        {hasContent ? (
          <InkTransition>
            <TexturedAsset texture={TextureUrl}>
              <MascotWrapper isMobile={isMobile}>
                <Mascot />
              </MascotWrapper>
            </TexturedAsset>
          </InkTransition>
        ) : null}
      </AssetPage>
      <ShortAnswerPage {...props}>
        <ShortAnswerQuestion
          showButton={hasContent}
          buttonText={
            value === "University of Waterloo" ? "Go Warriors!" : "Continue"
          }
        >
          <BodyText>This September, I will be attending</BodyText>
          {isMobile ? <Spacer height={24} /> : <Spacer height={46} />}
          {isHighSchoolStudent ? (
            <TextInput
              shortAnswer
              focusOnFlip
              route={RouteName.SHORT_ANSWER_SCHOOL}
              placeholder="My school name"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e: any) => {
                if (
                  e.key === "Enter" &&
                  hasContent &&
                  location.pathname == RouteName.SHORT_ANSWER_SCHOOL
                ) {
                  onTurnNext();
                }
              }}
              tabIndex={0}
            />
          ) : (
            <DropdownTagInput
              shortAnswer
              creatable
              isSearchable
              placeholder="My school name"
              value={value ? answerToOption(value) : undefined}
              onChange={(option: OptionTypeBase) => setValue(option.value)}
              options={answersToOptions(SCHOOL_OPTIONS)}
              onKeyDown={(e: any) => {
                if (
                  e.key === "Enter" &&
                  hasContent &&
                  location.pathname == RouteName.SHORT_ANSWER_SCHOOL
                ) {
                  onTurnNext();
                }
              }}
              tabIndex={0}
              focusOnFlip
              route={RouteName.SHORT_ANSWER_SCHOOL}
            />
          )}
        </ShortAnswerQuestion>
        {isMobile && <StorybookPaginator />}
      </ShortAnswerPage>
    </>
  );
};

export default School;
