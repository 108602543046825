import React, { ComponentPropsWithoutRef } from "react";
import { styled } from "twin.macro";

import { Link, Text, TexturedAsset } from "src/components";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import {
  LocationImg,
  ExperiencedHackerImg,
  FirstTimeHackerImg,
  LogoImg,
  GradientImg,
  TextureUrl,
} from "src/static";

type Props = ComponentPropsWithoutRef<"div">;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontSize.h1D}px;
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
  position: relative;
  top: 34%;
  transform: translate(50%, 0%);
`;

const StyledSubtitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.body}px;
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
  position: relative;
  top: 37%;
  transform: translate(50%, 0%);
  text-align: center;
  width: 460px;
`;

const ClickAnywhereToContinueWrapper = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.body}px;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
  position: relative;
  top: 50%;
  transform: translate(50%, 0%);
  animation: fadeIn linear 3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 90px;
  right: -56px;
  pointer-events: none;
`;

const BackgroundGradient = styled.img`
  position: absolute;
  width: 1366px;
  height: auto;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
`;

const LocationAssetWrapper = styled.div`
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  bottom: -20px;
  left: -265px;
  width: 700px;
  height: auto;
  opacity: 0.4;
  pointer-events: none;
`;

const ExperienceAssetWrapper = styled.div`
  position: absolute;
  bottom: -145px;
  left: 145px;
  width: 490px;
  height: auto;
  pointer-events: none;
`;

const FirstTimeAssetWrapper = styled.div`
  position: absolute;
  bottom: -152px;
  left: 160px;
  width: 416px;
  height: auto;
  pointer-events: none;
`;

const ThanksLeft: React.FC<Props> = ({ children, ...rest }) => {
  const {
    responses: { short_hackathon_count },
  } = useResponse();
  return (
    <Container {...rest}>
      <BackgroundGradient src={GradientImg} />
      <LogoWrapper>
        <LogoImg />
      </LogoWrapper>
      <StyledTitle>Application Sent! You&apos;re all set.</StyledTitle>
      <StyledSubtitle>
        If you don&apos;t receive a confirmation email within the next 10
        minutes, please reach out to us at hello@hackthenorth.com.
      </StyledSubtitle>
      <ClickAnywhereToContinueWrapper>
        Click anywhere to continue
      </ClickAnywhereToContinueWrapper>

      <LocationAssetWrapper>
        <TexturedAsset texture={TextureUrl}>
          <LocationImg />
        </TexturedAsset>
      </LocationAssetWrapper>

      {short_hackathon_count.value === "0" ? (
        <FirstTimeAssetWrapper>
          <TexturedAsset texture={TextureUrl}>
            <FirstTimeHackerImg />
          </TexturedAsset>
        </FirstTimeAssetWrapper>
      ) : (
        <ExperienceAssetWrapper>
          <TexturedAsset texture={TextureUrl}>
            <ExperiencedHackerImg />
          </TexturedAsset>
        </ExperienceAssetWrapper>
      )}
    </Container>
  );
};

export default ThanksLeft;
