import { useAuth } from "@hackthenorth/north";
import React, { useEffect, useCallback } from "react";
import tw, { styled } from "twin.macro";

import { Text, Button, Flex } from "src/components";
import { TOTAL_HEADER_HEIGHT_PX } from "src/components/Header";
import { BaseRoute } from "src/constants/route";
import { useUserContext } from "src/contexts";
import { useSiteContext } from "src/contexts/SiteContext";
import { deviceBreakpoints, mediaQueries } from "src/utils/responsive";

const CreateAccountStage: React.FC = () => {
  const { isAuthenticated, logIn, signUp } = useUserContext();
  const { isMobile, navigate } = useSiteContext();

  const goToNextStep = useCallback(
    () => (isAuthenticated ? navigate(BaseRoute.LONG_ANSWER) : signUp()),
    [isAuthenticated]
  );

  const goToNextIfKeyPressed = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        goToNextStep();
      }
    },
    [goToNextStep]
  );

  useEffect(() => {
    window.addEventListener("keypress", goToNextIfKeyPressed);
    return () => window.removeEventListener("keypress", goToNextIfKeyPressed);
  }, [goToNextIfKeyPressed]);

  return (
    <Background isMobile={isMobile}>
      <WhiteContainer>
        <div tw="h-full w-full flex flex-col justify-evenly items-center lm:justify-start gap-y-28">
          <HeaderContainer>
            <HeaderText>
              {isAuthenticated
                ? "Continue writing your story."
                : "Continue your story by creating an account."}
            </HeaderText>
          </HeaderContainer>
          <TextContainer column>
            <InformationText>
              {isAuthenticated
                ? "Thank you for creating an account."
                : "Thank you for telling us about yourself!"}
            </InformationText>
            <InformationText>
              {isAuthenticated
                ? "Your information has been saved!"
                : "Create an account so you can return to your application when you want to."}
            </InformationText>
          </TextContainer>
          <Flex column>
            <StyledButton onClick={goToNextStep}>
              {isAuthenticated
                ? "Go to application questions"
                : "Create my account"}
            </StyledButton>
            {!isAuthenticated && (
              <Flex row>
                <InformationText>Already have an account?</InformationText>
                <Button link tw="leading-loose" onClick={() => logIn()}>
                  Log in
                </Button>
              </Flex>
            )}
          </Flex>
        </div>
      </WhiteContainer>
    </Background>
  );
};

const InformationText = styled(Text)`
  font-size: 16px;
  line-height: 180%;
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

const TextContainer = styled(Flex)`
  max-width: 600px;
  text-align: center;
`;

const HeaderText = styled(Text)`
  color: ${({ theme }) => theme.color.text.blue};
  line-height: 120%;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontSize.h1D}px;
  ${mediaQueries.largeMobile} {
    font-size: ${({ theme }) => theme.fontSize.h3M}px;
  }
`;

const HeaderContainer = styled.div`
  max-width: 500px;
  ${mediaQueries.largeMobile} {
    max-width: 300px;
  }
`;

const StyledButton = styled(Button)`
  height: 60px;
  margin-bottom: 15px;
`;

export const WhiteContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  height: 80%;
  position: relative;
  padding-top: 32px;
  min-width: 80%;
  max-width: ${deviceBreakpoints.medium}px;
  border-radius: 16px;
`;

export const ChildrenWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
`;

export const Background = styled.div<{ isMobile: boolean }>`
  background-size: cover;
  ${({ isMobile }) =>
    !isMobile && `height: calc(100vh - ${TOTAL_HEADER_HEIGHT_PX}px);`}
  display: flex;
  justify-content: center;
  align-content: center;
`;

export default CreateAccountStage;
