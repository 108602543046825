import { keyframes } from "styled-components";

export const float = keyframes`
  0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-8px);
	}
	100% {
		transform: translatey(0px);
	}
`;

export const wave = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -689px;
  }
`;

export const waveMobile = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -187px;
  }
`;

export const waveSwell = keyframes`
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
`;

export const moveclouds = keyframes`
  0% {
    transform: translatex(1000px);
  }
  100% {
    transform: translatex(-1000px);
  }
`;

export const mobileBoatBob = keyframes`
 0% {
  transform: translateY(-10%) translateX(0%);
 }
 50% {
  transform: translateY(-5%) translateX(0%);
 }
 100% {
  transform: translateY(-10%) translateX(0%);
 }
`;

export const boatBounce = keyframes`
  0%, 50%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -moz-transform: translateY(-35px);
    -ms-transform: translateY(-35px);
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
  }

  66% {
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }
`;
