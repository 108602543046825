import React from "react";
import ReactTooltip from "react-tooltip";
import tw, { styled } from "twin.macro";

import { Spacer, Text, Icon } from "src/components";
import { SURVEY_QUESTION_TITLES } from "src/constants/copy";
import { default as HelpUrl } from "src/static/icons/help.svg";
import { mediaQueries } from "src/utils/responsive";
import { useSiteContext } from "src/contexts/SiteContext";

export type TSurveyQuestionProps = {
  children: React.ReactNode;
  step: number;
  showSaved: boolean;
  subtitle?: string;
  tooltip?: string;
};

type TitleTextProps = {
  step: number;
};

const Container = styled.div`
  padding-top: 48px;

  ${mediaQueries.tablet} {
    top: 35%;
  }
`;

const StatusText = styled(Text)`
  color: ${({ theme }) => theme.color.secondary.green};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled(Text)<TitleTextProps>`
  ${mediaQueries.tablet} {
    width: ${({ step }) => (step === 1 || step === 2) && "90%"};
  }
`;

const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.color.primary.blue2};
`;

const TooltipText = styled(Text)`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.label}px;
  padding: 8px 0;
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: 16px !important;
  opacity: 1 !important;
  width: 280px;
`;

const HelpIcon = styled.span`
  background: url(${HelpUrl});
  width: 24px;
  height: 24px;
`;

export default function SurveyQuestion({
  step,
  showSaved,
  children,
  subtitle,
  tooltip,
}: TSurveyQuestionProps) {
  const title = SURVEY_QUESTION_TITLES[step];
  const { isMobile } = useSiteContext();

  return (
    <Container>
      <TitleContainer>
        <TitleText step={step} heading size={isMobile ? "h3M" : "h3D"} element="h2">
          {title}
        </TitleText>
        {tooltip && (
          <>
            <HelpIcon data-tip data-for={step.toString()} tw="mt-4 ml-4" />
            <StyledTooltip
              id={step.toString()}
              place="top"
              type="dark"
              effect="solid"
            >
              <TooltipText>{tooltip}</TooltipText>
            </StyledTooltip>
          </>
        )}

        <Spacer width="16px" />
        {showSaved ? (
          <>
            <StatusText>Saved</StatusText>
            <Spacer width="6px" />
            <Icon name="checkmark" />
          </>
        ) : null}
      </TitleContainer>
      {subtitle && (
        <>
          <Spacer height="16px" />
          <Subtitle>{subtitle}</Subtitle>
        </>
      )}
      <Spacer height={16} />
      {children}
    </Container>
  );
}
