import React from "react";
import { Textfit } from "safe-react-textfit";
import styled from "styled-components";

import { Text, TTextProps } from "src/components";

export type TResponsiveTextProps = TTextProps & {
  isMultiLine?: boolean;
  width?: number | string | boolean;
  height?: number | string | boolean;
};

const StyledText = styled(Text)<Omit<TResponsiveTextProps, "isMultiLine">>`
  width: ${({ width }) => getSize(width)};
  min-width: ${({ width }) => getSize(width)};
  height: ${({ height }) => getSize(height)};
  min-height: ${({ height }) => getSize(height)};
`;

const StyledTextFit = styled(Textfit)`
  height: 100%;
`;

const getSize = (size?: number | string | boolean) => {
  if (typeof size === "number") {
    return `${size}px`;
  } else if (typeof size === "string") {
    return size;
  } else if (size) {
    return "100%";
  } else {
    return "0";
  }
};

const ResponsiveText: React.FC<TResponsiveTextProps> = ({
  isMultiLine = false,
  children,
  ...rest
}) => (
  <StyledText {...rest}>
    <StyledTextFit mode={isMultiLine ? "multi" : "single"}>
      {children}
    </StyledTextFit>
  </StyledText>
);

export default ResponsiveText;
