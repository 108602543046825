import { ApolloProvider } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { configureAnalyticsLogger } from "@hackthenorth/analytics";
import { AuthProvider } from "@hackthenorth/north";
import React, { memo } from "react";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";

import { AppContent, QA, NotFound, EasterEgg, Expired } from "src/components";
import {
  ThemeContextProvider,
  UserContextProvider,
  SurveyScrollContextProvider,
  SiteContextProvider,
} from "src/contexts";
import { ResponseContextProvider } from "src/contexts/ResponseContext/ResponseContext";
import { client } from "src/services/api/client";
import { APP_ENV, IS_PRODUCTION } from "src/utils/env";

const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY as string;

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

configureAnalyticsLogger({
  url: `https://${
    IS_PRODUCTION ? "api" : "staging-api"
  }.hackthenorth.com/analytics`,
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React); // eslint-disable-line

const INDICATOR_COLOR: string = {
  production: "green",
  staging: "yellow",
  development: "grey",
}[APP_ENV];

const Indicator = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 4px;
  z-index: 1000;
  background-color: ${INDICATOR_COLOR};
`;

const EnvIndicator = memo(function EnvIndicator() {
  return APP_ENV === "production" ? null : <Indicator />;
});

const areAppsExpired =
  IS_PRODUCTION &&
  window.location.hostname !== "2021-apply-hackthenorth-com.netlify.app" &&
  false;

function App() {
  return (
    <ThemeContextProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={NotFound}>
            {areAppsExpired ? (
              <Expired />
            ) : (
              <AuthProvider production={IS_PRODUCTION}>
                <UserContextProvider>
                  <ResponseContextProvider>
                    <SurveyScrollContextProvider>
                      <SiteContextProvider>
                        <EasterEgg />
                        <AppContent />
                        <EnvIndicator />
                        <QA />
                      </SiteContextProvider>
                    </SurveyScrollContextProvider>
                  </ResponseContextProvider>
                </UserContextProvider>
              </AuthProvider>
            )}
          </ErrorBoundary>
        </BrowserRouter>
      </ApolloProvider>
    </ThemeContextProvider>
  );
}

export default App;
