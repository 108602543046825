import React, { ComponentPropsWithoutRef } from "react";

type TAssetPageProps = ComponentPropsWithoutRef<"div">;

const PlaceholderPage: React.FC<TAssetPageProps> = ({
  children,
  style,
  ...rest
}) => {
  return <div style={{ background: "#F6F4F2", ...style }} {...rest}></div>;
};

export default PlaceholderPage;
