import React, { useEffect } from "react";
import { OptionTypeBase } from "react-select";
import styled from "styled-components";

import {
  ShortAnswerQuestion,
  DropdownTagInput,
  Flex,
  Spacer,
  ShortAnswerPage,
  InkTransition,
  TexturedAsset,
} from "src/components";
import { HACKATHON_COUNT_OPTIONS } from "src/constants/copy";
import { RouteName } from "src/constants/route";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { useStorybookContext } from "src/contexts/StorybookContext";
import StorybookPaginator from "src/contexts/StorybookContext/Paginator";
import { TStorybookPageProps } from "src/contexts/StorybookContext/Storybook/constants";
import {
  ExperiencedHackerImg,
  FirstTimeHackerImg,
  FirstTimeHackerMobileImg,
  TextureUrl,
} from "src/static";
import { answerToOption, answersToOptions } from "src/utils/react-select";
import { mediaQueries } from "src/utils/responsive";

import AssetPage from "../AssetPage";
import { ShortAnswerMobileSVGStyles } from "../GradPage";
import { BodyText } from "../NamePage";

const ExperienceAssetWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  ${({ isMobile }) =>
    isMobile &&
    `
    transform: translateX(23%) translateY(9%) scale(1.1);
    max-width: 400px;
    margin-left: auto;
  `}
  ${ShortAnswerMobileSVGStyles}
  ${mediaQueries.largeMobile} {
    width: 350px;
  }
`;

const StyledDropdownTagInput = styled(DropdownTagInput)`
  width: 135px;
  ${mediaQueries.largeMobile} {
    width: 100px;
  }
`;

const FIRST_TIME_HACKER_BOAT_QUOTE = (
  <div style={{ width: "370px" }}>
    No sweat! <b>1 in 3</b> of our hackers also started their journey here at
    Hack the North
  </div>
);

const Experience: React.FC<TStorybookPageProps> = (props) => {
  const {
    responses: { short_hackathon_count },
    saveResponses,
  } = useResponse();

  const { value, setValue } = short_hackathon_count;

  const { isMobile, setBoatQuote } = useSiteContext();

  const { curPage, onTurnNext } = useStorybookContext();

  const hasContent = value !== null && value !== "" && value !== undefined;

  useEffect(() => {
    setBoatQuote(
      value === "0" && curPage === 16 ? FIRST_TIME_HACKER_BOAT_QUOTE : null
    );
  }, [value, curPage]);

  return (
    <>
      <AssetPage {...props}>
        {hasContent ? (
          <InkTransition>
            <TexturedAsset texture={TextureUrl}>
              <ExperienceAssetWrapper isMobile={isMobile}>
                {/* need to fix jumpy effect by changing svgs */}
                {value === "0" ? (
                  isMobile ? (
                    <FirstTimeHackerMobileImg
                      style={{
                        transform:
                          "scale(1.38) translateY(-11.2%) translateX(-1.5%)",
                      }}
                    />
                  ) : (
                    <FirstTimeHackerImg
                      style={{ maxHeight: "616px", margin: "auto" }}
                    />
                  )
                ) : (
                  <ExperiencedHackerImg />
                )}
              </ExperienceAssetWrapper>
            </TexturedAsset>
          </InkTransition>
        ) : null}
      </AssetPage>
      <ShortAnswerPage {...props}>
        <ShortAnswerQuestion showButton={hasContent} buttonText="Awesome!">
          <BodyText>Oh, and I have been to</BodyText>
          {isMobile ? <Spacer height={24} /> : <Spacer height={46} />}
          <Flex row={isMobile}>
            <StyledDropdownTagInput
              shortAnswer
              placeholder="# of"
              value={value ? answerToOption(value) : undefined}
              onChange={(option: OptionTypeBase) => setValue(option.value)}
              options={answersToOptions(HACKATHON_COUNT_OPTIONS)}
              onKeyDown={(e: any) => {
                if (
                  e.key === "Enter" &&
                  hasContent &&
                  location.pathname == RouteName.SHORT_ANSWER_EXPERIENCE
                ) {
                  onTurnNext();
                }
              }}
              tabIndex={0}
              focusOnFlip
              route={RouteName.SHORT_ANSWER_EXPERIENCE}
            />
            <Spacer width={21} />
            <BodyText>hackathon{value !== "1" && "s"}!</BodyText>
          </Flex>
        </ShortAnswerQuestion>
        {isMobile && <StorybookPaginator />}
      </ShortAnswerPage>
    </>
  );
};

export default Experience;
