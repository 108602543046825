import React, { useState } from "react";
import tw, { styled, css } from "twin.macro";

import {
  HorizontalRule,
  Flex,
  Spacer,
  Text,
  GradientText,
  Button,
} from "src/components";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { mediaQueries } from "src/utils/responsive";
import { EditIcon } from "src/static/icons";

type TFullReviewSectionProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "children"
> & {
  title: string;
  onSave: () => void;
  onCancel: () => void;
  saveDisabled?: boolean;
  children: (isEditing: boolean) => React.ReactNode;
};

type TButtonType = "edit" | "save" | "cancel";

const Title = styled(GradientText).attrs({
  element: "h2",
  size: "body",
  gradient: "gradientRedBlue172",
})`
  font-weight: normal;
`;

const StyledFlex = styled(Flex)`
  ${mediaQueries.smallMobile} {
    flex-direction: column;
    align-items: start;
  }
`;

const ButtonText = styled(Text).attrs({
  size: "body",
})<{ buttonType: TButtonType }>`
  color: ${({ theme, buttonType }) =>
    buttonType === "save" ? theme.color.white : theme.color.primary.blue1};
  font-weight: normal;
`;

const FullReviewSection: React.FC<TFullReviewSectionProps> = ({
  title,
  onSave,
  onCancel,
  saveDisabled = false,
  children,
}) => {
  const { isApplicationSubmitted } = useResponse();
  const [isEditing, setIsEditing] = useState(false);

  const onCancelClick = () => {
    onCancel();
    setIsEditing(false);
  };

  const onSaveClick = () => {
    onSave();
    setIsEditing(false);
  };

  return (
    <>
      <HorizontalRule />
      <Spacer height={36} />
      <Flex column>
        <StyledFlex justify="space-between" align="center">
          <Title>{title}</Title>
          {!isApplicationSubmitted
            ? !isEditing && (
                <Button link onClick={() => setIsEditing(true)}>
                  <Flex row align="center">
                    <Text link tw="mr-8">
                      Edit
                    </Text>
                    <EditIcon />
                  </Flex>
                </Button>
              )
            : null}
        </StyledFlex>
        <Spacer height={24} />
        {typeof children === "function" ? children(isEditing) : children}
        <Spacer height={24} />
        {!isApplicationSubmitted
          ? isEditing && (
              <Flex justify="flex-end">
                <Button tw="px-48 py-16" secondary onClick={onCancelClick}>
                  <ButtonText buttonType="cancel">Cancel</ButtonText>
                </Button>
                <Spacer width={16} />
                <Button
                  tw="px-48 py-16"
                  onClick={onSaveClick}
                  disabled={saveDisabled}
                >
                  <ButtonText buttonType="save">Save</ButtonText>
                </Button>
              </Flex>
            )
          : null}
      </Flex>
    </>
  );
};

export default FullReviewSection;
