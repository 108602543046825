import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { OptionTypeBase, OptionsType } from "react-select";
import tw, { styled } from "twin.macro";

import {
  CheckBox,
  HorizontalRule,
  Spacer,
  Text,
  Flex,
  SurveyQuestion,
  TagInput,
  DropdownTagInput,
  TextInput,
  Button,
} from "src/components";
import {
  Background,
  WhiteContainer,
  ChildrenWrapper,
} from "src/components/LongAnswerPage";
import {
  GENDER_OPTIONS,
  RACE_OPTIONS,
  ETHNICITY_OPTIONS,
  YES_NO_OPTIONS,
  EXPERIENCE_OPTIONS,
  UNDERREP_GROUPS_OPTIONS,
  TECHNOLOGY_OPTIONS,
  WORKSHOP_OPTIONS,
} from "src/constants/copy";
import { RouteName } from "src/constants/route";
import { useResponse } from "src/contexts/ResponseContext/ResponseContext";
import { useSiteContext } from "src/contexts/SiteContext";
import { BackArrowIcon } from "src/static/icons";
import { answersToOptions, optionsToAnswers } from "src/utils/react-select";

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CheckboxRow = styled(Flex)`
  margin-top: 20px;
`;

const CheckboxText = styled(Text)`
  color: ${({ theme }) => theme.color.primary.blue1};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize.body};
  margin-left: 15px;
`;

const Subtitle = styled(Text).attrs({
  size: "bodyL",
})`
  font-weight: 500;
  color: ${({ theme }) => theme.color.primary.blue1};
`;

const StyledLink = styled.a`
  font-weight: 700;
  color: ${({ theme }) => theme.color.primary.blue2};
`;

const RACE_TOOLTIP =
  "Racial identity refers to the set of physical appearances common to a group of people such as skin colour, facial features, and hair texture.";
const ETHNICITY_TOOLTIP =
  "Ethnicity refers to the shared languages, traditions, and cultural heritage of a group of people from a given geographic region.";
const TECHNOLOGY_MAX = 3;
const WORKSHOP_MAX = 3;
const OPTIONS_MAX_COPY =
  "You've selected the maximum number of options! To choose another, please unselect an existing one.";

const SURVEY_BOAT_QUOTE = "We're almost there!";

const SurveyStage: React.FC = () => {
  const {
    responses: {
      survey_gender,
      survey_race,
      survey_ethnicity,
      survey_education,
      survey_personal_experience,
      survey_family_experience,
      survey_groups,
      survey_underrep_groups,
      survey_tech_preferences,
      survey_workshop_preferences,
    },
    saveResponses,
  } = useResponse();

  const { value: selectedGender, setValue: setSelectedGender } = survey_gender;
  const { value: selectedRace, setValue: setSelectedRace } = survey_race;
  const { value: selectedEthnicity, setValue: setSelectedEthnicity } =
    survey_ethnicity;
  const { value: selectedEducation, setValue: setSelectedEducation } =
    survey_education;
  const {
    value: selectedPersonalExperience,
    setValue: setSelectedPersonalExperience,
  } = survey_personal_experience;
  const {
    value: selectedFamilyExperience,
    setValue: setSelectedFamilyExperience,
  } = survey_family_experience;
  const { value: selectedGroups, setValue: setSelectedGroups } = survey_groups;
  const { value: selectedUnderrepGroups, setValue: setSelectedUnderrepGroups } =
    survey_underrep_groups;
  const { value: selectedTechnology, setValue: setSelectedTechnology } =
    survey_tech_preferences;
  const { value: selectedWorkshops, setValue: setSelectedWorkshops } =
    survey_workshop_preferences;

  const { isMobile, navigate, setBoatQuote } = useSiteContext();
  const [addingTechnology, setAddingTechnology] = useState(false);
  const [addingWorkshop, setAddingWorkshop] = useState(false);
  const [userTechnology, setUserTechnology] = useState<string[]>(
    selectedTechnology.filter(
      (option: string) => !TECHNOLOGY_OPTIONS.includes(option)
    )
  );
  const [userWorkshops, setUserWorkshops] = useState<string[]>(
    selectedWorkshops.filter(
      (option: string) => !WORKSHOP_OPTIONS.includes(option)
    )
  );

  const [didFocus, setDidFocus] = useState({
    gender: false,
    underrep: false,
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  setBoatQuote(SURVEY_BOAT_QUOTE);

  return (
    <Background isMobile={isMobile}>
      <WhiteContainer>
        <ChildrenWrapper>
          <ReactRouterLink to={RouteName.LONG_ANSWER}>
            <Flex row align="center">
              <BackArrowIcon />
              <Text link tw="ml-16">
                {" "}
                Write my story
              </Text>
            </Flex>
          </ReactRouterLink>
          <Spacer height={24} />
          <Text heading size="h1D">
            Survey questions (optional)
          </Text>
          <Spacer height={36} />
          <Subtitle>
            We want to understand who our hackers are so that we do our best to
            help them succeed at Hack the North. This includes eliminating
            biases from our application process, setting goals and measuring our
            progress towards them, as well as making educated decisions so that
            Hack the North is a great experience for everyone.
          </Subtitle>
          <Spacer height={36} />
          <Subtitle>
            The following <span tw="font-bold">optional</span> survey questions
            are used only for aggregate reporting and{" "}
            <span tw="font-bold">will not have any effect</span> on your
            application. For more information on how we use your data, check out
            our privacy policy at{" "}
            <StyledLink
              href="https://hackthenorth.com/privacy"
              target="_blank"
              rel="noopener"
            >
              hackthenorth.com/privacy
            </StyledLink>
            .
          </Subtitle>
          <HorizontalRule />

          <SurveyQuestion
            step={0}
            showSaved={false}
            subtitle="Select all that apply."
          >
            <CheckboxContainer>
              {GENDER_OPTIONS.map((type, i) => {
                const isChecked = selectedGender.includes(type);
                const onChange = () => {
                  if (!didFocus.gender) {
                    setDidFocus({ ...didFocus, gender: true });
                  }
                  setSelectedGender((prev) => {
                    if (isChecked) {
                      return prev.filter((value) => value !== type);
                    } else {
                      if (type === "Prefer not to specify") {
                        return [type];
                      }
                      return [
                        ...prev.filter(
                          (value) => value !== "Prefer not to specify"
                        ),
                        type,
                      ];
                    }
                  });
                };

                return (
                  <CheckboxRow key={i} row align="center">
                    {isChecked ? (
                      <CheckBox
                        onChange={onChange}
                        checked={isChecked}
                        checkColor="#FFFFFF"
                      />
                    ) : (
                      <CheckBox onChange={onChange} checked={isChecked} />
                    )}
                    <CheckboxText>{GENDER_OPTIONS[i]}</CheckboxText>
                  </CheckboxRow>
                );
              })}
            </CheckboxContainer>
          </SurveyQuestion>

          <SurveyQuestion
            step={1}
            showSaved={false}
            subtitle="Select all that apply and/or add your own option."
            tooltip={RACE_TOOLTIP}
          >
            <DropdownTagInput
              multi
              isSearchable
              creatable
              placeholder="Please type or select from the dropdown"
              options={RACE_OPTIONS}
              value={answersToOptions(selectedRace)}
              onChange={(options: OptionsType<OptionTypeBase>) => {
                if (
                  optionsToAnswers(options).includes("Prefer not to specify") &&
                  optionsToAnswers(options)[0] !== "Prefer not to specify"
                ) {
                  setSelectedRace(["Prefer not to specify"]);
                } else if (
                  optionsToAnswers(options)[0] === "Prefer not to specify" &&
                  optionsToAnswers(options).length !== 0
                ) {
                  setSelectedRace(optionsToAnswers(options).slice(1));
                } else {
                  setSelectedRace(optionsToAnswers(options));
                }
              }}
            />
          </SurveyQuestion>

          <SurveyQuestion
            step={2}
            showSaved={false}
            subtitle="Select all that apply and/or add your own option."
            tooltip={ETHNICITY_TOOLTIP}
          >
            <DropdownTagInput
              multi
              isSearchable
              creatable
              placeholder="Please type or select from the dropdown"
              options={ETHNICITY_OPTIONS}
              value={answersToOptions(selectedEthnicity)}
              onChange={(options: OptionsType<OptionTypeBase>) => {
                if (
                  optionsToAnswers(options).includes("Prefer not to specify") &&
                  optionsToAnswers(options)[0] !== "Prefer not to specify"
                ) {
                  setSelectedEthnicity(["Prefer not to specify"]);
                } else if (
                  optionsToAnswers(options)[0] === "Prefer not to specify" &&
                  optionsToAnswers(options).length !== 0
                ) {
                  setSelectedEthnicity(optionsToAnswers(options).slice(1));
                } else {
                  setSelectedEthnicity(optionsToAnswers(options));
                }
              }}
            />
          </SurveyQuestion>

          <SurveyQuestion step={3} showSaved={false}>
            <TagInput
              options={YES_NO_OPTIONS}
              selectedOptions={
                selectedEducation !== null
                  ? selectedEducation
                    ? ["Yes"]
                    : ["No"]
                  : []
              }
              setSelectedOptions={(newSelection) => {
                newSelection[0] == "Yes"
                  ? setSelectedEducation(true)
                  : newSelection[0] == "No"
                  ? setSelectedEducation(false)
                  : setSelectedEducation(null);
              }}
            />
          </SurveyQuestion>

          <SurveyQuestion step={4} showSaved={false}>
            <TagInput
              options={EXPERIENCE_OPTIONS}
              selectedOptions={[selectedPersonalExperience]}
              setSelectedOptions={(newSelection) => {
                setSelectedPersonalExperience(newSelection[0] || "");
              }}
            />
          </SurveyQuestion>

          <SurveyQuestion step={5} showSaved={false}>
            <TagInput
              options={YES_NO_OPTIONS}
              selectedOptions={
                selectedFamilyExperience !== null
                  ? selectedFamilyExperience
                    ? ["Yes"]
                    : ["No"]
                  : []
              }
              setSelectedOptions={(newSelection) => {
                newSelection[0] == "Yes"
                  ? setSelectedFamilyExperience(true)
                  : newSelection[0] == "No"
                  ? setSelectedFamilyExperience(false)
                  : setSelectedFamilyExperience(null);
              }}
            />
          </SurveyQuestion>

          <SurveyQuestion
            step={6}
            showSaved={false}
            subtitle="Select all that apply."
          >
            <CheckboxContainer>
              {UNDERREP_GROUPS_OPTIONS.map((type, i) => {
                const isChecked = selectedGroups.includes(type);
                const onChange = () => {
                  if (!didFocus.underrep) {
                    setDidFocus({ ...didFocus, underrep: true });
                  }
                  setSelectedGroups((prev) => {
                    if (prev.includes(type)) {
                      return prev.filter(
                        (value) => value !== UNDERREP_GROUPS_OPTIONS[i]
                      );
                    } else {
                      return [...prev, UNDERREP_GROUPS_OPTIONS[i]];
                    }
                  });
                };

                return (
                  <CheckboxRow key={i} row align="center">
                    {isChecked ? (
                      <CheckBox
                        onChange={onChange}
                        checked={isChecked}
                        checkColor="#FFFFFF"
                      />
                    ) : (
                      <CheckBox onChange={onChange} checked={isChecked} />
                    )}
                    <CheckboxText>{UNDERREP_GROUPS_OPTIONS[i]}</CheckboxText>
                  </CheckboxRow>
                );
              })}
            </CheckboxContainer>
          </SurveyQuestion>

          <SurveyQuestion step={7} showSaved={false}>
            <TextInput
              value={selectedUnderrepGroups}
              placeholder="Please type here"
              onChange={(e) => setSelectedUnderrepGroups(e.target.value)}
            />
          </SurveyQuestion>

          <SurveyQuestion
            step={8}
            showSaved={false}
            subtitle="Select and/or add up to three options."
          >
            <TagInput
              multi
              max={TECHNOLOGY_MAX}
              adding={addingTechnology}
              setAdding={setAddingTechnology}
              options={TECHNOLOGY_OPTIONS}
              userOptions={userTechnology}
              setUserOptions={setUserTechnology}
              selectedOptions={selectedTechnology}
              setSelectedOptions={setSelectedTechnology}
            />
            {!addingTechnology && selectedTechnology.length < TECHNOLOGY_MAX ? (
              <Button
                link
                onClick={() => setAddingTechnology(true)}
                tw="inline"
              >
                + Add a new option
              </Button>
            ) : (
              <Text tw="font-bold">{OPTIONS_MAX_COPY}</Text>
            )}
          </SurveyQuestion>

          <SurveyQuestion
            step={9}
            showSaved={false}
            subtitle="Select and/or add up to three options."
          >
            <TagInput
              multi
              max={WORKSHOP_MAX}
              adding={addingWorkshop}
              setAdding={setAddingWorkshop}
              options={WORKSHOP_OPTIONS}
              userOptions={userWorkshops}
              setUserOptions={setUserWorkshops}
              selectedOptions={selectedWorkshops}
              setSelectedOptions={setSelectedWorkshops}
            />
            {!addingWorkshop && selectedWorkshops.length < WORKSHOP_MAX ? (
              <Button link onClick={() => setAddingWorkshop(true)} tw="inline">
                + Add a new option
              </Button>
            ) : (
              <Text tw="font-bold">{OPTIONS_MAX_COPY}</Text>
            )}
          </SurveyQuestion>

          <Spacer height={78} />
          <Flex row justify="flex-end">
            <Button
              onClick={() =>
                saveResponses().then((savedSuccessfully) => {
                  if (savedSuccessfully) navigate(RouteName.REVIEW);
                })
              }
            >
              Review my application
            </Button>
          </Flex>
          <Spacer height={78} />
        </ChildrenWrapper>
      </WhiteContainer>
    </Background>
  );
};

export default SurveyStage;
