import React from "react";
import styled, { css } from "styled-components";

import { useSiteContext } from "src/contexts/SiteContext";

import Button from "../base/Button";
import { normalTextStyles } from "../base/Text";
import { TextInput } from "../index";

type TTagInputProps = React.ComponentPropsWithRef<"input"> & {
  multi?: boolean;
  max?: number;
  options: string[];
  userOptions?: string[];
  setUserOptions?: (selected: string[]) => void;
  adding?: boolean;
  setAdding?: (adding: boolean) => void;
  selectedOptions: string[];
  setSelectedOptions: (selected: string[]) => void;
};

type TTagProps = React.ComponentPropsWithRef<"div"> & {
  selected: boolean;
  disabled: boolean;
  isMobile: boolean;
};

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const buttonSelectedStyles = css`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.primary.blue2};
  border-color: ${({ theme }) => theme.color.primary.blue2};
  > svg path {
    stroke: ${({ theme }) => theme.color.white};
  }

  &:focus,
  :hover {
    opacity: 0.8;
    background-color: ${({ theme }) => theme.color.primary.blue2};
  }
`;

const Tag = styled(Button)<TTagProps>`
  ${normalTextStyles}
  height: 45px;
  border: 3px solid ${({ theme }) => theme.color.primary.blue3};
  padding: 6px 36px;
  margin: 0px 16px 16px 0px;
  border-radius: 16px;
  outline: none;
  background-color: ${({ theme }) => theme.color.white};
  height: 100%;

  &:focus,
  :hover {
    opacity: ${({ disabled }) => !disabled && 1};
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.color.primary.blue3};
    cursor: ${({ disabled }) => disabled && "not-allowed"};
  }

  ${({ selected }) => (selected ? buttonSelectedStyles : "")}

  &:hover {
    opacity: ${({ isMobile }) => isMobile && 1};
    background-color: ${({ theme, selected, isMobile }) =>
      isMobile && (selected ? theme.color.primary.blue2 : theme.color.white)};
  }
`;

const TextInputTag = styled(TextInput)`
  ${normalTextStyles}
  border: 3px solid ${({ theme }) => theme.color.primary.blue3};
  padding: 8px 36px;
  margin: 0px 16px 16px 0px;
  border-radius: 16px;
  outline: none;
  background-color: ${({ theme }) => theme.color.white};
  opacity: 1;
  height: 100%;
`;

const TagInput: React.FC<TTagInputProps> = ({
  multi,
  max,
  options,
  userOptions,
  setUserOptions,
  adding,
  setAdding,
  selectedOptions,
  setSelectedOptions,
  ...rest
}) => {
  const toggleOption = (e: any, option: string) => {
    let newSelectedOptions: string[];
    e.target.blur();
    if (selectedOptions.includes(option)) {
      newSelectedOptions = selectedOptions.filter((o) => o != option);
      if (userOptions && userOptions.includes(option)) {
        if (setUserOptions)
          setUserOptions(userOptions.filter((o) => o != option));
      }
    } else {
      newSelectedOptions = multi ? [...selectedOptions, option] : [option];
    }
    if (max && newSelectedOptions.length > max) {
      return;
    }
    setSelectedOptions(newSelectedOptions);
  };

  const handleEnterPress = (e: any) => {
    if (e.key === "Enter") {
      let newSelectedOptions: string[];
      let newUserOptions: string[] = [];
      if (
        selectedOptions.includes(e.target.value) ||
        e.target.value === "" ||
        (max && selectedOptions.length > max)
      ) {
        return;
      } else {
        if (userOptions) {
          newUserOptions = [...userOptions, e.target.value];
        }
        newSelectedOptions = [...selectedOptions, e.target.value];
      }
      if (max && newSelectedOptions.length > max) {
        return;
      }
      setSelectedOptions(newSelectedOptions);
      if (setUserOptions && newUserOptions) setUserOptions(newUserOptions);
      if (setAdding) setAdding(false);
    }
  };

  const { isMobile } = useSiteContext();

  return (
    <TagContainer {...rest}>
      {options.map((option: string) => {
        return (
          <Tag
            selected={selectedOptions.includes(option)}
            disabled={
              !!(
                !selectedOptions.includes(option) &&
                max &&
                selectedOptions.length >= max
              )
            }
            key={option}
            onClick={(e) => toggleOption(e, option)}
            isMobile={isMobile}
          >
            {option}
          </Tag>
        );
      })}
      {userOptions &&
        userOptions.map((option: string) => {
          return (
            <Tag
              selected={selectedOptions.includes(option)}
              disabled={
                !!(
                  !selectedOptions.includes(option) &&
                  max &&
                  selectedOptions.length >= max
                )
              }
              key={option}
              onClick={(e) => toggleOption(e, option)}
              isMobile={isMobile}
            >
              {option}
            </Tag>
          );
        })}
      {adding && <TextInputTag onKeyDown={(e) => handleEnterPress(e)} />}
    </TagContainer>
  );
};

export default TagInput;
